import { Badge, Group, Text } from '@mantine/core';
import { TFunction } from 'i18next';
import React, { forwardRef } from 'react';

import { ISelectItem } from '../../typings';

interface ItemProps extends ISelectItem, React.ComponentPropsWithoutRef<'div'> {
  isMobile: boolean;
  t: TFunction;
}

// isDisabled instead of disable to get over mantine's behavior of disabling click events
const ProductOptionItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ isMobile, t, label, isDisabled, ...others }, ref) => {
    return (
      <div ref={ref} {...others} {...(isDisabled ? { 'data-disabled': true } : undefined)}>
        <Group position="apart">
          <Text size="sm">{label}</Text>
          {isDisabled && (
            <Badge
              size={isMobile ? 'md' : 'lg'}
              styles={(theme) => ({
                root: {
                  fontSize: 12,
                  fontWeight: 500,
                  color: theme.white,
                  backgroundColor: theme.colors['dark-red'][0],
                  borderRadius: theme.defaultRadius,
                },
              })}
            >
              {t('out-of-stock')}
            </Badge>
          )}
        </Group>
      </div>
    );
  },
);

ProductOptionItem.displayName = 'ProductOptionItem';

export default ProductOptionItem;
