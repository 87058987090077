import { Box, Pagination } from "@mantine/core";

const Paginator = ({
  activePage,
  totalPages,
  setActivePage,
}: {
  activePage: number;
  totalPages: number;
  setActivePage: (page: number) => void;
}) => {
  return (
    <Box miw={400} my={30}>
      <Pagination
        size={"lg"}
        position="right"
        color="primary-black"
        value={activePage}
        total={totalPages}
        onChange={setActivePage}
        styles={(theme) => ({
          control: {
            color: theme.colors["med-gray"][0],
            ":not([data-disabled]):hover": {
              backgroundColor: theme.colors["primary-black"][0],
              color: theme.colors["white"][0],
            },
          },
          dots: {
            color: theme.colors["med-gray"][0],
          },
        })}
      />
    </Box>
  );
};

export default Paginator;
