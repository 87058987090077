import { Carousel, CarouselProps } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { useRef, useState } from 'react';

import {
  ICompany,
  IPage,
  IPropValue,
  ISpacing,
  ITheme,
  IThemeComponent,
  ValidLocales,
} from '../../../typings';
import { formatSpacing, getImageFitValue } from '../../../utils';
import { ZammitComponent } from '../../ZammitComponent';

export interface HeroBannerCarouselProps extends CarouselProps {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  company: ICompany;
  locale?: ValidLocales;
  isMobile?: boolean;
  withAutoRotating?: boolean;
  autoRotatingDelay?: number;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  componentChildren: IThemeComponent[];
}

export const HeroBannerCarousel = ({
  withinBuilder,
  theme,
  company,
  locale,
  isMobile,
  height = 400,
  withAutoRotating = false,
  withControls = false,
  autoRotatingDelay = 2000,
  spacing,
  mobileSpacing,
  componentChildren,
  ...props
}: HeroBannerCarouselProps) => {
  const [appliedHeight, setAppliedHeight] = useState(height);
  const [aspectRatio, setAspectRatio] = useState<string | undefined>(undefined);
  const autoplay = useRef(Autoplay({ delay: autoRotatingDelay }));

  const onSlideChange = (activeSlideIndex: number) => {
    const activeSlide = componentChildren[activeSlideIndex];

    if (activeSlide) {
      const imageScaleProp = activeSlide.propValues.find(
        (prop: IPropValue) => prop.key === 'imageScale',
      );
      const bgImageProp = activeSlide.propValues.find((prop: IPropValue) => prop.key === 'bgImage');

      const imageFitValue = getImageFitValue(imageScaleProp?.value as string);

      if (imageFitValue === 'contain') {
        const imgAspectRatio = bgImageProp?.metadata
          ? bgImageProp.metadata.width / bgImageProp.metadata.height
          : undefined;

        setAppliedHeight('fit-content');
        setAspectRatio(imgAspectRatio ? String(imgAspectRatio) : undefined);
      }
    }
  };

  return (
    <Carousel
      mx="auto"
      maw="100%"
      height={appliedHeight}
      plugins={withAutoRotating ? [autoplay.current] : undefined}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
      withControls={withControls}
      withKeyboardEvents
      sx={{
        width: `calc(100% - ${isMobile ? mobileSpacing?.margin.right : spacing?.margin.right}px - ${
          isMobile ? mobileSpacing?.margin.left : spacing?.margin.left
        }px)`,
        aspectRatio,
        ...formatSpacing(isMobile ? mobileSpacing : spacing),
      }}
      onSlideChange={onSlideChange}
      {...props}
    >
      {componentChildren.map((child) => (
        <Carousel.Slide key={child.id}>
          <ZammitComponent
            withinBuilder={withinBuilder}
            theme={theme}
            locale={locale}
            component={child}
            company={company}
            isMobile={isMobile}
          />
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};
