import { useTranslation } from "react-i18next";
import { Divider, Skeleton } from "@mantine/core";

import Tab from "../../../builder/settings/Tab";

const ComponentsTabSkeleton = () => {
  const { t } = useTranslation("settings");

  return (
    <Tab tab="components" title={t("components.title")} desc={t("components.description")}>
      <Skeleton w={150} h={20} />
      <Divider my={20} color="light-gray" />
      <Skeleton w="100%" h={20} mb={10} />
      <Skeleton w="50%" h={17} />
    </Tab>
  );
};

export default ComponentsTabSkeleton;
