import { Group } from '@mantine/core';
import { useMemo } from 'react';

import EtisalatCashIcon from '../../../assets/icons/etisalat-cash.png';
import MaestroIcon from '../../../assets/icons/maestro.svg';
import MastercardIcon from '../../../assets/icons/mastercard.svg';
import MeezaIcon from '../../../assets/icons/meeza.png';
import SymplIcon from '../../../assets/icons/sympl.svg';
import TabbyIcon from '../../../assets/icons/tabby.svg';
import ValuIcon from '../../../assets/icons/valu.svg';
import VisaIcon from '../../../assets/icons/visa.svg';
import VodafoneCashIcon from '../../../assets/icons/vodafone-cash.png';
import { ICompany } from '../../../typings/Company';

const getPaymentMethodIcons = (company: ICompany) => {
  const icons = [];

  if (company.useOnlinePayment || company.useGeideaPayment || company.usePaytabsPayment)
    icons.push(...[MastercardIcon, VisaIcon, MeezaIcon]);

  if (company.usePaytabsPayment) icons.push(MaestroIcon);

  if (company.useMobileWalletsPayment) icons.push(...[EtisalatCashIcon, VodafoneCashIcon]);

  if (company.useSymplPayment) icons.push(SymplIcon);

  if (company.useValuPayment) icons.push(ValuIcon);

  if (company.useTabbyPayment) icons.push(TabbyIcon);

  return icons;
};

const PaymentMethodsIcons = ({ company }: { company: ICompany }) => {
  const paymentMethods = useMemo(() => getPaymentMethodIcons(company), [company]);

  if (!paymentMethods.length) return null;
  return (
    <Group position="apart" spacing={10}>
      {paymentMethods.map((icon, index) => (
        <img
          key={index}
          src={(icon as any).src ? (icon as any).src : icon}
          alt={`payment method ${index}`}
          style={{ maxWidth: 50, maxHeight: 35, objectFit: 'contain' }}
        />
      ))}
    </Group>
  );
};

export default PaymentMethodsIcons;
