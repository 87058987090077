import { Link } from "react-router-dom";
import { useMantineTheme } from "@mantine/core";

import ExitIcon from "../../icons/ExitIcon";
import { useTranslation } from "react-i18next";

const ExitButton = () => {
  const theme = useMantineTheme();
  const { i18n } = useTranslation();
  const direction = i18n.language === "ar" ? "left" : "right";

  return (
    <Link
      to="https://dashboard.zammit.shop"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 43,
        height: 43,
        borderRadius: 5,
        backgroundColor: theme.colors["light-gray"][0],
      }}
    >
      <ExitIcon direction={direction} />
    </Link>
  );
};

export default ExitButton;
