import { Box, BoxProps } from '@mantine/core';

import { ISpacing } from '../../typings';
import { formatSpacing } from '../../utils';

export interface FreeTextProps extends BoxProps {
  text: string;
  textAlign?: 'start' | 'center' | 'end';
  bgColor?: string;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  isMobile?: boolean;
  withinBuilder?: boolean;
  componentChildren?: React.ReactNode;
}

export const FreeText = ({
  text,
  bgColor,
  spacing,
  mobileSpacing,
  isMobile,
  textAlign = 'center',
  withinBuilder,
  children,
  componentChildren,
  ...props
}: FreeTextProps) => {
  return (
    <Box
      sx={{
        textAlign,
        width: `calc(100% - ${isMobile ? mobileSpacing?.margin.right : spacing?.margin.right}px - ${
          isMobile ? mobileSpacing?.margin.left : spacing?.margin.left
        }px)`,
        ...formatSpacing(isMobile ? mobileSpacing : spacing),
      }}
      dangerouslySetInnerHTML={{ __html: text }}
      bg={bgColor}
      {...props}
    />
  );
};
