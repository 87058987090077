import API from "./API";
import { apiPrefix } from "./constants";
import { IMetadata, INavigationItem } from "shared";

type GetNavigationResponse = {
  success: boolean;
  data: INavigationItem[];
  metadata: IMetadata;
};

export const getNavigation = async ({ themeId, locale }: { themeId: number; locale: "en" | "ar" }) => {
  const response = await API.get<GetNavigationResponse>(`${apiPrefix}/navigations`, {
    headers: { locale },
    params: {
      theme_id: themeId,
    },
  });
  return response.data.data;
};

export const getNavigationByIds = async ({ ids: id, locale }: { ids: string[]; locale: "en" | "ar" }) => {
  const response = await API.get<GetNavigationResponse>(`${apiPrefix}/navigations`, {
    headers: { locale },
    params: { id },
  });
  return response.data.data;
};
