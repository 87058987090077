import { UseControllerProps, UseFormSetValue } from "react-hook-form";
import { Box } from "@mantine/core";

import LoadingSkeleton from "./LoadingSkeleton";
import ImageUploader from "./ImageUploader";

import { useImageUpload } from "../../../../../hooks/mutations/useImageUpload";
import { ITheme, IThemePage } from "shared";
import EditorLabel from "../EditorLabel";

type Props = {
  name: UseControllerProps<ITheme<IThemePage>>["name"];
  label?: string;
  hint?: string;
  imageUrl?: string;
  localeSwitcher?: React.ReactNode;
  setValue: UseFormSetValue<ITheme<IThemePage>>;
};

const ImageEditor = ({ name, label, hint, imageUrl, localeSwitcher, setValue }: Props) => {
  const { mutate: mutateImageUpload, isLoading } = useImageUpload();
  const suffix = name.split(".").pop() as string;

  const onChange = (image: File | null) => {
    const imageIDFormName = name.replace(suffix, "imageId") as UseControllerProps<ITheme<IThemePage>>["name"];
    const metadataFormName = name.replace(suffix, "metadata") as UseControllerProps<ITheme<IThemePage>>["name"];

    if (image) {
      mutateImageUpload({
        image,
        onSuccess({ id, url, metadata }) {
          setValue(name, url);
          setValue(imageIDFormName, id);
          setValue(metadataFormName, metadata);
        },
      });
    } else {
      setValue(name, undefined);
      setValue(imageIDFormName, undefined);
      setValue(metadataFormName, undefined);
    }
  };

  return (
    <Box>
      {label ? <EditorLabel label={label} hint={hint} localeSwitcher={localeSwitcher} /> : null}
      {isLoading ? <LoadingSkeleton /> : <ImageUploader label={label} imageUrl={imageUrl} onChange={onChange} />}
    </Box>
  );
};

export default ImageEditor;
