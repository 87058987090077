import { ISectionTemplate } from "../typings/SectionTemplate";
import API from "./API";
import { apiPrefix } from "./constants";

type SectionTemplatesResponse = {
  success: boolean;
  sectionTemplates: ISectionTemplate[];
};

export const getSectionTemplates = async () => {
  const response = await API.get<SectionTemplatesResponse>(`${apiPrefix}/section_templates`);
  return response.data.sectionTemplates;
};
