import { useTranslation } from "react-i18next";
import { Box, Group, SimpleGrid, Skeleton } from "@mantine/core";

import Tab from "../../../builder/settings/Tab";

const ColorsTabSkeleton = () => {
  const { t } = useTranslation("settings");

  return (
    <Tab mode="grid" tab="colors" title={t("colors.title")} desc={t("colors.description")}>
      <SimpleGrid cols={3} mt={20}>
        {Array.from({ length: 5 }, (_, cardIndex) => (
          <Box key={cardIndex} p={20} bg="white">
            <Skeleton h={20} w="50%" mb={20} />
            {Array.from({ length: 3 }, (_, index) => (
              <Group key={index} mb={20} spacing={10} w="100%">
                <Skeleton h={40} w={40} />
                <Box sx={{ flexGrow: 1 }}>
                  <Skeleton h={15} w="70%" mb={5} />
                  <Skeleton h={15} w="50%" />
                </Box>
              </Group>
            ))}
          </Box>
        ))}
      </SimpleGrid>
    </Tab>
  );
};

export default ColorsTabSkeleton;
