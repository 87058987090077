import { Text, TextProps } from '@mantine/core';
import Link from 'next/link';

import { INavigationItem } from '../../typings/Navigation';

interface Props extends TextProps {
  size: number;
  weight: number;
  color?: string;
  item: INavigationItem;
  onClick?: () => void;
}

const NavItem = ({ size, weight, color, item, onClick, ...props }: Props) => {
  return (
    <Link
      href={`${item.navigationableType === 'Collection' ? '/shop' : ''}/${
        item.navigationable.handle === 'index' ? '' : item.navigationable.handle
      }`}
    >
      <Text
        size={size}
        weight={weight}
        color={color}
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        onClick={onClick}
        {...props}
      >
        {item.label}
      </Text>
    </Link>
  );
};

export default NavItem;
