import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Carousel } from "@mantine/carousel";
import { Flex, Image, Text, Box } from "@mantine/core";

import { ISectionTemplate } from "../../../typings/SectionTemplate";
import Button from "../../common/Button";
import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useFormContext } from "react-hook-form";
import { ComponentDisplay } from "../../../typings/Theme";
import { ITheme, IThemePage } from "shared";

type Props = {
  section: ISectionTemplate;
  componentDisplay: ComponentDisplay;
  addThemeSection: (data: {
    sectionTemplateId: number;
    themeId: number;
    page?: { id: number; index: number };
    display: ComponentDisplay;
  }) => void;
};

const SectionTemplateCard = ({ section, componentDisplay, addThemeSection }: Props) => {
  const { t } = useTranslation("sections");
  const { theme } = useParams();
  const { activePage } = useBuilderQuery();

  const form = useFormContext<ITheme<IThemePage>>();
  const pageIndex = form.getValues("pages").findIndex((page) => page.id === activePage);

  return (
    <Flex gap={20}>
      <Carousel miw={300} w={300} h={150} withIndicators>
        {section.thumbnails.map((thumbnail) => (
          <Carousel.Slide key={thumbnail}>
            <Image src={thumbnail} alt={section.name} h={150} width={300} />
          </Carousel.Slide>
        ))}
      </Carousel>
      <Flex direction="column" gap={15}>
        <Text weight={700} size={18} color="primary-black">
          {section.name}
        </Text>
        <Text weight={300} size={16} color="primary-black">
          {section.description}
        </Text>
        <Box h="100%" sx={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            mode="primary"
            onClick={() => {
              addThemeSection({
                display: componentDisplay,
                ...(activePage && componentDisplay === "content" && { page: { id: activePage, index: pageIndex } }),
                sectionTemplateId: section.id,
                themeId: Number(theme),
              });
            }}
          >
            {t("section-drawer.use-section")}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SectionTemplateCard;
