import { useMutation } from "@tanstack/react-query";
import { createTheme } from "../../api/Theme";

export const useCreateTheme = () => {
  return useMutation(
    ({ storeTemplateId }: { storeTemplateId: number; onSuccess: (id: number) => void }) => createTheme(storeTemplateId),
    {
      onSuccess({ theme, success }, { onSuccess }) {
        if (success) {
          onSuccess(theme.id);
        }
      },
    },
  );
};
