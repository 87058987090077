import { UnstyledButton, Image } from "@mantine/core";

import LeftArrow from "../../assets/icons/left-arrow.svg";
import RightArrow from "../../assets/icons/right-arrow.svg";
import DownArrow from "../../assets/icons/down-arrow.svg";

const arrowIcons = {
  left: LeftArrow,
  right: RightArrow,
  down: DownArrow,
};

const ArrowButton = ({ dir, onClick }: { dir: "left" | "right" | "down"; onClick?: () => void }) => {
  return (
    <UnstyledButton
      sx={{
        width: 26,
        height: 25,
        borderRadius: 5,
        backgroundColor: "rgba(79, 63, 168, 0.1)",
      }}
      onClick={onClick}
    >
      <Image src={arrowIcons[dir]} width="100%" height={14} fit="none" />
    </UnstyledButton>
  );
};

export default ArrowButton;
