const TelegramIcon = ({ fill }: { fill: string }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28816 1.52417C3.42256 1.52417 2.59242 1.86803 1.98034 2.4801C1.36827 3.09217 1.02441 3.92232 1.02441 4.78792V16.2117C1.02441 17.0773 1.36827 17.9074 1.98034 18.5195C2.59242 19.1316 3.42256 19.4754 4.28816 19.4754H15.7119C16.5775 19.4754 17.4077 19.1316 18.0197 18.5195C18.6318 17.9074 18.9757 17.0773 18.9757 16.2117V4.78834C18.9757 3.92274 18.6318 3.09259 18.0197 2.48052C17.4077 1.86844 16.5775 1.52459 15.7119 1.52459H4.28816V1.52417ZM16.5277 10.5C16.5346 11.3616 16.3709 12.2161 16.0459 13.0141C15.7209 13.8121 15.2412 14.5379 14.6344 15.1496C14.0275 15.7613 13.3056 16.2468 12.5102 16.5782C11.7148 16.9095 10.8617 17.0801 10 17.0801C9.13839 17.0801 8.28527 16.9095 7.48987 16.5782C6.69448 16.2468 5.97254 15.7613 5.3657 15.1496C4.75887 14.5379 4.27913 13.8121 3.95417 13.0141C3.62921 12.2161 3.46545 11.3616 3.47233 10.5C3.48609 8.77778 4.17989 7.13078 5.40258 5.91782C6.62527 4.70486 8.27776 4.02424 10 4.02424C11.7223 4.02424 13.3748 4.70486 14.5975 5.91782C15.8202 7.13078 16.514 8.77778 16.5277 10.5ZM5.53816 10.3075C5.53816 10.2471 5.57816 10.1892 5.63441 10.1688L12.9482 7.38042C12.9735 7.37116 13.0009 7.36886 13.0274 7.37377C13.0539 7.37868 13.0787 7.39062 13.099 7.40834C13.1398 7.44334 13.1577 7.50125 13.1457 7.55459L11.6865 13.6892C11.6808 13.713 11.6693 13.7351 11.6531 13.7534C11.6369 13.7718 11.6165 13.7859 11.5936 13.7946C11.5707 13.803 11.5461 13.8057 11.5219 13.8024C11.4977 13.7991 11.4747 13.79 11.4548 13.7758L8.70108 11.5579L11.1586 9.35334C11.174 9.33867 11.1839 9.31906 11.1864 9.2979C11.1889 9.27675 11.1839 9.25538 11.1723 9.2375C11.1599 9.22013 11.1417 9.20769 11.121 9.20236C11.1002 9.19703 11.0783 9.19914 11.059 9.20834L7.40733 11.0942L5.63441 10.4471C5.60636 10.4362 5.5822 10.4172 5.56505 10.3924C5.5479 10.3677 5.53854 10.3384 5.53816 10.3083V10.3075Z"
      fill={fill}
    />
  </svg>
);

export default TelegramIcon;
