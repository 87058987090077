import { Box, Flex, Grid, Skeleton } from "@mantine/core";

const ThemeStoreSkeleton = () => {
  return (
    <Box w="100%">
      <Grid gutter={30} justify="flex-start" mb={30}>
        {Array.from({ length: 6 }, (_, index) => (
          <Grid.Col key={index} h={400} md={6} lg={4}>
            <Skeleton h="100%" />
          </Grid.Col>
        ))}
      </Grid>
      <Flex justify="space-between">
        <Skeleton h={20} w="20%" />
        <Skeleton h={30} w="30%" />
      </Flex>
    </Box>
  );
};

export default ThemeStoreSkeleton;
