import { useMantineTheme } from "@mantine/core";

const MenuIcon = () => {
  const theme = useMantineTheme();

  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_516_21758)">
        <circle cx="2.5" cy="11.5" r="2.5" fill={theme.colors["dark-gray"][0]} />
        <circle cx="11.5" cy="11.5" r="2.5" fill={theme.colors["dark-gray"][0]} />
        <circle cx="20.5" cy="11.5" r="2.5" fill={theme.colors["dark-gray"][0]} />
      </g>
      <defs>
        <clipPath id="clip0_516_21758">
          <rect width="23" height="23" fill="white" transform="translate(0 23) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuIcon;
