import { useQuery } from "@tanstack/react-query";

import { useBuilderQuery } from "../custom/useBuilderQuery";
import { getProduct } from "../../api/Product";
import { IProduct } from "shared";

export const useProduct = (id?: string) => {
  const { locale } = useBuilderQuery();
  return useQuery<IProduct | null>(["product", id, locale], () => (id ? getProduct({ id, locale }) : null));
};
