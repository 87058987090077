import { Aside, Stack } from '@mantine/core';

import { INavigationItem } from '../../../typings/Navigation';
import { Currency } from '../../../typings/Theme';
import CurrencyMenu from './CurrencyMenu';
import MobileNavigationList from './MobileNavigationList';

const SideMenu = ({
  sideMenuTextColor,
  sideMenuBgColor,
  showSocialMediaIcons,
  showCurrencyMenu,
  currency = 'EGP',
  currencies,
  data,
  children,
  closeMenu,
}: {
  sideMenuTextColor?: string;
  sideMenuBgColor?: string;
  showSocialMediaIcons: boolean;
  showCurrencyMenu: boolean;
  currency?: string;
  currencies?: Currency[];
  data?: INavigationItem[];
  children: React.ReactNode;
  closeMenu: () => void;
}) => {
  return (
    <Aside
      p={50}
      w="100%"
      pos="absolute"
      top={showSocialMediaIcons ? 120 : 70}
      bg={sideMenuBgColor}
      sx={{
        height: `calc(100vh - ${showSocialMediaIcons ? 120 : 70}px)`,
        zIndex: 1005,
        gap: 15,
        justifyContent: 'space-between',
        overflowY: 'auto',
        border: 0,
      }}
    >
      {data ? (
        <MobileNavigationList txtColor={sideMenuTextColor} navItems={data} onNavigate={closeMenu} />
      ) : null}
      <Stack>
        {children}
        {showCurrencyMenu && currencies && (
          <CurrencyMenu color={sideMenuTextColor} currency={currency} currencies={currencies} />
        )}
      </Stack>
    </Aside>
  );
};

export default SideMenu;
