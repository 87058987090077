import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { Card, Text, useMantineTheme, Stack } from "@mantine/core";

import ColorPaletteItem from "./ColorPaletteItem";

import { ThemeColors } from "../../../../typings";
import { ITheme, IThemePage } from "shared";

const ColorPaletteCard = ({
  group,
  colors,
  control,
}: {
  group: "text" | "primary_btn" | "secondary_btn" | "form_fields" | "pages";
  colors: ThemeColors[];
  control: Control<ITheme<IThemePage>>;
}) => {
  const { t } = useTranslation("settings");
  const theme = useMantineTheme();

  return (
    <Card sx={{ borderColor: theme.colors["low-gray"][0], overflow: "initial" }} padding={20} withBorder>
      <Text mb={20} size={20} weight={400} color="primary-black">
        {t(`colors.${group}.title`)}
      </Text>
      <Stack spacing={20}>
        {colors.map((color) => (
          <ColorPaletteItem key={color} name={color} group={group} control={control} />
        ))}
      </Stack>
    </Card>
  );
};

export default ColorPaletteCard;
