import { useTranslation } from "react-i18next";
import { Divider, Group, Skeleton } from "@mantine/core";

import Tab from "../../../builder/settings/Tab";

const TypographyTabSkeleton = () => {
  const { t } = useTranslation("settings");

  return (
    <Tab tab="typography" title={t("typography.title")} desc={t("typography.description")}>
      <Skeleton w={200} h={40} mb={25} />
      <Group position="apart">
        <Skeleton w={250} h={24} />
        <Skeleton w={215} h={24} />
      </Group>
      <Divider my={20} color="light-gray" />
      <Skeleton w={190} h={20} mb={25} />
      <Group sx={{ flexDirection: "column" }} spacing={20}>
        <Skeleton w="100%" h={16} />
        <Skeleton w="100%" h={16} />
        <Skeleton w="100%" h={16} />
      </Group>
      <Divider my={20} color="light-gray" />
      <Skeleton w={190} h={20} mb={25} />
      <Skeleton w="100%" h={16} />
    </Tab>
  );
};

export default TypographyTabSkeleton;
