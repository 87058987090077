import { Carousel } from "@mantine/carousel";

import ActiveThemeCard from "./ActiveThemeCard";
import DraftThemeCard from "./DraftThemeCard";

import { ITheme, IThemePage } from "shared";

const ThemeSlide = ({ theme }: { theme: ITheme<IThemePage> }) => {
  return (
    <Carousel.Slide size={theme.status == "active" ? 558 : 322}>
      {theme.status == "active" ? <ActiveThemeCard theme={theme} /> : <DraftThemeCard theme={theme} />}
    </Carousel.Slide>
  );
};

export default ThemeSlide;
