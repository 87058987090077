import { Card, CardProps, useMantineTheme } from "@mantine/core";

const Section = ({ children, ...props }: CardProps) => {
  const theme = useMantineTheme();

  return (
    <Card sx={{ borderColor: theme.colors["low-gray"][0] }} padding={20} withBorder {...props}>
      {children}
    </Card>
  );
};

export default Section;
