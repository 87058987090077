type Props = { direction?: "left" | "right" };

const ExitIcon = ({ direction = "right" }: Props) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={direction === "right" ? "scale(-1,1)" : "scale(1,1)"}
    >
      <path
        d="M15.607 16.4284V20.5356C15.607 20.9713 15.4339 21.3892 15.1258 21.6972C14.8177 22.0053 14.3999 22.1784 13.9641 22.1784H2.46415C2.02843 22.1784 1.61057 22.0053 1.30247 21.6972C0.994375 21.3892 0.821289 20.9713 0.821289 20.5356V2.46415C0.821289 2.02843 0.994375 1.61057 1.30247 1.30247C1.61057 0.994375 2.02843 0.821289 2.46415 0.821289H13.9641C14.3999 0.821289 14.8177 0.994375 15.1258 1.30247C15.4339 1.61057 15.607 2.02843 15.607 2.46415V6.57129"
        stroke="#404C60"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20.5354 11.5H7.39258" stroke="#404C60" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.6783 8.21436L7.39258 11.5001L10.6783 14.7858"
        stroke="#404C60"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExitIcon;
