import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTheme } from "../../api/Theme";

export const useDeleteTheme = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: number }) => deleteTheme(id),
    onSuccess() {
      queryClient.invalidateQueries(["themes"]);
    },
  });
};
