import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useCompany } from "../queries/useCompany";

type Device = "mobile" | "desktop";
type Locale = "en" | "ar";
type ActiveComponent = number | undefined;
type ActivePage = number | undefined;

export function useBuilderQuery() {
  const [params, setParams] = useSearchParams();
  const { i18n } = useTranslation();
  const { data: company } = useCompany();

  const device = (params.get("device") || "mobile") as Device;
  const locale = (params.get("locale") || company?.defaultStoreLanguage || i18n.language) as Locale;
  const activePage = Number(params.get("activePage")) as ActivePage;
  const activeComponent = Number(params.get("activeComponent")) as ActiveComponent;

  const isMobile = device === "mobile";

  const setDevice = (device: Device) => {
    params.set("device", device);
    setParams(params);
  };
  const setLocale = (locale: Locale) => {
    params.set("locale", locale);
    setParams(params);
  };
  const setActivePage = (activePage: ActivePage) => {
    if (activePage) {
      params.delete("activeComponent");
      params.set("activePage", String(activePage));
    }
    setParams(params, { replace: true });
  };
  const replaceActivePage = (activePage: ActivePage) => {
    params.set("activePage", String(activePage));
    setParams(params, { replace: true });
  };
  const setActiveComponent = (activeComponent: ActiveComponent) => {
    if (activeComponent) params.set("activeComponent", String(activeComponent));
    else params.delete("activeComponent");
    setParams(params);
  };

  return {
    device,
    locale,
    setDevice,
    setLocale,
    isMobile,
    activePage,
    setActivePage,
    replaceActivePage,
    activeComponent,
    setActiveComponent,
  };
}
