import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateTheme } from "../../api/Theme";
import { ITheme, IThemePage } from "shared";
import { useFormContext } from "react-hook-form";

export const useThemeUpdate = () => {
  const form = useFormContext<ITheme<IThemePage>>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ theme }: { theme: ITheme<IThemePage> }) => updateTheme(theme),
    onSuccess(updatedTheme) {
      form.reset(updatedTheme);
      queryClient.invalidateQueries(["themes"]);
      queryClient.setQueryData(["theme", updatedTheme.id], updatedTheme);
    },
  });
};
