import { useQuery } from "@tanstack/react-query";
import { getTemplateFilters } from "../../api/StoreTemplates";

import { ITemplateFilterCategory } from "../../typings/StoreTemplate";

export const useTemplateFilters = () => {
  return useQuery<{
    filterCategories: ITemplateFilterCategory[];
  }>({ queryKey: ["filterCategories"], queryFn: getTemplateFilters });
};
