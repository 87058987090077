import { Badge } from "@mantine/core";

const Tag = ({ name }: { name: string }) => {
  return (
    <Badge
      radius={5}
      size="xs"
      py={5}
      px={10}
      styles={(theme) => ({
        root: {
          maxWidth: "30%",
          height: 22,
          backgroundColor: theme.colors["light-gray"][0],
          textTransform: "none",
          fontSize: theme.fontSizes.xs,
          fontWeight: 300,
          color: theme.colors["dark-gray"][0],
        },
      })}
    >
      {name}
    </Badge>
  );
};

export default Tag;
