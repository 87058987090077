import { forwardRef } from "react";
import { UnstyledButton } from "@mantine/core";

import MenuIcon from "../../icons/MenuIcon";

interface MenuButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  bgColor: "white" | "light-gray";
}

const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(({ bgColor, ...props }: MenuButtonProps, ref) => {
  return (
    <UnstyledButton
      ref={ref}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 45,
        height: 45,
        borderRadius: 5,
      }}
      bg={bgColor}
      {...props}
    >
      <MenuIcon />
    </UnstyledButton>
  );
});

MenuButton.displayName = "MenuButton";

export default MenuButton;
