import { Stack } from '@mantine/core';

import { IProduct, IVariant, ValidLocales } from '../../typings';
import { Sympl } from '../integrations/Sympl';
import { Tabby } from '../integrations/Tabby';

type Props = {
  hasSympl?: boolean;
  hasTabby?: boolean;
  variant: IVariant | null;
  product: IProduct;
  quantity: number;
  locale?: ValidLocales;
  currency?: string;
  symplStoreCode: string;
};

const PaymentIntegrations = ({
  hasSympl,
  hasTabby,
  variant,
  product,
  quantity,
  locale,
  currency,
  symplStoreCode,
}: Props) => {
  return (
    <Stack spacing={20}>
      {hasSympl && (
        <Sympl variant={variant} product={product} quantity={quantity} storeCode={symplStoreCode} />
      )}
      {hasTabby && (
        <Tabby
          currency={currency}
          locale={locale}
          variant={variant}
          product={product}
          quantity={quantity}
        />
      )}
    </Stack>
  );
};

export default PaymentIntegrations;
