const LinkedInIcon = ({ fill }: { fill: string }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5231 0.499756H1.47779C0.661563 0.499756 0 1.1411 0 1.93266V19.0664C0 19.858 0.661785 20.5 1.47779 20.5H18.5231C19.3393 20.5 20 19.8577 20 19.0664V1.93266C20 1.14132 19.3393 0.499756 18.5231 0.499756ZM6.0634 17.2413H3.04159V8.2114H6.0634V17.2413ZM4.55272 6.97783H4.5325C3.51915 6.97783 2.86225 6.28471 2.86225 5.41714C2.86225 4.53202 3.53848 3.85779 4.57183 3.85779C5.6054 3.85779 6.24118 4.5318 6.26118 5.41714C6.26118 6.28493 5.60562 6.97783 4.55272 6.97783ZM16.9564 17.2413H13.9355V12.4101C13.9355 11.1959 13.4977 10.3676 12.4052 10.3676C11.5695 10.3676 11.0735 10.9265 10.8557 11.4659C10.7748 11.6588 10.755 11.9281 10.755 12.1974V17.2411H7.73453C7.73453 17.2411 7.77409 9.05807 7.73453 8.21117H10.7552V9.49141C11.1563 8.87629 11.8728 7.99873 13.4773 7.99873C15.4655 7.99873 16.9564 9.28852 16.9564 12.0634V17.2413ZM10.7357 9.52008C10.741 9.51163 10.7481 9.50119 10.7552 9.49141V9.52008H10.7357Z"
      fill={fill}
    />
  </svg>
);

export default LinkedInIcon;
