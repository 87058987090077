import { useEffect } from 'react';

import { useExternalScript } from '../../hooks';
import { IProduct, IVariant, ValidLocales } from '../../typings';
import { calculatePriceValue } from '../../utils';

export declare class TabbyPromo {
  constructor(TabbyPromo: {
    selector: string;
    currency: string;
    price: number;
    installmentsCount: number;
    lang: string;
    source: string;
    merchantCode: string;
  });
}

const tabbyInit = ({
  currency,
  price,
  locale,
}: {
  currency: string;
  price: number;
  locale?: string;
}) => {
  new TabbyPromo({
    selector: '#TabbyPromo',
    currency,
    price,
    installmentsCount: 4,
    lang: locale || 'en',
    source: 'product',
    merchantCode: 'eg',
  });
};

type Props = {
  locale?: ValidLocales;
  currency?: string;
  variant: IVariant | null;
  product: IProduct;
  quantity: number;
};

export const Tabby = ({ locale = 'en', currency = 'EGP', variant, product, quantity }: Props) => {
  const price = calculatePriceValue({ variant, product, quantity });

  const { isLoaded } = useExternalScript('https://checkout.tabby.ai/tabby-promo.js', () =>
    tabbyInit({ currency, price, locale }),
  );

  useEffect(() => {
    if (isLoaded) tabbyInit({ currency, price, locale });
  }, [currency, price, locale, isLoaded]);

  return <div id="TabbyPromo" />;
};
