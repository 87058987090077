import { IProduct, IPurchaseVariant, IVariant } from '../typings';

export const calculateDiscount = (product: IProduct | IPurchaseVariant) => {
  const { isOnSale, priceCents, discountedPriceCents } = product;
  return isOnSale ? (priceCents - discountedPriceCents) / 100 : 0;
};

export const finalPrice = (variant?: IVariant | IPurchaseVariant | null) => {
  if (variant) return variant.isOnSale ? variant.discountedPriceCents : variant.priceCents;

  return 0;
};

export const cheapestVariant = (product: IProduct) => {
  return product.variants.reduce((prev, curr) => {
    return finalPrice(prev) < finalPrice(curr) ? prev : curr;
  });
};

export const calculatePriceValue = ({
  variant,
  product,
  quantity = 1,
}: {
  variant?: IVariant | IPurchaseVariant | null;
  product?: IProduct;
  quantity?: number;
}) => {
  let expectedVariant = variant || product?.defaultVariant;

  if (!expectedVariant && product) expectedVariant = cheapestVariant(product);

  return (finalPrice(expectedVariant) * quantity) / 100;
};
