import { Badge, createStyles } from '@mantine/core';
import { TFunction } from 'i18next';

const useStyles = createStyles((theme) => ({
  badge: {
    height: 'auto',
    padding: '5px 10px',
    fontWeight: 700,
    fontSize: 12,

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 10,
      fontWeight: 600,
    },
  },
}));

type Props = {
  t: TFunction;
};

export const OnSaleBadge = ({ t }: Props) => {
  const { classes } = useStyles();

  return (
    <Badge
      className={classes.badge}
      styles={(theme) => ({
        root: {
          color: theme.colors['btn-secondary-label'][0],
          backgroundColor: theme.colors['btn-secondary'][0],
        },
      })}
      variant="filled"
    >
      {t('sale')}
    </Badge>
  );
};
