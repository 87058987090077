import { IUser } from "../typings/User";
import API from "./API";
import { apiPrefix } from "./constants";

type UserResponse = {
  success: boolean;
  data: IUser;
};

export const getUser = async () => {
  const response = await API.get<UserResponse>(`${apiPrefix}/users/current_user`);
  return response.data.data;
};
