import { DefaultMantineColor, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ICompany, ITheme, IThemePage, INavigationComponent, ZammitComponent } from "shared";
import { IconSearch, IconUserCircle, IconShoppingBag } from "@tabler/icons-react";

import ArabicIcon from "../../../assets/icons/ar.svg";
import { useNavigationByIds } from "../../../hooks/queries/useNavigationByIds";
import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";

type Props = { company: ICompany; component: INavigationComponent; theme: ITheme<IThemePage> };

const ComponentWithNavigationModel = ({ company, component, theme }: Props) => {
  const { t } = useTranslation(["sections"]);
  const { isMobile, locale } = useBuilderQuery();
  const { data } = useNavigationByIds({
    component: component.key as "header" | "footer",
    ids: component.modelIds,
  });

  const txtColor = component.propValues.filter((propValue) => propValue.key === "txtColor")[0]
    .value as DefaultMantineColor;

  return component.key === "header" ? (
    <ZammitComponent
      company={company}
      component={{ ...component, data }}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
    >
      <div id="header-children" style={{ display: "flex", gap: 15 }}>
        <IconSearch color={txtColor} />
        <IconShoppingBag color={txtColor} />
      </div>
      <div id="aside-children" style={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: 15 }}>
        {isMobile ? (
          <>
            <Text color={txtColor}>Login</Text>
            <Text color={txtColor}>Create account</Text>
            <Text color={txtColor}>Arabic | English</Text>
          </>
        ) : (
          <>
            <IconUserCircle color={txtColor} />
            <img width={24} height={24} src={ArabicIcon} alt="language switcher" />
          </>
        )}
      </div>
    </ZammitComponent>
  ) : (
    <ZammitComponent
      company={company}
      component={{ ...component, data }}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
      extraProps={{
        newsletterDefaultTitle: t("newsletter.input"),
      }}
    />
  );
};

export default ComponentWithNavigationModel;
