import { ICompany, ITheme, IThemePage, ICollectionComponent, ZammitComponent } from "shared";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useProducts } from "../../../hooks/queries/useProducts";

type Props = { company: ICompany; component: ICollectionComponent; theme: ITheme<IThemePage> };

const ComponentWithCollectionModel = ({ company, component, theme }: Props) => {
  const { isMobile, locale } = useBuilderQuery();
  const { data } = useProducts({ collectionId: String(component.modelIds) });

  return (
    <ZammitComponent
      withinBuilder
      company={company}
      component={{ ...component, data }}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
    />
  );
};

export default ComponentWithCollectionModel;
