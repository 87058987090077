import Header from "../components/layout/Header";
import NewThemingCTA from "../components/home/NewThemingCTA";
import ThemeLibrary from "../components/home/themeLibrary";
import ThemesStore from "../components/home/themesStore/ThemesStore";

import { useCompany } from "../hooks/queries/useCompany";

const Home = () => {
  const { data: company } = useCompany();

  return (
    <>
      <Header />
      {company && !company.useNewTheming && <NewThemingCTA />}
      <ThemeLibrary />
      <ThemesStore />
    </>
  );
};

export default Home;
