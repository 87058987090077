import { useQuery } from "@tanstack/react-query";

import { useBuilderQuery } from "../custom/useBuilderQuery";
import { INavigationItem } from "shared";
import { getNavigation } from "../../api/Navigation";
import { useParams } from "react-router-dom";

export const useNavigation = () => {
  const params = useParams();
  const { locale } = useBuilderQuery();

  const themeId = Number(params.theme);
  return useQuery<INavigationItem[]>(["navigation", themeId, locale], () => getNavigation({ themeId, locale }));
};
