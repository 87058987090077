import { Badge, Box, createStyles } from '@mantine/core';

import { ISelectItem } from '../../typings';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    gap: '15px',
  },
  badge: {
    height: '45px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  selected: {
    borderWidth: '2px',
    borderColor: theme.colors['btn-primary-border'][0],
    color: theme.colors['btn-primary-label'][0],
    backgroundColor: theme.colors['btn-primary'][0],
  },
  unselectable: {
    cursor: 'not-allowed',
  },
}));

type Props = {
  value?: string;
  sizes: ISelectItem[];
  onSelect: (size: string) => void;
};

const SizeBadges = ({ value, sizes, onSelect }: Props) => {
  const { cx, classes } = useStyles();

  return (
    <Box className={classes.container}>
      {sizes.map((size) => (
        <Badge
          key={size.label}
          variant="outline"
          color="high-gray"
          radius={5}
          className={cx(classes.badge, {
            [classes.selected]: value && value === size.value,
            [classes.unselectable]: size.isDisabled,
          })}
          onClick={() => onSelect(size.value)}
        >
          {size.label}
        </Badge>
      ))}
    </Box>
  );
};

export default SizeBadges;
