import { Box, Group, Modal } from '@mantine/core';
import Image from 'next/future/image';

import { Image as ImageType, IPage, ISpacing, ITheme } from '../../../typings';
import { formatSpacing, imageLoader } from '../../../utils';
import { Button } from '../../common/Button';

type Props = {
  theme: ITheme<IPage>;
  opened: boolean;
  size: 'sm' | 'md' | 'lg' | 'xl' | 'auto';
  radius?: number;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  bgColor?: string;
  caption?: string;
  image?: ImageType;
  withTransition: boolean;
  transition:
    | 'fade'
    | 'skew-up'
    | 'skew-down'
    | 'rotate-right'
    | 'rotate-left'
    | 'slide-down'
    | 'slide-up'
    | 'slide-right'
    | 'slide-left'
    | 'scale-y'
    | 'scale-x'
    | 'scale'
    | 'pop';
  delay?: number;
  withCloseButton: boolean;
  closeOnClickOutside: boolean;
  withLinkBtn?: boolean;
  linkBtnLabel?: string;
  linkBtnAlign: 'left' | 'center' | 'right';
  url?: string;
  withinBuilder?: boolean;
  isMobile?: boolean;
  onClose: () => void;
};

export const DismissiblePopup = ({
  theme,
  opened,
  size,
  radius,
  spacing,
  mobileSpacing,
  bgColor,
  caption,
  image,
  withTransition,
  transition,
  withCloseButton,
  closeOnClickOutside,
  withLinkBtn,
  linkBtnLabel = 'Shop',
  linkBtnAlign,
  url = '/shop',
  withinBuilder,
  isMobile,
  onClose,
}: Props) => {
  return (
    <Modal
      centered
      size={size}
      opened={opened}
      trapFocus={false}
      styles={{
        header: { backgroundColor: bgColor },
        inner: { position: withinBuilder ? 'absolute' : 'fixed' },
        content: {
          backgroundColor: bgColor,
          borderRadius: radius,
          ...formatSpacing(isMobile ? mobileSpacing : spacing),
        },
      }}
      withCloseButton={withCloseButton}
      closeOnClickOutside={closeOnClickOutside}
      overlayProps={{ fixed: !withinBuilder }}
      lockScroll={!withinBuilder}
      onClose={onClose}
      {...(withTransition && {
        transitionProps: { transition, duration: 600, timingFunction: 'linear' },
      })}
      {...(withinBuilder && { target: '#modal-portal' })}
    >
      {image?.value.length && image.value[0] ? (
        <Box sx={{ height: 300 }} pos="relative">
          <Image
            src={image.value[0]}
            loader={imageLoader({ urls: image.value as string[], withinBuilder })}
            alt="popup image"
            fill
            style={{ objectFit: 'contain' }}
          />
        </Box>
      ) : null}
      {caption ? <div dangerouslySetInnerHTML={{ __html: caption }} /> : null}
      {withLinkBtn && (
        <Group position={linkBtnAlign}>
          <Button
            url={url}
            label={linkBtnLabel}
            txtColor={theme.primaryBtnLabelColor}
            bgColor={theme.primaryBtnBgColor}
            borderColor={theme.primaryBtnBorderColor}
            onClick={onClose}
          />
        </Group>
      )}
    </Modal>
  );
};
