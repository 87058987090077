import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Header as MantineHeader, Group } from "@mantine/core";

import Button from "../../common/Button";
import ErrorModal from "../../common/ErrorModal";
import Breadcrumb from "./Breadcrumb";
import PageLayoutControls from "../components/PageLayoutControls";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useThemeUpdate } from "../../../hooks/mutations/useThemeUpdate";
import { useThemePublish } from "../../../hooks/mutations/useThemePublish";
import { isCurrentRoute } from "../../../utils";
import { ITheme, IThemePage } from "shared";
import { useTheme } from "../../../hooks/queries/useTheme";

import i18n from "i18next";
export const dashboardURL = import.meta.env.VITE_PUBLIC_DASHBOARD_HOST;

type Props = { useForm?: boolean };

function Header({ useForm }: Props) {
  const { t } = useTranslation("common");
  const { setActiveComponent } = useBuilderQuery();

  const params = useParams();
  const themeId = Number(params.theme);
  const { data: theme } = useTheme(themeId);

  // Only to be shown while the form is loading, so we can safely access the form context
  if (!useForm)
    return (
      <MantineHeader
        height={75}
        px={30}
        py={15}
        styles={(theme) => ({
          root: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            borderBottomColor: theme.colors["low-gray"][0],
          },
        })}
      >
        <Breadcrumb />
        {isCurrentRoute("sections") && <PageLayoutControls />}
        {theme?.publishable ? (
          <Group spacing={20} w="20%" position="right">
            <Button mode="secondary" h={45}>
              {t("builder.header.save")}
            </Button>
            <Button mode="primary" h={45}>
              {t("builder.header.publish")}
            </Button>
          </Group>
        ) : (
          <Group spacing={20} w="20%" position="right">
            <Button mode="secondary" h={45}>
              {t("builder.header.close")}
            </Button>
            <Button mode="primary" h={45} style={{ backgroundColor: "#D47B21" }}>
              {t("builder.header.upgrade")}
            </Button>
          </Group>
        )}
      </MantineHeader>
    );

  const form = useFormContext<ITheme<IThemePage>>();

  const saveTheme = useThemeUpdate();
  const publishTheme = useThemePublish();

  function onSave() {
    saveTheme.mutate({ theme: form.getValues() }, { onSuccess: () => setActiveComponent(undefined) });
  }

  function onPublish() {
    publishTheme.mutate({ theme: form.getValues() }, { onSuccess: () => setActiveComponent(undefined) });
  }

  const onClose = () => (window.location.href = "/");

  const onUpgrade = () => (window.location.href = `${dashboardURL}/${i18n.language}/plan/pick-plan`);

  return (
    <MantineHeader
      height={75}
      px={30}
      py={15}
      styles={(theme) => ({
        root: {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          borderBottomColor: theme.colors["low-gray"][0],
        },
      })}
    >
      <Breadcrumb />

      {isCurrentRoute("sections") && <PageLayoutControls />}
      {theme?.publishable ? (
        <Group spacing={20} w="20%" position="right">
          <Button
            mode="secondary"
            h={45}
            onClick={onSave}
            loading={saveTheme.isLoading}
            disabled={publishTheme.isLoading}
          >
            {t("builder.header.save")}
          </Button>
          <Button
            mode="primary"
            h={45}
            onClick={onPublish}
            loading={publishTheme.isLoading}
            disabled={saveTheme.isLoading}
          >
            {t("builder.header.publish")}
          </Button>
        </Group>
      ) : (
        <Group spacing={20} w="20%" position="right">
          <Button mode="secondary" h={45} onClick={onClose}>
            {t("builder.header.close")}
          </Button>
          <Button mode="primary" h={45} onClick={onUpgrade} style={{ backgroundColor: "#D47B21" }}>
            {t("builder.header.upgrade")}
          </Button>
        </Group>
      )}

      <ErrorModal opened={saveTheme.isError} reset={saveTheme.reset} error={saveTheme.error} />
      <ErrorModal opened={publishTheme.isError} reset={publishTheme.reset} error={publishTheme.error} />
    </MantineHeader>
  );
}

export default Header;
