import { useState } from "react";
import { Control } from "react-hook-form";
import { MultiSelect as FormMultiSelect, MultiSelectProps } from "react-hook-form-mantine";

import ArrowButton from "./ArrowButton";

import { ITheme, IThemePage } from "shared";

type Props = { control: Control<ITheme<IThemePage>> } & MultiSelectProps<ITheme<IThemePage>>;

const MultiSelect = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const delay = 300;

  const multiSelectProps: Props = {
    ...props,
    multiple: true,
    onDropdownOpen: () => setIsOpen(true),
    onDropdownClose: () => setIsOpen(false),
    variant: "filled",
    rightSection: props.rightSection ? props.rightSection : <ArrowButton dir="down" />,
    transitionProps: {
      transition: "fade",
      duration: delay,
      timingFunction: "ease-in-out",
    },
    styles: (theme) => ({
      rightSection: {
        transition: `transform ${delay}ms ease-in-out`,
        transform: isOpen ? "rotate(180deg)" : "",
      },
      input: {
        "&:focus": { borderColor: "transparent" },
        "&:focus-within": { borderColor: "transparent" },
      },
      item: {
        "&[data-selected]": {
          "&, &:hover": {
            backgroundColor: theme.colors["secondary-magenta"][0],
          },
        },
      },
    }),
  };

  // TODO: remove this ignores, to avoid ts errors
  /* used the following comment for 
    "Type instantiation is excessively deep and possibly infinite" error
    it happens cause IProduct has two props with same type */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <FormMultiSelect {...multiSelectProps} />;
};

export default MultiSelect;
