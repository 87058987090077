import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavigateOptions, URLSearchParamsInit } from "react-router-dom";
import { TextInput, Image } from "@mantine/core";

import searchGlass from "../../../assets/search-glass.svg";

type Props = {
  filters: URLSearchParams;
  setFilters: (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit) | undefined,
    navigateOpts?: NavigateOptions | undefined,
  ) => void;
};

const DEBOUNCE_DELAY = 500;

const SearchBar = ({ filters, setFilters }: Props) => {
  const { t } = useTranslation("home");
  const [searchTerm, setSearchTerm] = useState(filters.get("search") || "");
  const debounceTimeout = useRef<ReturnType<typeof setTimeout>>();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setSearchTerm(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      const newFilters = new URLSearchParams(filters);
      newFilters.set("search", value);
      newFilters.set("page", "1");
      setFilters(newFilters);
    }, DEBOUNCE_DELAY);
  };

  return (
    <TextInput
      styles={(theme) => ({
        icon: {
          paddingLeft: 20,
          paddingRight: 20,
          width: 70,
        },
        input: {
          fontSize: 16,
          fontWeight: 300,
          lineHeight: 19,
          "&:focus": { borderColor: theme.colors["secondary-magenta"][0] },
        },
      })}
      radius={30}
      size={"xl"}
      placeholder={t("themes_store.search_placeholder")}
      icon={<Image height={30} width={30} src={searchGlass} alt="search glass" />}
      value={searchTerm}
      onChange={handleSearchChange}
    />
  );
};

export default SearchBar;
