import { Box } from '@mantine/core';

import { useExternalScript } from '../../hooks';
import { IProduct, IVariant } from '../../typings';
import { calculatePriceValue } from '../../utils';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ['sympl-widget']: any;
    }
  }
}

type Props = {
  variant?: IVariant | null;
  product?: IProduct;
  quantity?: number;
  storeCode: string;
  cartTotal?: number | null;
};

export const Sympl = ({ variant, product, quantity, storeCode, cartTotal }: Props) => {
  const { isLoaded } = useExternalScript(
    'https://assets.sympl.ai/widgets/ecom-prod-dtls/widget.min.js',
  );
  return (
    <>
      {isLoaded ? (
        <sympl-widget
          productprice={cartTotal ? cartTotal : calculatePriceValue({ variant, product, quantity })}
          storecode={storeCode}
        />
      ) : (
        <Box mt={100}></Box>
      )}
    </>
  );
};
