import { Link } from "react-router-dom";
import { useMantineTheme, Button as MantineButton, ButtonProps as MantineButtonProps } from "@mantine/core";

interface ButtonProps extends MantineButtonProps {
  to: string;
  mode: "primary" | "secondary";
}

const LinkButton = ({ to, mode, children, ...props }: ButtonProps) => {
  const theme = useMantineTheme();

  const bgColor = {
    primary: theme.colors["primary-black"][0],
    secondary: theme.white,
  };
  const txtColor = {
    primary: theme.white,
    secondary: theme.colors["primary-black"][0],
  };

  return (
    <MantineButton
      styles={(theme) => ({
        root: {
          borderRadius: 5,
          backgroundColor: bgColor[mode],
          borderColor: theme.colors["primary-black"][0],

          "&:hover": {
            backgroundColor: bgColor[mode],
            borderColor: theme.colors["primary-black"][0],
          },
        },
        label: {
          color: txtColor[mode],
          fontSize: 14,
          fontWeight: 400,
          textTransform: "capitalize",

          "&:hover": {
            color: txtColor[mode],
          },
        },
      })}
      component={Link}
      to={to}
      {...props}
    >
      {children}
    </MantineButton>
  );
};

export default LinkButton;
