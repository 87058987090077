import { Box, Flex, Modal, Stack } from '@mantine/core';
import Image from 'next/future/image';
import { useState } from 'react';

import { Image as ImageType, IPage, ISpacing, ITheme } from '../../../typings';
import { formatSpacing, imageLoader } from '../../../utils';
import { Button } from '../../common/Button';

type Props = {
  theme: ITheme<IPage>;
  opened: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'auto';
  radius?: number;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  bgColor?: string;
  caption?: string;
  image?: ImageType;
  confirmBtnLabel: string;
  declineBtnLabel: string;
  declineMsg?: string;
  withinBuilder?: boolean;
  isMobile?: boolean;
  onClose: () => void;
};

export const ConfirmationPopup = ({
  theme,
  opened,
  size = 'auto',
  radius,
  spacing,
  mobileSpacing,
  bgColor,
  caption,
  image,
  confirmBtnLabel,
  declineBtnLabel,
  declineMsg,
  withinBuilder,
  isMobile,
  onClose,
}: Props) => {
  const [showMsg, setShowMsg] = useState(false);

  return (
    <Modal
      centered
      size={size}
      opened={opened}
      trapFocus={false}
      styles={{
        inner: { position: withinBuilder ? 'absolute' : 'fixed' },
        content: {
          backgroundColor: bgColor,
          borderRadius: radius,
          ...formatSpacing(isMobile ? mobileSpacing : spacing),
        },
      }}
      withCloseButton={false}
      closeOnClickOutside={false}
      overlayProps={{ fixed: !withinBuilder }}
      lockScroll={!withinBuilder}
      onClose={onClose}
      {...(withinBuilder && { target: '#modal-portal' })}
    >
      <Stack spacing={10}>
        {image?.value.length && image.value[0] ? (
          <Box sx={{ height: 300 }} pos="relative">
            <Image
              src={image.value[0]}
              loader={imageLoader({ urls: image.value as string[], withinBuilder })}
              alt="popup image"
              style={{ objectFit: 'contain' }}
              fill
            />
          </Box>
        ) : null}
        {caption ? <div dangerouslySetInnerHTML={{ __html: caption }} /> : null}
        {showMsg && declineMsg ? <div dangerouslySetInnerHTML={{ __html: declineMsg }} /> : null}
        <Flex justify="center">
          <Button
            label={confirmBtnLabel}
            txtColor={theme.primaryBtnLabelColor}
            bgColor={theme.primaryBtnBgColor}
            borderColor={theme.primaryBtnBorderColor}
            onClick={onClose}
          />
          <Button
            label={declineBtnLabel}
            txtColor={theme.secondaryBtnLabelColor}
            bgColor={theme.secondaryBtnBgColor}
            borderColor={theme.secondaryBtnBorderColor}
            onClick={() => setShowMsg(true)}
          />
        </Flex>
      </Stack>
    </Modal>
  );
};
