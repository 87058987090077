import { useMantineTheme } from "@mantine/core";

const ThemeSettingsIcon = ({ stroke }: { stroke: "white" | "dark-gray" }) => {
  const theme = useMantineTheme();

  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2 8.96179C16.1968 8.96179 17.0049 8.16968 17.0049 7.19257C17.0049 6.21545 16.1968 5.42334 15.2 5.42334C14.2031 5.42334 13.395 6.21545 13.395 7.19257C13.395 8.16968 14.2031 8.96179 15.2 8.96179Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.98012 17.8078C8.47854 17.8078 8.8826 17.4117 8.8826 16.9232C8.8826 16.4346 8.47854 16.0386 7.98012 16.0386C7.48169 16.0386 7.07764 16.4346 7.07764 16.9232C7.07764 17.4117 7.48169 17.8078 7.98012 17.8078Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.97977 11.6156C8.97662 11.6156 9.78473 10.8235 9.78473 9.84638C9.78473 8.86926 8.97662 8.07715 7.97977 8.07715C6.98291 8.07715 6.1748 8.86926 6.1748 9.84638C6.1748 10.8235 6.98291 11.6156 7.97977 11.6156Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0408 21.8415C17.0207 21.4983 16.8991 21.1683 16.6907 20.8918C16.4824 20.6153 16.1963 20.4043 15.8676 20.2846C15.0512 20.0216 14.3574 19.4817 13.9107 18.7621C13.4641 18.0425 13.294 17.1903 13.4311 16.3588C13.5681 15.5272 14.0033 14.7709 14.6583 14.2257C15.3134 13.6805 16.1454 13.3823 17.0047 13.3846H20.38C20.9589 13.3862 21.5297 13.2513 22.0443 12.9912C22.5588 12.7312 23.002 12.3537 23.3365 11.8905C23.671 11.4274 23.8869 10.8922 23.9661 10.3301C24.0453 9.76795 23.9853 9.19542 23.7914 8.66076C23.0735 6.67386 21.8123 4.91858 20.1456 3.5867C18.4789 2.25481 16.4708 1.39755 14.3407 1.10859C12.2106 0.819617 10.0404 1.11005 8.06731 1.94817C6.0942 2.78628 4.39405 4.13982 3.15259 5.86091C1.91114 7.58201 1.17615 9.60444 1.02791 11.7073C0.879678 13.8101 1.32391 15.9124 2.31207 17.7845C3.30023 19.6566 4.7943 21.2265 6.63105 22.3226C8.46781 23.4188 10.5766 23.999 12.727 24C13.789 24.0029 14.8461 23.8599 15.8676 23.5753C16.2428 23.4723 16.5677 23.2405 16.7831 22.9222C16.9985 22.6039 17.0899 22.2204 17.0408 21.8415V21.8415Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThemeSettingsIcon;
