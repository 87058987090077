import { Carousel } from '@mantine/carousel';
import { Box, createStyles } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import Image from 'next/future/image';
import { useCallback, useMemo, useState } from 'react';

import ProductPlaceholder from '../../assets/product_placeholder.svg';
import { IProduct } from '../../typings';
import { imageLoader } from '../../utils';

type StyleProps = {
  activeImage?: string[];
  imagePosition: string;
};

const useStyles = createStyles((theme, { activeImage, imagePosition }: StyleProps) => {
  const zoomImage = activeImage ? activeImage[activeImage.length - 1] : undefined;
  return {
    slide: {
      cursor: 'pointer',
      position: 'relative',
      minWidth: 102,
      height: '100%',
    },
    activeSlide: {
      border: `2px solid ${theme.colors['borders-and-lines'][0]}`,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    imageContainer: {
      marginBottom: 20,
    },
    imageContainerZoomed: {
      backgroundPosition: imagePosition,
      backgroundSize: '200%',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${zoomImage})`,
    },
    image: {
      objectFit: 'contain',
      transition: 'opacity .5s',
    },
    zoom: {
      '&:hover': {
        opacity: 0,
        cursor: 'zoom-in',
      },
    },
  };
});

type Props = {
  withinBuilder?: boolean;
  product: IProduct;
  variantImagesIds?: number[];
  zoomOnHover: boolean;
  offset?: { left?: number; top?: number };
  isMobile: boolean;
  isFeaturedProduct?: boolean;
};

const ProductGallery = ({
  withinBuilder,
  product,
  variantImagesIds,
  zoomOnHover,
  offset,
  isMobile,
  isFeaturedProduct,
}: Props) => {
  const [activeImage, setActiveImage] = useState<string[] | undefined>();
  const [imagePosition, setImagePosition] = useState<string>('50% 50%');

  const { hovered, ref } = useHover();

  const { cx, classes } = useStyles({ imagePosition, activeImage });

  const galleryImages = useMemo(() => {
    let images = product.imagesUrls || [];

    if (variantImagesIds) {
      const variantImages = images.filter((img) => variantImagesIds.includes(img.id));
      if (variantImages.length > 0) images = variantImages;
    }

    if (!isMobile && !isFeaturedProduct && images.length > 0) setActiveImage(images[0].urls);

    return images.filter((img) => !!img.urls);
  }, [isMobile, product, variantImagesIds]);

  const isActiveImg = (imgUrl: string) => activeImage?.[0] === imgUrl;

  const zoom = useCallback(
    (event: React.MouseEvent<HTMLImageElement>) => {
      const zoomTarget = event.currentTarget;

      const extraLeft = offset?.left || 0;
      const extraTop = offset?.top || 0;

      const x = (100 / zoomTarget.offsetWidth) * (event.pageX - zoomTarget.offsetLeft - extraLeft);
      const y = (100 / zoomTarget.offsetHeight) * (event.pageY - zoomTarget.offsetTop - extraTop);

      setImagePosition(`${x}% ${y}%`);
    },
    [offset],
  );

  return (
    <Box className={classes.container} mb={isMobile ? 16 : 30}>
      {!isMobile && !isFeaturedProduct && (
        <Box
          className={cx(classes.imageContainer, {
            [classes.imageContainerZoomed]: hovered && zoomOnHover,
          })}
          ref={ref}
        >
          <Image
            alt={product.name}
            height="500"
            width="500"
            src={activeImage?.[0] || ProductPlaceholder}
            className={cx(classes.image, { [classes.zoom]: zoomOnHover })}
            priority
            loader={imageLoader({ urls: activeImage, withinBuilder })}
            onMouseMove={(e: React.MouseEvent<HTMLImageElement>) => zoomOnHover && zoom(e)}
          />
        </Box>
      )}

      {(isMobile || isFeaturedProduct || galleryImages.length > 1) && (
        <Carousel
          slideSize={isMobile || isFeaturedProduct ? '100%' : '15%'}
          height={isMobile || isFeaturedProduct ? 500 : 100}
          align={isMobile || isFeaturedProduct ? 'center' : 'start'}
          slideGap={isMobile || isFeaturedProduct ? 0 : 15}
          withControls={!isMobile}
          withIndicators={isMobile || isFeaturedProduct}
          controlSize={25}
          controlsOffset={5}
          dragFree={!isMobile && !isFeaturedProduct}
          draggable
          slidesToScroll={isMobile || isFeaturedProduct ? 1 : 5}
          styles={(theme) => ({
            root: {
              width: '100%',
            },
            indicator: {
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: theme.colors['light-gray'][0],
              transition: 'backgroundColor 250ms ease',

              '&[data-active]': {
                backgroundColor: theme.colors['btn-primary'][0],
              },
            },
          })}
        >
          {galleryImages.map((img, index) => (
            <Carousel.Slide
              key={img.id}
              onClick={() => !isMobile && !isFeaturedProduct && setActiveImage(img.urls)}
            >
              <Box
                className={cx(classes.slide, {
                  [classes.activeSlide]:
                    !isMobile && !isFeaturedProduct && isActiveImg(img.urls[0]),
                })}
              >
                <Image
                  fill
                  loader={imageLoader({ urls: img.urls, withinBuilder })}
                  sizes="(max-width: 900px) 100vw, 15vw"
                  src={img.urls[0]}
                  alt={`${product.name} ${index}`}
                  className={classes.image}
                  priority={index === 0}
                />
              </Box>
            </Carousel.Slide>
          ))}
        </Carousel>
      )}
    </Box>
  );
};

export default ProductGallery;
