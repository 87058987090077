import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { Slider } from "react-hook-form-mantine";
import { Box, Divider, Group, Text } from "@mantine/core";

import Tab from "./Tab";

import { ITheme, IThemePage } from "shared";

const ComponentsTab = ({ control }: { control: Control<ITheme<IThemePage>> }) => {
  const { t } = useTranslation("settings");

  return (
    <Tab tab="components" title={t("components.title")} desc={t("components.description")}>
      <Text size={16} weight={700} color="primary-black">
        {t("general_settings")}
      </Text>
      <Divider mt={20} mb={10} color="light-gray" />
      <Group position="apart">
        <Box>
          <Text size={16} weight={700} color="primary-black" mb={10}>
            {t("components.radius.title")}
          </Text>
          <Text size={14} weight={300} color="high-gray">
            {t("components.radius.description")}
          </Text>
        </Box>
        <Group w="50%" position="right" spacing={25}>
          <Text size={12} weight={700} color="high-gray">
            0 px
          </Text>
          <Slider w="70%" size="xl" color="secondary-magenta" name="radius" control={control} />
          <Text size={12} weight={700} color="high-gray">
            100 px
          </Text>
        </Group>
      </Group>
    </Tab>
  );
};

export default ComponentsTab;
