import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { Group, Text } from "@mantine/core";

import FontFamilies from "../../../constants/FontFamilies.json";
import { ITheme, IThemePage } from "shared";
import Dropdown from "../../common/Dropdown";

type Props = {
  name: "arabicFont" | "englishFont";
  control: Control<ITheme<IThemePage>>;
};

const FontFamilySelector = ({ name, control }: Props) => {
  const { t } = useTranslation("settings");

  return (
    <Group position="apart">
      <Text size={20} weight={400} color="primary-black">
        {t("typography.select_font")}
      </Text>
      <Dropdown
        name={name}
        searchable
        placeholder={t("typography.select_font")}
        width={300}
        data={FontFamilies}
        control={control}
      />
    </Group>
  );
};

export default FontFamilySelector;
