import { Center, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { APIError } from "../../../typings";
import Header from "../../builder/layout/Header";
import { getErrorMessage } from "../../../utils/builder";

type Props = { error: APIError | unknown | null };

const BuilderError = ({ error }: Props) => {
  const { t } = useTranslation("common");

  return (
    <Center h="calc(100vh - 155px)">
      <Header />
      <Stack spacing="md" align="center">
        <Text fw="bold" color="dark-gray" fz="xl">
          ⚠️ {t("error_something_happened")}
        </Text>
        <Text fw="bold">{getErrorMessage(error)}</Text>
      </Stack>
    </Center>
  );
};

export default BuilderError;
