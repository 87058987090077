import {
  IconPackages,
  IconCarouselHorizontal,
  IconLayoutDashboard,
  IconHandClick,
  IconBoxMultiple,
  IconLayoutNavbar,
  IconLayoutNavbarCollapseFilled,
  IconTextSize,
  IconPhoto,
  IconSlideshow,
  IconLayoutBottombar,
  IconBrandYoutube,
  IconLayoutColumns,
  IconSparkles,
  IconLayoutCards,
  IconBellUp,
  IconBellX,
  IconBellStar,
  IconLayout,
} from "@tabler/icons-react";
import { ComponentKey } from "shared";

type IComponentIconMap = {
  [key in ComponentKey]: JSX.Element;
};

export const ComponentIconMap: IComponentIconMap = {
  announcement_bar: <IconLayoutNavbarCollapseFilled size={24} />,
  header: <IconLayoutNavbar size={24} />,
  free_text: <IconTextSize size={24} />,
  hero_banner: <IconPhoto size={24} />,
  hero_banner_carousel: <IconSlideshow size={24} />,
  divided_banner: <IconLayoutColumns size={24} />,
  cards_carousel: <IconCarouselHorizontal size={24} />,
  cards_grid: <IconLayoutDashboard size={24} />,
  featured_collection: <IconPackages size={24} />,
  featured_product: <IconSparkles size={24} />,
  featured_products_grid: <IconPackages size={24} />,
  featured_products_carousel: <IconCarouselHorizontal size={24} />,
  footer: <IconLayoutBottombar size={24} />,
  card: <IconBoxMultiple size={24} />,
  button: <IconHandClick size={24} />,
  youtube: <IconBrandYoutube size={24} />,
  masonry_collage: <IconLayoutCards size={24} />,
  frame_collage: <IconLayout size={24} />,
  collage_card: <IconPhoto size={24} />,
  confirmation_popup: <IconBellUp size={24} />,
  dismissible_popup: <IconBellX size={24} />,
  image_with_overlay_text_popup: <IconBellStar size={24} />,
};
