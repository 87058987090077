import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";

import Tab from "./Tab";
import LangToggle from "./LangToggle";
import { ITheme, IThemePage } from "shared";
import { TextInput } from "react-hook-form-mantine";
import { Group, Stack, Text } from "@mantine/core";

const labels = ["addToCart", "checkout", "notifyMe"];

const LabelsTab = ({ control }: { control: Control<ITheme<IThemePage>> }) => {
  const { t, i18n } = useTranslation("settings");
  const [lang, setLang] = useState<"En" | "Ar">("En");

  return (
    <Tab tab="labels" title={t("labels.title")} desc={t("labels.description")}>
      <LangToggle lang={lang} setLang={setLang} />
      <Stack spacing={20} mt={20}>
        {labels.map((label) => (
          <Group position="apart" key={label + lang}>
            <Text size={16} weight={400} color="high-gray">
              {t(`labels.items.${label}`)}
            </Text>
            <TextInput
              name={`labels${lang}.${label}`}
              control={control}
              w={500}
              placeholder={i18n.getFixedT(lang.toLowerCase(), "settings")(`labels.items.${label}`)}
            />
          </Group>
        ))}
      </Stack>
    </Tab>
  );
};

export default LabelsTab;
