import { useMantineTheme } from "@mantine/core";

const SectionsIcon = ({ stroke }: { stroke: "white" | "dark-gray" }) => {
  const theme = useMantineTheme();

  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3572 10.6782H14.7858C14.3321 10.6782 13.9644 11.046 13.9644 11.4997V21.3568C13.9644 21.8105 14.3321 22.1782 14.7858 22.1782H21.3572C21.8109 22.1782 22.1786 21.8105 22.1786 21.3568V11.4997C22.1786 11.046 21.8109 10.6782 21.3572 10.6782Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3572 0.821289H14.7858C14.3321 0.821289 13.9644 1.18906 13.9644 1.64272V4.94486C13.9644 5.39852 14.3321 5.76629 14.7858 5.76629H21.3572C21.8109 5.76629 22.1786 5.39852 22.1786 4.94486V1.64272C22.1786 1.18906 21.8109 0.821289 21.3572 0.821289Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.21415 0.821289H1.64272C1.18906 0.821289 0.821289 1.18906 0.821289 1.64272V11.4999C0.821289 11.9535 1.18906 12.3213 1.64272 12.3213H8.21415C8.66781 12.3213 9.03557 11.9535 9.03557 11.4999V1.64272C9.03557 1.18906 8.66781 0.821289 8.21415 0.821289Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.21415 17.2334H1.64272C1.18906 17.2334 0.821289 17.6012 0.821289 18.0548V21.357C0.821289 21.8106 1.18906 22.1784 1.64272 22.1784H8.21415C8.66781 22.1784 9.03557 21.8106 9.03557 21.357V18.0548C9.03557 17.6012 8.66781 17.2334 8.21415 17.2334Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SectionsIcon;
