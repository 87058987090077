import { ICompany, INavigationItem, ISpacing, IThemeComponent } from '../typings';

export const getDesignDirection = (locale?: string) => (locale && locale === 'ar' ? 'rtl' : 'ltr');

export function important<T>(value: T) {
  return (value + ' !important') as unknown as T;
}

export function constructProps(component: IThemeComponent) {
  return component.propValues.reduce((acc, cur) => {
    let value_;
    if (cur.propType === 'image') {
      const url = Array.isArray(cur.value) ? cur.value : [cur.value];
      value_ = { value: url, metadata: cur.metadata };
    } else {
      value_ = cur.value;
    }
    return { ...acc, [cur.key]: value_ };
  }, {});
}

export const hasSubNavigation = (navigation: INavigationItem) =>
  navigation.items.some((item) => item.navigationable.handle);

export const hasEnabledCompanyLinks = (company?: ICompany) =>
  !!company?.companyLinks.some((link) => link.isEnabled);

export const getImageFitValue = (imageScale?: string) => {
  if (imageScale === 'Stretch') {
    return 'fill';
  } else if (imageScale === 'Original Size') {
    return 'contain';
  } else if (imageScale === 'Cover') {
    return 'cover';
  } else if (imageScale === 'Scale to Fit') {
    return 'scale-down';
  } else {
    return 'contain';
  }
};

export const getImageHeight = (imageScale: string, height: number) => {
  if (imageScale === 'Original Size') {
    return '100%';
  } else {
    return height;
  }
};

export const formatSpacing = (spacing?: ISpacing) => {
  if (spacing) {
    const { padding, margin } = spacing;
    return {
      padding: `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`,
      margin: `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`,
    };
  }
  return;
};

export const hasFeature = (company: ICompany | undefined, featureKey: string) => {
  return !!company?.storeFeatures.find((feature) => feature.name === featureKey);
};

export const isColor = (name?: string) => /color|colour/i.test(name?.toLowerCase() || '');

export function imageLoader({ urls, withinBuilder }: { urls?: string[]; withinBuilder?: boolean }) {
  // Should match nextConfig.images.deviceSizes and ProductImage::SIZES in the backend
  const sizes = [300, 768, 1024, 1500];
  return ({ width }: { width: number }) => {
    if (!urls) return '';

    const index = sizes.indexOf(width);
    if (index === -1 || index >= urls.length) return urls[withinBuilder ? urls.length - 1 : 0];

    return urls[index];
  };
};

export const getInitials = (name: string) => {
  const initials = name.split(" ").map((n) => n[0]);
  return initials.join("");
};

export const getColor = (name: string) => {
  const colors = [
    "gray",
    "pink",
    "grape",
    "violet",
    "yellow",
    "cyan",
    "red",
    "blue",
    "green",
    "indigo",
    "dark",
    "teal",
    "lime",
    "orange",
  ];

  const hash = name.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);

  const index = hash % colors.length;
  return colors[index];
};