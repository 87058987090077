import { Modal, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { APIError } from "../../typings";
import { getErrorMessage } from "../../utils/builder";

type Props = { opened: boolean; reset: () => void; error: APIError | unknown | null };

function ErrorModal({ opened, reset, error }: Props) {
  const { t } = useTranslation("common");
  return (
    <Modal
      opened={opened}
      title={
        <Text fw="bold" color="dark-gray">
          ⚠️ {t("error_something_happened")}
        </Text>
      }
      centered
      onClose={reset}
      size="lg"
      closeButtonProps={{ "aria-label": "close error popup" }}
    >
      <Text fw="bold">{getErrorMessage(error)}</Text>
    </Modal>
  );
}

export default ErrorModal;
