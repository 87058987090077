import { useMantineTheme } from "@mantine/core";

const PagesIcon = ({ stroke }: { stroke: "white" | "dark-gray" }) => {
  const theme = useMantineTheme();

  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4142 2.11669H2.58539C2.08761 2.11669 1.61021 2.31443 1.25823 2.66642C0.90624 3.01841 0.708496 3.4958 0.708496 3.99359V19.0073C0.708496 19.5051 0.90624 19.9825 1.25823 20.3345C1.61021 20.6865 2.08761 20.8842 2.58539 20.8842H20.4142C20.912 20.8842 21.3894 20.6865 21.7414 20.3345C22.0934 19.9825 22.2911 19.5051 22.2911 19.0073V3.99311C22.2911 3.49532 22.0934 3.01793 21.7414 2.66594C21.3894 2.31395 20.912 2.11621 20.4142 2.11621V2.11669Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.708496 6.80762H22.2911"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62324 10.5615H3.99303C3.86862 10.5615 3.7493 10.6109 3.66132 10.6989C3.57335 10.7869 3.52393 10.9062 3.52393 11.0306V16.6608C3.52393 16.7853 3.57335 16.9046 3.66132 16.9925C3.7493 17.0805 3.86862 17.1299 3.99303 17.1299H9.62324C9.74765 17.1299 9.86697 17.0805 9.95494 16.9925C10.0429 16.9046 10.0923 16.7853 10.0923 16.6608V11.0306C10.0923 10.9062 10.0429 10.7869 9.95494 10.6989C9.86697 10.6109 9.74765 10.5615 9.62324 10.5615Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.64697 16.978L6.88422 12.9889C6.93176 12.9303 6.99271 12.8841 7.06192 12.8541C7.13112 12.8241 7.20654 12.8113 7.28178 12.8166C7.35701 12.822 7.42984 12.8454 7.4941 12.8849C7.55835 12.9244 7.61214 12.9788 7.65089 13.0435L9.98826 16.9554"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.007 10.5615H13.3768C13.2524 10.5615 13.1331 10.6109 13.0451 10.6989C12.9571 10.7869 12.9077 10.9062 12.9077 11.0306V16.6608C12.9077 16.7853 12.9571 16.9046 13.0451 16.9925C13.1331 17.0805 13.2524 17.1299 13.3768 17.1299H19.007C19.1314 17.1299 19.2508 17.0805 19.3387 16.9925C19.4267 16.9046 19.4761 16.7853 19.4761 16.6608V11.0306C19.4761 10.9062 19.4267 10.7869 19.3387 10.6989C19.2508 10.6109 19.1314 10.5615 19.007 10.5615Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0303 16.9782L16.2675 12.9892C16.3152 12.9308 16.3762 12.8847 16.4453 12.8549C16.5145 12.825 16.5899 12.8122 16.665 12.8176C16.7402 12.8229 16.813 12.8463 16.8772 12.8857C16.9414 12.925 16.9953 12.9793 17.0342 13.0438L19.3716 16.9557"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99274 4.69664C3.93237 4.69391 3.87537 4.66801 3.83362 4.62432C3.79186 4.58063 3.76855 4.52253 3.76855 4.46209C3.76855 4.40166 3.79186 4.34355 3.83362 4.29986C3.87537 4.25617 3.93237 4.23027 3.99274 4.22754"
        stroke={theme.colors[stroke][0]}
      />
      <path
        d="M3.99268 4.69664C4.05305 4.69391 4.11004 4.66801 4.1518 4.62432C4.19356 4.58063 4.21686 4.52253 4.21686 4.46209C4.21686 4.40166 4.19356 4.34355 4.1518 4.29986C4.11004 4.25617 4.05305 4.23027 3.99268 4.22754"
        stroke={theme.colors[stroke][0]}
      />
      <path
        d="M6.80817 4.69664C6.7478 4.69391 6.6908 4.66801 6.64905 4.62432C6.60729 4.58063 6.58398 4.52253 6.58398 4.46209C6.58398 4.40166 6.60729 4.34355 6.64905 4.29986C6.6908 4.25617 6.7478 4.23027 6.80817 4.22754"
        stroke={theme.colors[stroke][0]}
      />
      <path
        d="M6.80811 4.69664C6.86848 4.69391 6.92547 4.66801 6.96723 4.62432C7.00899 4.58063 7.03229 4.52253 7.03229 4.46209C7.03229 4.40166 7.00899 4.34355 6.96723 4.29986C6.92547 4.25617 6.86848 4.23027 6.80811 4.22754"
        stroke={theme.colors[stroke][0]}
      />
      <path
        d="M9.62311 4.69762C9.56274 4.69489 9.50574 4.66899 9.46399 4.6253C9.42223 4.58161 9.39893 4.5235 9.39893 4.46307C9.39893 4.40263 9.42223 4.34453 9.46399 4.30084C9.50574 4.25715 9.56274 4.23125 9.62311 4.22852"
        stroke={theme.colors[stroke][0]}
      />
      <path
        d="M9.62305 4.69688C9.65474 4.69832 9.6864 4.69331 9.7161 4.68217C9.74581 4.67103 9.77295 4.65398 9.79588 4.63206C9.81882 4.61014 9.83707 4.5838 9.84954 4.55463C9.86201 4.52545 9.86844 4.49406 9.86844 4.46233C9.86844 4.4306 9.86201 4.39921 9.84954 4.37004C9.83707 4.34086 9.81882 4.31452 9.79588 4.2926C9.77295 4.27068 9.74581 4.25363 9.7161 4.24249C9.6864 4.23135 9.65474 4.22635 9.62305 4.22778"
        stroke={theme.colors[stroke][0]}
      />
    </svg>
  );
};

export default PagesIcon;
