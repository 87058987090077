import API from "./API";
import { apiPrefix } from "./constants";
import { IMetadata, IProduct } from "shared";

type GetProductsResponse = {
  success: boolean;
  data: {
    products: IProduct[];
    metadata: IMetadata;
  };
};

type GetProductResponse = {
  success: boolean;
  data: IProduct;
};

export const getProducts = async ({
  locale,
  ids,
  collectionId,
}: {
  locale: "en" | "ar";
  ids?: string[];
  collectionId?: string;
}) => {
  const response = await API.get<GetProductsResponse>(`${apiPrefix}/products`, {
    headers: { locale },
    params: {
      page: 1,
      limit: 1000, //TODO: check if dropdown handle pagination
      ...(ids && { id: ids }),
      ...(collectionId && { collection_ids: JSON.stringify([collectionId]) }),
      include_second_thumb: true,
      status: "visible",
    },
  });
  return response.data.data.products;
};

export const getProduct = async ({ id, locale }: { id: string; locale: "en" | "ar" }) => {
  const relations = [{ relation: "product_options" }];

  const response = await API.get<GetProductResponse>(`${apiPrefix}/products/${id}`, {
    headers: { locale },
    params: {
      includes: JSON.stringify(relations),
    },
  });

  return response.data.data;
};
