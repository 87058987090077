import { Button, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  btn: {
    color: theme.colors["dark-gray"][0],
    backgroundColor: theme.colors["light-gray"][0],

    "&:hover": {
      color: theme.colors["dark-gray"][0],
      backgroundColor: theme.colors["light-gray"][0],
    },
  },
  activeBtn: {
    color: theme.colors.white[0],
    backgroundColor: theme.colors["dark-gray"][0],

    "&:hover": {
      color: theme.colors.white[0],
      backgroundColor: theme.colors["dark-gray"][0],
    },
  },
}));

const LangToggle = ({ lang, setLang }: { lang: "En" | "Ar"; setLang: (lang: "En" | "Ar") => void }) => {
  const { cx, classes } = useStyles();

  return (
    <Button.Group>
      <Button
        className={cx(classes.btn, { [classes.activeBtn]: lang === "En" })}
        variant="filled"
        onClick={() => setLang("En")}
      >
        English
      </Button>
      <Button
        className={cx(classes.btn, { [classes.activeBtn]: lang === "Ar" })}
        variant="filled"
        onClick={() => setLang("Ar")}
      >
        عربي
      </Button>
    </Button.Group>
  );
};

export default LangToggle;
