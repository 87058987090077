import { useMutation } from "@tanstack/react-query";

import { uploadImage } from "../../api/Image";
import { ImageMetadata } from "../../typings";

export const useImageUpload = () => {
  return useMutation(
    ({ image }: { image: File; onSuccess: (data: { id: string; url: string; metadata: ImageMetadata }) => void }) =>
      uploadImage(image),
    {
      onSuccess({ success, data }, { onSuccess }) {
        if (success) onSuccess(data);
      },
    },
  );
};
