const InfoIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99958 19.7858C15.128 19.7858 19.2853 15.6285 19.2853 10.5001C19.2853 5.37171 15.128 1.21436 9.99958 1.21436C4.87122 1.21436 0.713867 5.37171 0.713867 10.5001C0.713867 15.6285 4.87122 19.7858 9.99958 19.7858Z"
        fill="#404C60"
      />
      <path d="M7.85742 14.7856H12.1431" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.0004 6.92857C10.3949 6.92857 10.7147 6.60877 10.7147 6.21429C10.7147 5.8198 10.3949 5.5 10.0004 5.5C9.60593 5.5 9.28613 5.8198 9.28613 6.21429C9.28613 6.60877 9.60593 6.92857 10.0004 6.92857Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.4286 15V10H9" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default InfoIcon;
