import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider, Menu, ScrollArea } from "@mantine/core";

import ArrowButton from "../../../common/ArrowButton";

import { useBuilderQuery } from "../../../../hooks/custom/useBuilderQuery";
import { usePages } from "../../../../hooks/queries/usePages";
import { pagesWithGraphicOption } from "../../../../utils/pages";

const PagesMenu = () => {
  const params = useParams();
  const { i18n } = useTranslation();
  const { activePage, setActivePage } = useBuilderQuery();
  const [isOpen, setIsOpen] = useState(false);

  const themeId = Number(params.theme);
  const { data: pages, isLoading } = usePages(themeId);

  const activePageObj = useMemo(
    () => (activePage && pages ? pages.find((page) => page.id === activePage) : null),
    [activePage, pages],
  );

  const toggleMenuState = () => setIsOpen(!isOpen);

  return (
    <Menu shadow="md" width={350} withinPortal zIndex={1001}>
      <Menu.Target>
        <Button
          w={350}
          size="md"
          loading={isLoading}
          styles={(theme) => ({
            root: {
              color: theme.colors["primary-black"][0],
              backgroundColor: "#EDEFF380",

              "&:hover": {
                backgroundColor: "#EDEFF380",
              },
            },
            inner: {
              justifyContent: "space-between",
            },
            rightIcon: {
              transform: `rotate(${isOpen ? 180 : 0}deg)`,
            },
          })}
          rightIcon={<ArrowButton dir="down" />}
          onClick={toggleMenuState}
        >
          {i18n.language === "ar" ? activePageObj?.nameAr : activePageObj?.nameEn}
        </Button>
      </Menu.Target>

      {pages && pages.length > 0 ? (
        <Menu.Dropdown p={20}>
          <ScrollArea.Autosize type="auto" mah={250}>
            {pages
              .filter((page) => !pagesWithGraphicOption.includes(page.handle))
              .map((page, index) => (
                <Box key={page.id}>
                  <Menu.Item onClick={() => setActivePage(page.id)}>
                    {i18n.language === "ar" ? page.nameAr : page.nameEn}
                  </Menu.Item>
                  {index < pages.length - 1 && <Divider my={15} color="light-gray" />}
                </Box>
              ))}
          </ScrollArea.Autosize>
        </Menu.Dropdown>
      ) : null}
    </Menu>
  );
};

export default PagesMenu;
