import API from "./API";
import { apiPrefix } from "./constants";
import { ICompany } from "shared";

type CompanyResponse = {
  success: boolean;
  data: ICompany;
};

const CompanyParams = {
  includes: JSON.stringify([{ relation: "company_values" }, { relation: "company_links" }]),
};

export const getCompany = async () => {
  const response = await API.get<CompanyResponse>(`${apiPrefix}/company`, {
    params: CompanyParams,
  });
  return response.data.data;
};

export const updateCompany = async (company: Partial<ICompany>) => {
  const response = await API.put<CompanyResponse>(`${apiPrefix}/company`, company, {
    params: CompanyParams,
  });
  return response.data.data;
};
