import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { Box, Divider, Text } from "@mantine/core";

import TextSizeSlider from "./TextSizeSlider";
import FontFamilySelector from "./FontFamilySelector";
import { ITheme, IThemePage } from "shared";

const TypographyContent = ({ lang, control }: { lang: "arabic" | "english"; control: Control<ITheme<IThemePage>> }) => {
  const { t } = useTranslation("settings");

  return (
    <Box mt={20}>
      <FontFamilySelector name={`${lang}Font`} control={control} />

      <Divider my={20} color="light-gray" />

      <Text size={20} weight={400} color="primary-black" mb={25}>
        {t("typography.headlines")}
      </Text>
      <TextSizeSlider
        label={t("typography.headline", { number: 1 })}
        iconLabel="H1"
        maxValue={40}
        name={`${lang}Sizes.h1`}
        control={control}
      />
      <Divider variant="dashed" my={15} color="light-gray" />
      <TextSizeSlider
        label={t("typography.headline", { number: 2 })}
        iconLabel="H2"
        maxValue={40}
        name={`${lang}Sizes.h2`}
        control={control}
      />
      <Divider variant="dashed" my={15} color="light-gray" />
      <TextSizeSlider
        label={t("typography.headline", { number: 3 })}
        iconLabel="H3"
        maxValue={40}
        name={`${lang}Sizes.h3`}
        control={control}
      />
      <Divider variant="dashed" my={15} color="light-gray" />
      <TextSizeSlider
        label={t("typography.headline", { number: 4 })}
        iconLabel="H4"
        maxValue={40}
        name={`${lang}Sizes.h4`}
        control={control}
      />

      <Divider my={20} color="light-gray" />

      <TextSizeSlider
        title={t("typography.body")}
        label={t("typography.body")}
        iconLabel="B"
        maxValue={20}
        name={`${lang}Sizes.body`}
        control={control}
      />

      <Divider my={20} color="light-gray" />

      <TextSizeSlider
        title={t("typography.buttons")}
        label={t("typography.buttons")}
        iconLabel="B"
        maxValue={20}
        name={`${lang}Sizes.btn`}
        control={control}
      />
    </Box>
  );
};

export default TypographyContent;
