import { ITheme, IThemePage } from "shared";

export const getThemeColors = (theme: ITheme<IThemePage>) => {
  return [
    theme.headingsAndLinksColor,
    theme.bodyTextColor,
    theme.formFieldsBgColor,
    theme.formFieldsBorderColor,
    theme.formFieldsLabelColor,
    theme.primaryBtnBgColor,
    theme.primaryBtnBorderColor,
    theme.primaryBtnLabelColor,
    theme.secondaryBtnBgColor,
    theme.secondaryBtnBorderColor,
    theme.secondaryBtnLabelColor,
    theme.pageBgColor,
    theme.pageBordersAndLinesColor,
    theme.priceColor,
    theme.salePriceColor,
  ];
};
