import { ICompany, ITheme, IThemePage, IThemeComponent, ZammitOverlay } from "shared";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";

type Props = { company: ICompany; component: IThemeComponent; theme: ITheme<IThemePage> };

const OverlayComponent = ({ company, component, theme }: Props) => {
  const { isMobile, locale, activeComponent, setActiveComponent } = useBuilderQuery();
  const opened = activeComponent === component.id;
  const close = () => setActiveComponent(undefined);

  return (
    <ZammitOverlay
      withinBuilder
      opened={opened}
      company={company}
      component={component}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
      onClose={close}
    />
  );
};

export default OverlayComponent;
