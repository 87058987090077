import '../../../styles/CardsCollage.css';

import { FrameGrid } from '@egjs/react-grid';
import { Box, Stack } from '@mantine/core';

import { ICompany, IPage, ISpacing, ITheme, IThemeComponent, ValidLocales } from '../../../typings';
import { formatSpacing } from '../../../utils';
import { ZammitComponent } from '../../ZammitComponent';

type Props = {
  theme: ITheme<IPage>;
  company: ICompany;
  locale?: ValidLocales;
  isMobile?: boolean;
  gap?: number;
  spacing: ISpacing;
  mobileSpacing: ISpacing;
  bgColor?: string;
  caption?: string;
  enableImageHoverEffect?: boolean;
  componentChildren: IThemeComponent[];
};

export const FrameCollage = ({
  theme,
  company,
  locale,
  isMobile,
  gap = 1,
  spacing,
  mobileSpacing,
  bgColor = '#fff',
  caption,
  enableImageHoverEffect,
  componentChildren,
}: Props) => {
  const spacings = `${JSON.stringify(formatSpacing(spacing))} - ${JSON.stringify(
    formatSpacing(mobileSpacing),
  )}`;

  return (
    <Stack
      spacing={20}
      style={{ backgroundColor: bgColor, ...formatSpacing(isMobile ? mobileSpacing : spacing) }}
    >
      {caption ? <Box w="100%" dangerouslySetInnerHTML={{ __html: caption }} /> : null}

      <FrameGrid
        key={`${isMobile}-${spacings}-${gap}`}
        className="container"
        gap={gap}
        defaultDirection="end"
        frame={[
          [1, 1, 2, 2],
          [3, 3, 2, 2],
          [4, 4, 4, 5],
        ]}
        rectSize={0}
        useFrameFill={true}
      >
        {componentChildren.map((child) => (
          <Box key={`${child.id}-${gap}`} className="item">
            <ZammitComponent
              theme={theme}
              locale={locale}
              component={child}
              company={company}
              isMobile={isMobile}
              extraProps={{ enableImageHoverEffect, height: '100%' }}
            />
          </Box>
        ))}
      </FrameGrid>
    </Stack>
  );
};
