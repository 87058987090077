import { useTranslation } from "react-i18next";
import { ActionIcon, Tooltip, Text } from "@mantine/core";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";

const InputLocaleSwitcher = () => {
  const { t } = useTranslation("common");

  const { locale, setLocale } = useBuilderQuery();
  const nextLocale = locale === "en" ? "ar" : "en";

  const switchLocale = () => setLocale(nextLocale);

  return (
    <Tooltip label={t(`input_locale_switcher.${nextLocale}`)} position="left" color="dark-gray">
      <ActionIcon variant="outline" onClick={switchLocale} color="dark-gray">
        <Text fw="1000" fz="xs" color="dark-gray">
          {locale.toUpperCase()}
        </Text>
      </ActionIcon>
    </Tooltip>
  );
};

export default InputLocaleSwitcher;
