import { Flex, Group, Text, Tooltip } from "@mantine/core";

import InfoIcon from "../../../icons/InfoIcon";

type Props = { label: string; hint?: string; localeSwitcher?: React.ReactNode; mb?: number };

const EditorLabel = ({ label, hint, localeSwitcher, mb = 20 }: Props) => {
  return (
    <Group noWrap position="apart" mb={mb}>
      <Flex gap={10}>
        <Text size={16} weight={700} lh={1.56} color="primary-black">
          {label}
        </Text>
        {hint ? (
          <Tooltip label={hint} maw={330} position="top-start" multiline>
            <Group align="center">
              <InfoIcon />
            </Group>
          </Tooltip>
        ) : null}
      </Flex>
      {localeSwitcher}
    </Group>
  );
};

export default EditorLabel;
