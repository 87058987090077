import React, { FunctionComponent } from 'react';

import { Platform } from '../../typings/Company';
import BehanceIcon from '../icons/BehanceIcon';
import EmailIcon from '../icons/EmailIcon';
import FacebookIcon from '../icons/FacebookIcon';
import InstagramIcon from '../icons/InstagramIcon';
import LinkedInIcon from '../icons/LinkedInIcon';
import PinterestIcon from '../icons/PinterestIcon';
import SnapchatIcon from '../icons/SnapchatIcon';
import TelegramIcon from '../icons/TelegramIcon';
import ThreadsIcon from '../icons/ThreadsIcon';
import TikTokIcon from '../icons/TikTokIcon';
import WhatsAppIcon from '../icons/WhatsAppIcon';
import XIcon from '../icons/XIcon';
import YouTubeIcon from '../icons/YouTubeIcon';

type IconProps = {
  fill: string;
};

const platformMap: Record<Platform, FunctionComponent<IconProps>> = {
  linkedin: LinkedInIcon,
  whatsapp: WhatsAppIcon,
  facebook: FacebookIcon,
  x: XIcon,
  instagram: InstagramIcon,
  youtube: YouTubeIcon,
  pinterest: PinterestIcon,
  tiktok: TikTokIcon,
  behance: BehanceIcon,
  telegram: TelegramIcon,
  email: EmailIcon,
  threads: ThreadsIcon,
  snapchat: SnapchatIcon,
};

interface Props {
  platform: Platform;
  url: string;
  color?: string;
}

const SocialMediaIcon = ({ platform, url, color = '#121118' }: Props) => {
  return platform in platformMap ? (
    <a href={url} target="_blank" rel="noreferrer" aria-label={platform} style={{ height: 20 }}>
      {React.createElement(platformMap[platform], { fill: color })}
    </a>
  ) : (
    <></>
  );
};

export default SocialMediaIcon;
