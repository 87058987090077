import { Flex } from "@mantine/core";

import PagesMenu from "./PagesMenu";
import PreviewControls from "./PreviewControls";

const PageLayoutControls = () => {
  return (
    <Flex gap={20}>
      <PagesMenu />
      <PreviewControls />
    </Flex>
  );
};

export default PageLayoutControls;
