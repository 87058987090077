import { useParams } from "react-router-dom";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Stack } from "@mantine/core";

import PageHeader from "./PageHeader";
import PageSettings from "./PageSettings";
import PageSEO from "./PageSEO";
import PageGraphic from "./PageGraphic";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { IThemeForm } from "../../../typings/Theme";
import { pagesWithGraphicOption } from "../../../utils/pages";
import { ITheme, IThemePage } from "shared";

const PageSections = ({ pageIndex, form }: { pageIndex: number; form: IThemeForm }) => {
  const page = useWatch({ control: form.control, name: `pages.${pageIndex}` });

  return (
    <Stack spacing={20}>
      <PageHeader page={page} />
      <PageSettings pageIndex={pageIndex} page={page} control={form.control} />
      <PageSEO pageIndex={pageIndex} page={page} control={form.control} />
      {pagesWithGraphicOption.includes(page?.handle) && <PageGraphic page={page} form={form} pageIndex={pageIndex} />}
    </Stack>
  );
};

const Page = () => {
  const { locale } = useBuilderQuery();
  const form = useFormContext<ITheme<IThemePage>>();

  const { fields: pages } = useFieldArray({
    control: form.control,
    name: "pages",
    keyName: "formId",
  });

  const params = useParams();
  const pageIndex = pages.findIndex((page) => page.id === Number(params.activePage));

  return <PageSections key={`${pageIndex}-${locale}`} pageIndex={pageIndex} form={form} />;
};

export default Page;
