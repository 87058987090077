import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Box, Divider, Text, Group, useMantineTheme, getStylesRef, createStyles, ScrollArea } from "@mantine/core";

import CreatePageBtn from "./CreatePageBtn";
import ArrowButton from "../../common/ArrowButton";
import PagesNavbarSkeleton from "../../loadingSkeleton/builder/themePages/PagesNavbarSkeleton";

import { usePages } from "../../../hooks/queries/usePages";

const useStyles = createStyles(() => ({
  tabLink: {
    textDecoration: "none",

    "&:hover": {
      [`& .${getStylesRef("divider")}`]: {
        visibility: "visible",
      },
    },
  },
  divider: {
    ref: getStylesRef("divider"),
    visibility: "hidden",
  },
  visible: {
    visibility: "visible",
  },
}));

const PagesNavbar = ({ openPageModal }: { openPageModal: () => void }) => {
  const { t, i18n } = useTranslation("pages");
  const { cx, classes } = useStyles();
  const mantineTheme = useMantineTheme();
  const { activePage, theme } = useParams();

  const isActivePage = (page: number) => {
    return Number(activePage) === page;
  };

  const themeId = Number(theme);
  const { data: pages, isLoading } = usePages(themeId);

  return (
    <>
      <Divider orientation="vertical" mx={20} color="low-gray" />
      <Box w={"100%"}>
        <Text size={16} weight={700} color="primary-black">
          {t("title")}
        </Text>
        <Divider my={10} color="light-gray" />
        <CreatePageBtn onClick={openPageModal} />
        {isLoading && <PagesNavbarSkeleton />}
        {pages ? (
          <ScrollArea.Autosize mt={10} mah="85%">
            {pages.map(({ id, nameEn, nameAr }, index) => (
              <Box key={id} w="100%" className={classes.tabLink} component={Link} to={`pages/${id}`}>
                <Group
                  position="apart"
                  p={10}
                  sx={{
                    borderRadius: 5,
                    backgroundColor: isActivePage(id) ? mantineTheme.colors["light-gray"] : "transparent",
                  }}
                >
                  <Group spacing={10}>
                    <Divider
                      size={3}
                      orientation="vertical"
                      color="secondary-magenta"
                      className={cx(classes.divider, { [classes.visible]: isActivePage(id) })}
                    />
                    <Text
                      size={16}
                      weight={isActivePage(id) ? 700 : 400}
                      color={isActivePage(id) ? "secondary-magenta" : "primary-black"}
                    >
                      {i18n.language === "ar" ? nameAr : nameEn}
                    </Text>
                  </Group>
                  <ArrowButton dir={i18n.language === "en" ? "right" : "left"} />
                </Group>
                {index !== pages.length - 1 && <Divider my={10} color="light-gray" />}
              </Box>
            ))}
          </ScrollArea.Autosize>
        ) : null}
      </Box>
    </>
  );
};

export default PagesNavbar;
