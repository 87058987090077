import rtlPlugin from "stylis-plugin-rtl";
import { createEmotionCache } from "@mantine/core";

export const rtlCache = createEmotionCache({
  key: "mantine-rtl",
  stylisPlugins: [rtlPlugin],
});

export const ltrCache = createEmotionCache({
  key: "mantine-ltr",
});
