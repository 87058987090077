const YouTubeIcon = ({ fill }: { fill: string }) => (
  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9954 5.42609C20.0403 4.12569 19.7559 2.83502 19.1686 1.67392C18.7702 1.19749 18.2172 0.875975 17.606 0.765399C15.0781 0.536022 12.5397 0.442007 10.0017 0.483759C7.47298 0.440112 4.94372 0.531093 2.42467 0.756314C1.92663 0.846909 1.46574 1.08051 1.09823 1.42862C0.280565 2.18269 0.189713 3.47278 0.0988612 4.563C-0.0329537 6.52319 -0.0329537 8.49001 0.0988612 10.4502C0.125145 11.0638 0.216509 11.6729 0.371416 12.2672C0.480961 12.7261 0.702592 13.1506 1.01646 13.5028C1.38647 13.8694 1.8581 14.1163 2.37015 14.2115C4.32884 14.4532 6.30242 14.5534 8.27552 14.5113C11.4553 14.5567 14.2445 14.5113 17.5424 14.2569C18.067 14.1675 18.5519 13.9203 18.9324 13.5482C19.1868 13.2938 19.3768 12.9823 19.4866 12.6397C19.8115 11.6427 19.9711 10.5993 19.9591 9.55076C19.9954 9.04199 19.9954 5.97121 19.9954 5.42609ZM7.94845 10.0959V4.47215L13.3269 7.29764C11.8187 8.13348 9.82908 9.07833 7.94845 10.0959Z"
      fill={fill}
    />
  </svg>
);

export default YouTubeIcon;
