import { useTranslation } from "react-i18next";
import { Box, Button } from "@mantine/core";
import { useHover } from "@mantine/hooks";

import PlusSquareIcon from "../../icons/PlusSquareIcon";
import GreenPlusSquareIcon from "../../icons/GreenPlusSquareIcon";

const CreatePageBtn = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation("pages");
  const { hovered, ref } = useHover();

  return (
    <Box ref={ref}>
      <Button
        w="100%"
        h={50}
        styles={{
          root: {
            fontWeight: 400,
            color: "#121118",
            backgroundColor: "#4F3FA81A",
            border: "1px dashed #4F3FA866",

            "&:hover": {
              fontWeight: 700,
              color: "#121118",
              backgroundColor: "#45B4671A",
              border: 0,
            },
          },
          inner: {
            justifyContent: "flex-start",
          },
        }}
        leftIcon={hovered ? <GreenPlusSquareIcon /> : <PlusSquareIcon />}
        onClick={onClick}
      >
        {t("create")}
      </Button>
    </Box>
  );
};

export default CreatePageBtn;
