import { BackgroundImage, Box, createStyles, Overlay } from '@mantine/core';
import Link from 'next/link';

import {
  ICompany,
  Image as ImageType,
  IPage,
  ITheme,
  IThemeComponent,
  ValidLocales,
} from '../../../typings';
import { ZammitComponent } from '../../ZammitComponent';

const useStyles = createStyles(() => ({
  bgImage: {
    backgroundSize: '100%',
    transition: 'background-size .4s ease-in-out',

    '&:hover': {
      backgroundSize: '120%',
    },
  },
}));

type Props = {
  theme: ITheme<IPage>;
  company: ICompany;
  locale?: ValidLocales;
  isMobile?: boolean;
  bgImage: ImageType;
  width?: number;
  height: number;
  radius?: number;
  overlay?: boolean;
  overlayOpacity?: number;
  overlayColor?: string;
  url?: string;
  enableImageHoverEffect?: boolean;
  componentChildren: IThemeComponent[];
};

const CollageCardContent = ({
  theme,
  company,
  locale,
  isMobile,
  bgImage,
  width,
  height,
  radius = 0,
  overlay,
  overlayOpacity = 0.2,
  overlayColor = '#000',
  enableImageHoverEffect,
  componentChildren,
}: Props) => {
  const { cx, classes } = useStyles();

  return (
    <BackgroundImage
      src={bgImage?.value[bgImage.value.length - 1]}
      radius={radius}
      style={{ width: width || '100%', height }}
      className={cx({ [classes.bgImage]: enableImageHoverEffect })}
    >
      <Box h="100%" style={{ position: 'relative', zIndex: 100 }}>
        {componentChildren.map((child) => (
          <ZammitComponent
            key={child.id}
            theme={theme}
            locale={locale}
            component={child}
            company={company}
            isMobile={isMobile}
          />
        ))}{' '}
      </Box>
      {overlay && (
        <Overlay style={{ opacity: overlayOpacity, backgroundColor: overlayColor, zIndex: 99 }} />
      )}
    </BackgroundImage>
  );
};

export const CollageCard = ({ height, url, ...props }: Props) => {
  return url ? (
    <Link key={height} href={url} passHref>
      <Box component="a">
        <CollageCardContent key={height} height={height} {...props} />
      </Box>
    </Link>
  ) : (
    <CollageCardContent key={height} height={height} {...props} />
  );
};
