import 'dayjs/locale/en';
import 'dayjs/locale/ar-sa';

import { Text } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { TFunction } from 'i18next';

import { IProdExtra, ISubExtraOptionAnswer, ValidLocales } from '../../typings';

type Props = {
  locale?: ValidLocales;
  extra: IProdExtra;
  value?: ISubExtraOptionAnswer<string>;
  setValue: (value: ISubExtraOptionAnswer<string>) => void;
  t: TFunction;
};

const ExtraDate = ({ t, locale, extra, value, setValue }: Props) => {
  const onChange = (date: Date) => {
    const dateString = date.toLocaleDateString(undefined, {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    });

    setValue({
      extraId: extra.id,
      extraOptionId: extra.options[0].id,
      value: dateString,
    });
  };

  return (
    <>
      <Text mb={15} size={16} weight={700} color="high-gray" transform="uppercase">
        {`${extra.name} ${extra.min > 0 ? '*' : ''}`}
      </Text>
      <DatePicker
        size="md"
        mb={20}
        locale={locale}
        placeholder={t('pick-date') as string}
        value={value?.value ? new Date(Date.parse(value.value)) : null}
        onChange={onChange}
      />
    </>
  );
};

export default ExtraDate;
