import { Box, Grid, Group } from '@mantine/core';

import { ICompany, IPage, ISpacing, ITheme, IThemeComponent, ValidLocales } from '../../../typings';
import { formatSpacing } from '../../../utils';
import { ZammitComponent } from '../../ZammitComponent';

export interface CardsGridProps {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  company: ICompany;
  locale?: ValidLocales;
  isMobile?: boolean;
  caption?: string;
  columns: number;
  mobileColumns?: number;
  bgColor?: string;
  spacing: ISpacing;
  mobileSpacing: ISpacing;
  enableImageHoverEffect?: boolean;
  componentChildren: IThemeComponent[];
}

export const CardsGrid = ({
  withinBuilder,
  theme,
  company,
  locale,
  isMobile,
  caption,
  columns,
  bgColor,
  mobileColumns,
  spacing,
  mobileSpacing,
  enableImageHoverEffect,
  componentChildren,
}: CardsGridProps) => {
  return (
    <Group
      spacing={20}
      sx={{
        flexDirection: 'column',
        backgroundColor: bgColor,
        ...formatSpacing(isMobile ? mobileSpacing : spacing),
      }}
    >
      {caption ? <Box w="100%" dangerouslySetInnerHTML={{ __html: caption }} /> : null}
      <Grid w="100%" gutter="lg" columns={isMobile ? mobileColumns : columns}>
        {componentChildren.map((child) => (
          <Grid.Col key={child.id} span={1}>
            <ZammitComponent
              withinBuilder={withinBuilder}
              theme={theme}
              locale={locale}
              component={child}
              company={company}
              isMobile={isMobile}
              extraProps={{ columns, mobileColumns, enableImageHoverEffect }}
            />
          </Grid.Col>
        ))}
      </Grid>
    </Group>
  );
};
