import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";

import { createPage } from "../../api/Pages";
import { ITheme, IThemePage } from "shared";

type Params = { themeId: number; page: Partial<IThemePage> };

export const useCreatePage = (onSuccess?: () => void) => {
  const form = useFormContext<ITheme<IThemePage>>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Params) => createPage(params),
    onSuccess(newPage) {
      const pages = form.getValues("pages");
      pages.push(newPage);
      form.setValue("pages", pages);

      const themeId = form.getValues("id");
      queryClient.invalidateQueries(["pages", themeId]);

      onSuccess?.();
    },
  });
};
