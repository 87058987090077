import { NumberInput as MantineNumberInput, NumberInputProps, Text } from '@mantine/core';

type Props = {
  label: string;
  value?: number;
  onChange: (value?: number) => void;
};

const NumberInput = ({ label, value, onChange }: Props & NumberInputProps) => {
  return (
    <MantineNumberInput
      size="md"
      mb={20}
      label={
        <Text mb={15} size={16} weight={700} color="high-gray" transform="capitalize">
          {label}
        </Text>
      }
      precision={1}
      value={value || 0}
      onChange={onChange}
    />
  );
};

export default NumberInput;
