import { Skeleton } from "@mantine/core";
import { Carousel } from "@mantine/carousel";

const ThemeLibrarySkeleton = () => {
  return (
    <Carousel height={220} align="start" slideGap="xl" withControls={false}>
      {Array.from({ length: 4 }, (_, index) => (
        <Carousel.Slide key={index} size={index === 0 ? 558 : 322}>
          <Skeleton h="100%" />
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};

export default ThemeLibrarySkeleton;
