import { Skeleton, Stack } from "@mantine/core";

const PagesNavbarSkeleton = () => {
  return (
    <Stack mt={10} w="100%" spacing={10}>
      {Array.from({ length: 3 }, (_, index) => (
        <Skeleton key={index} h={50} w="100%" />
      ))}
    </Stack>
  );
};

export default PagesNavbarSkeleton;
