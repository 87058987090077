import { useMantineTheme } from "@mantine/core";

const GarbageCan = ({ stroke }: { stroke: string }) => {
  const theme = useMantineTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.71387 6H22.2853" stroke="#C13749" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.28516 6H19.7137V21.4286C19.7137 21.8832 19.5331 22.3193 19.2116 22.6408C18.8901 22.9622 18.4541 23.1429 17.9994 23.1429H5.99944C5.54479 23.1429 5.10875 22.9622 4.78726 22.6408C4.46577 22.3193 4.28516 21.8832 4.28516 21.4286V6Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71387 6.00003V5.14289C7.71387 4.00625 8.1654 2.91616 8.96912 2.11243C9.77285 1.30871 10.8629 0.857178 11.9996 0.857178C13.1362 0.857178 14.2263 1.30871 15.03 2.11243C15.8338 2.91616 16.2853 4.00625 16.2853 5.14289V6.00003"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.42773 9.42871V18.8573" stroke="#C13749" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5713 9.42871V18.8573" stroke="#C13749" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default GarbageCan;
