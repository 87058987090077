import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Box, Divider, Text, Group, useMantineTheme, getStylesRef, createStyles } from "@mantine/core";

import ArrowButton from "../../common/ArrowButton";

import { SettingsTab } from "../../../typings";

const navItems = ["colors", "typography", "logos", "components", "labels"] as const;

const useStyles = createStyles(() => ({
  tabLink: {
    textDecoration: "none",

    "&:hover": {
      [`& .${getStylesRef("divider")}`]: {
        visibility: "visible",
      },
    },
  },
  divider: {
    ref: getStylesRef("divider"),
    visibility: "hidden",
  },
  visible: {
    visibility: "visible",
  },
}));

const SettingsNavbar = () => {
  const { t, i18n } = useTranslation("settings");
  const theme = useMantineTheme();
  const { cx, classes } = useStyles();
  const { activeTab } = useParams();

  const isActiveTab = (tab: SettingsTab) => {
    return activeTab === tab;
  };

  return (
    <>
      <Divider orientation="vertical" mx={20} color="low-gray" />
      <Box w={"100%"}>
        <Text size={16} weight={700} color="primary-black">
          {t("title")}
        </Text>
        <Box>
          {navItems.map((item) => (
            <Box key={item} w="100%" className={classes.tabLink} component={Link} to={`settings/${item}`}>
              <Divider my={10} color="light-gray" />
              <Group
                position="apart"
                p={10}
                sx={{
                  borderRadius: 5,
                  backgroundColor: isActiveTab(item) ? theme.colors["light-gray"] : "transparent",
                }}
              >
                <Group spacing={10}>
                  <Divider
                    size={3}
                    orientation="vertical"
                    color="secondary-magenta"
                    className={cx(classes.divider, { [classes.visible]: isActiveTab(item) })}
                  />
                  <Text
                    size={16}
                    weight={isActiveTab(item) ? 700 : 400}
                    color={isActiveTab(item) ? "secondary-magenta" : "primary-black"}
                  >
                    {t(`${item}.title`)}
                  </Text>
                </Group>
                <ArrowButton dir={i18n.language === "en" ? "right" : "left"} />
              </Group>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default SettingsNavbar;
