import { Badge, Box, createStyles } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { parseToRgba, readableColor } from 'color2k';

import { ISelectItem } from '../../typings';

const parseColor = (color?: string) => {
  if (!color) return '#000';

  try {
    const parsedColor = parseToRgba(color);
    return `rgba(${parsedColor.join(', ')})`;
  } catch {
    return '#000';
  }
};

const getLegibleColor = (color?: string) => {
  const actualColor = parseColor(color);
  return readableColor(actualColor);
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    gap: 10,
  },
  selectContainer: {
    gap: 15,
    flexWrap: 'wrap',
  },
  badgeContainer: {
    position: 'relative',
  },
  badge: {
    width: 30,
    height: 30,

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 24,
      height: 24,
    },
  },
  selectBadge: {
    width: 50,
    height: 50,
    cursor: 'pointer',

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 35,
      height: 35,
    },
  },
  unselectable: {
    cursor: 'not-allowed',
    opacity: 0.3,
  },
}));

type Props = {
  isMobile: boolean;
  selectable?: boolean;
  value?: string;
  colors: ISelectItem[];
  onSelect?: (color: string) => void;
};

export const ColorBadges = ({ isMobile, selectable, value, colors, onSelect }: Props) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.container, {
        [classes.selectContainer]: selectable,
      })}
    >
      {colors.map((color) => {
        const isSelected = value === color.value;
        const mainColor = parseColor(color.value);
        const oppositeColor = getLegibleColor(color.value);

        return (
          <Box key={color.label} className={classes.badgeContainer}>
            <Badge
              variant="filled"
              className={cx({
                [classes.badge]: !selectable,
                [classes.selectBadge]: selectable,
                [classes.unselectable]: color.isDisabled,
              })}
              title={color.label}
              styles={{
                root: {
                  backgroundColor: mainColor,
                  borderColor: oppositeColor,
                },
                inner: {
                  display: 'flex',
                },
              }}
              onClick={() => onSelect && onSelect(color.value)}
            >
              {isSelected && <IconCheck size={isMobile ? 17 : 24} color={oppositeColor} />}
              {color.isDisabled && <IconX size={isMobile ? 17 : 24} color={oppositeColor} />}
            </Badge>
          </Box>
        );
      })}
    </Box>
  );
};
