import { Box, Text, Title } from '@mantine/core';
import { TFunction } from 'i18next';

type Props = {
  isMobile: boolean;
  desc: string;
  useShopAsCatalogue: boolean;
  t: TFunction;
};

const ProductDesc = ({ isMobile, desc, useShopAsCatalogue, t }: Props) => {
  return (
    <Box>
      <Title order={isMobile ? 3 : 2} mt={30} mb={15} color="black">
        {useShopAsCatalogue ? t('desc') : t('product-details')}
      </Title>
      <Text dangerouslySetInnerHTML={{ __html: desc }} />
    </Box>
  );
};

export default ProductDesc;
