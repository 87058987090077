import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
  Flex,
  MantineSize,
} from '@mantine/core';
import Link from 'next/link';

import { ISpacing } from '../../typings';
import { formatSpacing, important } from '../../utils';

export interface ButtonProps extends MantineButtonProps {
  txtColor?: string;
  bgColor?: string;
  borderColor?: string;
  align?: 'start' | 'center' | 'end';
  size?: MantineSize;
  url?: string;
  label: string;
  variant?: 'filled' | 'outline' | 'light' | 'white' | 'default' | 'subtle' | 'gradient';
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  isMobile?: boolean;
  componentChildren?: React.ReactNode;
  onClick?: () => void;
}

export const Button = ({
  variant = 'filled',
  size = 'md',
  align = 'center',
  txtColor,
  bgColor,
  borderColor,
  label,
  url,
  spacing,
  mobileSpacing,
  isMobile,
  componentChildren,
  ...props
}: ButtonProps) => {
  const alignmentMap = {
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  };

  const buttonProps: MantineButtonProps = {
    styles: {
      root: {
        color: important(txtColor),
        backgroundColor: important(bgColor),
        borderColor: important(borderColor),
        '&:hover': {
          color: important(txtColor),
          backgroundColor: important(bgColor),
          borderColor: important(borderColor),
        },
        ...formatSpacing(isMobile ? mobileSpacing : spacing),
      },
    },
    size,
    variant,
    ...props,
  };

  // Easier than doing some TS magic
  if (url) {
    return (
      <Flex justify={alignmentMap[align]} sx={{ width: '100%' }}>
        <Link href={url} passHref>
          <MantineButton {...buttonProps} href={url} component="a">
            {label}
          </MantineButton>
        </Link>
      </Flex>
    );
  }

  return (
    <Flex justify={alignmentMap[align]} sx={{ width: '100%' }}>
      <MantineButton {...buttonProps}>{label}</MantineButton>
    </Flex>
  );
};
