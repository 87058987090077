import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { Box, Group } from '@mantine/core';
import { ControlledMenu, MenuItem, SubMenu, useHover, useMenuState } from '@szhsin/react-menu';
import { IconChevronDown } from '@tabler/icons-react';
import Link from 'next/link';
import { useRef } from 'react';

import { INavigationItem } from '../../../typings';
import { hasSubNavigation } from '../../../utils';
import NavItem from '../../common/NavItem';

const HoverMenuWithTransition = ({
  navItem,
  color,
}: {
  navItem: INavigationItem;
  color?: string;
}) => {
  const ref = useRef(null);
  const [menuState, toggle] = useMenuState({ transition: true });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle);

  return (
    <>
      <Group spacing={10} sx={{ cursor: 'pointer' }} ref={ref} {...anchorProps}>
        <NavItem size={16} weight={400} color={color} item={navItem} />
        <IconChevronDown size={20} color={color} />
      </Group>

      <ControlledMenu
        overflow="auto"
        menuStyle={{ maxHeight: '500px', overflowY: 'auto' }}
        {...hoverProps}
        {...menuState}
        anchorRef={ref}
        onClose={() => toggle(false)}
      >
        {navItem.items.map((item) =>
          hasSubNavigation(item) ? (
            <Box key={item.id}>
              <SubMenu
                label={
                  <Link
                    key={item.id}
                    href={`${item.navigationableType === 'Collection' ? '/shop' : ''}/${
                      item.navigationable.handle === 'index' ? '' : item.navigationable.handle
                    }`}
                  >
                    {item.label}
                  </Link>
                }
              >
                {item.items.map((subItem) => (
                  <MenuItem
                    key={subItem.id}
                    href={`${subItem.navigationableType === 'Collection' ? '/shop' : ''}/${
                      subItem.navigationable.handle === 'index' ? '' : subItem.navigationable.handle
                    }`}
                  >
                    <Link
                      href={`${subItem.navigationableType === 'Collection' ? '/shop' : ''}/${
                        subItem.navigationable.handle === 'index'
                          ? ''
                          : subItem.navigationable.handle
                      }`}
                    >
                      {subItem.label}
                    </Link>
                  </MenuItem>
                ))}
              </SubMenu>
            </Box>
          ) : (
            <MenuItem key={item.id}>
              <Link
                href={`${item.navigationableType === 'Collection' ? '/shop' : ''}/${
                  item.navigationable.handle === 'index' ? '' : item.navigationable.handle
                }`}
                passHref
              >
                {item.label}
              </Link>
            </MenuItem>
          ),
        )}
      </ControlledMenu>
    </>
  );
};

const NavigationList = ({
  txtColor,
  navItems,
}: {
  txtColor?: string;
  navItems: INavigationItem[];
}) => {
  return (
    <Group position="apart" align="center" spacing={30}>
      {navItems.map((item) =>
        hasSubNavigation(item) ? (
          <HoverMenuWithTransition key={item.id} navItem={item} color={txtColor} />
        ) : (
          <NavItem key={item.id} size={15} weight={400} color={txtColor} item={item} />
        ),
      )}
    </Group>
  );
};

export default NavigationList;
