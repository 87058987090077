import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { Menu, Loader, ScrollArea } from "@mantine/core";

import { ComponentIconMap } from "../components/ComponentIconMap";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useComponents } from "../../../hooks/queries/useComponents";
import { useCreateThemeComponent } from "../../../hooks/mutations/useCreateThemeComponent";
import { validComponents } from "../../../utils/builder";
import { ComponentDisplay } from "../../../typings/Theme";
import { ITheme, IThemePage, IThemeComponent, important } from "shared";

type Props = {
  target: (isLoading: boolean) => React.ReactNode;
  componentDisplay: ComponentDisplay;
  parentComponent?: IThemeComponent;
};

function AddSection({ target, componentDisplay, parentComponent }: Props) {
  const { theme } = useParams();
  const { activePage } = useBuilderQuery();
  const form = useFormContext<ITheme<IThemePage>>();

  const { data: allComponents, isLoading: isComponentsLoading } = useComponents();
  const { mutate: createThemeComponent, isLoading: isCreateComponentLoading } = useCreateThemeComponent();

  const pageIndex = form.getValues("pages").findIndex((page) => page.id === activePage);

  const themeComponents =
    componentDisplay === "content"
      ? form.getValues(`pages.${pageIndex}.pageComponents`)
      : form.getValues(`${componentDisplay}Components`);

  const components =
    parentComponent?.allowedChildren || validComponents({ display: componentDisplay, allComponents, themeComponents });

  return (
    <Menu
      withArrow
      shadow="lg"
      position="top"
      arrowSize={12}
      arrowPosition="center"
      styles={{ dropdown: { zIndex: important(1000) } }}
    >
      <Menu.Target>{target(isCreateComponentLoading)}</Menu.Target>

      <Menu.Dropdown>
        <ScrollArea.Autosize mah={200}>
          {isComponentsLoading && (
            <Menu.Item>
              <Loader variant="dots" />
            </Menu.Item>
          )}
          {components?.map((component) => (
            <Menu.Item
              key={component.id}
              icon={ComponentIconMap[component.key]}
              onClick={() =>
                createThemeComponent({
                  themeId: Number(theme),
                  componentId: component.id,
                  parentId: parentComponent?.id,
                  display: componentDisplay,
                  ...(activePage && componentDisplay === "content" && { page: { id: activePage, index: pageIndex } }),
                })
              }
            >
              {component.name}
            </Menu.Item>
          ))}
        </ScrollArea.Autosize>
      </Menu.Dropdown>
    </Menu>
  );
}

export default AddSection;
