import { useMantineTheme } from "@mantine/core";

const MobileIcon = ({ stroke }: { stroke: "white" | "dark-gray" }) => {
  const theme = useMantineTheme();

  return (
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2141 1.21436H2.78551C1.99653 1.21436 1.35693 1.85395 1.35693 2.64293V18.3572C1.35693 19.1462 1.99653 19.7858 2.78551 19.7858H14.2141C15.0031 19.7858 15.6426 19.1462 15.6426 18.3572V2.64293C15.6426 1.85395 15.0031 1.21436 14.2141 1.21436Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0711 4.78564H4.92822V11.9285H12.0711V4.78564Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49993 16.5716C8.89442 16.5716 9.21422 16.2518 9.21422 15.8574C9.21422 15.4629 8.89442 15.1431 8.49993 15.1431C8.10544 15.1431 7.78564 15.4629 7.78564 15.8574C7.78564 16.2518 8.10544 16.5716 8.49993 16.5716Z"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MobileIcon;
