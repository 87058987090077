import { useQuery } from "@tanstack/react-query";

import { useBuilderQuery } from "../custom/useBuilderQuery";
import { getNavigationByIds } from "../../api/Navigation";

export const useNavigationByIds = ({ component, ids }: { component: "header" | "footer"; ids?: string[] }) => {
  const { locale } = useBuilderQuery();
  return useQuery({
    queryKey: ["navigation", locale, component, ids],
    queryFn: () => {
      if (!ids || ids.length === 0) return [];
      return getNavigationByIds({ ids, locale });
    },
    keepPreviousData: true,
  });
};
