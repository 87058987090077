import { Box, Group } from '@mantine/core';
import Link from 'next/link';

import { HeaderBasicProps } from '../../../typings/Header';
import SocialMediaIcons from '../../common/SocialMediaIcons';
import CurrencyMenu from './CurrencyMenu';
import NavigationList from './NavigationList';

const DesktopHeader = ({
  theme,
  logoImage,
  txtColor,
  bgColor,
  socialMediaIconsColor,
  showSocialMediaIcons,
  showCurrencyMenu,
  currencies,
  withinBuilder,
  componentChildren,
  sideMenuBgColor,
  sideMenuTextColor,
  data,
  company,
  children,
  ...props
}: HeaderBasicProps) => {
  const showMiniHeader = showSocialMediaIcons || (showCurrencyMenu && currencies);

  return (
    <Box {...props} bg={bgColor}>
      {showMiniHeader ? (
        <Group position="apart" p={15}>
          {showCurrencyMenu && currencies && (
            <Box
              sx={{
                '&:only-child': {
                  margin: '0 auto',
                },
              }}
            >
              <CurrencyMenu
                color={txtColor}
                currency={company?.paymentCurrency}
                currencies={currencies}
              />
            </Box>
          )}
          {showSocialMediaIcons && (
            <Box
              sx={{
                '&:only-child': {
                  margin: '0 auto',
                },
              }}
            >
              <SocialMediaIcons company={company} color={socialMediaIconsColor} />
            </Box>
          )}
        </Group>
      ) : null}
      <Group sx={{ height: 80 }} position="apart" px={15} py={15}>
        <Link href={theme.landingPageUrl || '/'} passHref>
          <Box sx={{ maxWidth: '50%' }} w="fit-content" h={50} component="a">
            <img
              style={{ objectFit: 'contain' }}
              height={50}
              src={logoImage.value[0] || theme.logoUrl}
              alt={company?.name}
            />
          </Box>
        </Link>
        {data ? <NavigationList txtColor={txtColor} navItems={data} /> : null}
        <Group spacing={15}>{children}</Group>
      </Group>
    </Box>
  );
};

export default DesktopHeader;
