import { useQuery } from "@tanstack/react-query";

import { useBuilderQuery } from "../custom/useBuilderQuery";
import { getProducts } from "../../api/Product";
import { IProduct } from "shared";

export const useProducts = (params?: { collectionId?: string; ids?: string[] }) => {
  const { locale } = useBuilderQuery();
  const { ids, collectionId } = params || {};
  return useQuery<IProduct[]>(["products", ids, collectionId, locale], () =>
    getProducts({ locale, ids, collectionId }),
  );
};
