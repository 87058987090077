import { useQuery } from "@tanstack/react-query";

import { useBuilderQuery } from "../custom/useBuilderQuery";
import { getCollections } from "../../api/Collection";
import { ICollection } from "shared";

export const useCollections = () => {
  const { locale } = useBuilderQuery();
  return useQuery<ICollection[]>(["collections", locale], () => getCollections(locale));
};
