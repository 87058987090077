import { Box, Burger, Group } from '@mantine/core';
import Link from 'next/link';
import { Children, useState } from 'react';

import { HeaderBasicProps } from '../../../typings/Header';
import SocialMediaIcons from '../../common/SocialMediaIcons';
import SideMenu from './SideMenu';

const MobileHeader = ({
  theme,
  logoImage,
  txtColor,
  bgColor,
  sideMenuTextColor,
  sideMenuBgColor,
  socialMediaIconsColor,
  showSocialMediaIcons,
  showCurrencyMenu,
  currencies,
  withinBuilder,
  componentChildren,
  company,
  children,
  data,
  ...props
}: HeaderBasicProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Box {...props} bg={bgColor}>
      {showSocialMediaIcons ? (
        <Group position="center" p={15}>
          <SocialMediaIcons company={company} color={socialMediaIconsColor} />
        </Group>
      ) : null}
      <Group sx={{ height: 70 }} position="apart" px={10} py={10}>
        <Burger
          size={18}
          opened={isOpen}
          title="Open navigation"
          aria-label="Open navigation"
          color={txtColor}
          onClick={() => setIsOpen(!isOpen)}
        />
        <Link href={theme.landingPageUrl || '/'} passHref>
          <Box
            w="fit-content"
            h={45}
            sx={{
              maxWidth: 100,
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
            component="a"
          >
            <img
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
              height={45}
              src={logoImage.value[0] || theme.logoUrl}
              alt={company?.name}
            />
          </Box>
        </Link>

        {Children.toArray(children).find((child: any) => child.props.id === 'header-children')}
      </Group>
      {isOpen && (
        <SideMenu
          sideMenuTextColor={sideMenuTextColor}
          sideMenuBgColor={sideMenuBgColor}
          showSocialMediaIcons={showSocialMediaIcons}
          showCurrencyMenu={showCurrencyMenu}
          currency={company?.paymentCurrency}
          currencies={currencies}
          data={data}
          closeMenu={() => setIsOpen(false)}
        >
          {Children.toArray(children).find((child: any) => child.props.id === 'aside-children')}
        </SideMenu>
      )}
    </Box>
  );
};

export default MobileHeader;
