import { Flex, Text, Image } from "@mantine/core";
import Button from "../../common/Button";
import { useTranslation } from "react-i18next";
import { useCreateDefaultTheme } from "../../../hooks/mutations/useCreateDefaultTheme";
import add from "../../../assets/icons/add.svg";

function EmptyThemesList() {
  const { t } = useTranslation("home");
  const { mutate: createDefaultTheme, isLoading, isError } = useCreateDefaultTheme();

  return (
    <Flex h={224} w="100%" direction="column" justify="center" align="center" gap="lg">
      <Text size={16} weight={300} color={isError ? "red" : "dark-gray"}>
        {isError ? t("empty.error") : t("empty.title")}
      </Text>
      <Button
        mode="primary"
        variant="light"
        color="light-magenta"
        h={45}
        leftIcon={<Image src={add} alt="add" />}
        styles={(theme) => ({ root: { border: `1px dashed ${theme.colors["secondary-magenta"][0]}` } })}
        loaderProps={{ color: "dark" }}
        loading={isLoading}
        onClick={createDefaultTheme}
      >
        <Text size={16} weight={300} color="black">
          {t("empty.button")}
        </Text>
      </Button>
    </Flex>
  );
}

export default EmptyThemesList;
