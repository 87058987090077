import { useTranslation } from "react-i18next";
import { Badge, Card, Stack, Text } from "@mantine/core";

import ActionsGroup from "./ActionsGroup";

import { ITheme, IThemePage } from "shared";

const DraftThemeCard = ({ theme }: { theme: ITheme<IThemePage> }) => {
  const { t } = useTranslation("home");

  return (
    <Card
      px={30}
      py={20}
      h={220}
      radius={5}
      withBorder
      bg="white"
      sx={{ boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)" }}
    >
      <Stack align="center" spacing={20}>
        <Text h={19} size={16} weight={700} color={"primary-black"}>
          {theme.name}
        </Text>

        <Badge variant="filled" radius={5} h={27} bg="dark-gray">
          {t("theme_library.draft")}
        </Badge>
        <Text size={16} weight={300} color="high-gray">
          {t("theme_library.added_at", { date: new Date(theme.createdAt).toDateString() })}
        </Text>
        <ActionsGroup isActiveTheme={false} theme={theme} />
      </Stack>
    </Card>
  );
};

export default DraftThemeCard;
