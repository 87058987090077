import { useTranslation } from "react-i18next";
import { Box, Text } from "@mantine/core";

import ColoredDotsLoader from "../../../../animations/ColoredDotsLoader.gif";

const LoadingSkeleton = () => {
  const { t } = useTranslation("sections");

  return (
    <Box
      w="100%"
      h={139}
      sx={(theme) => ({
        border: `1px solid ${theme.colors["low-gray"][0]}`,
        textAlign: "center",
      })}
    >
      <img
        width={100}
        height={100}
        alt="loading"
        src={ColoredDotsLoader}
        style={{ marginTop: -10, marginBottom: -10 }}
      />
      <Text size={14} weight={400} lh={1.2} color="dark-gray" mb={10}>
        {t("config-sidebar.image-uploader.upload-image")}
      </Text>
      <Text size={12} weight={300} lh={1.2} color="med-gray">
        {t("config-sidebar.image-uploader.image-instructions")}
      </Text>
    </Box>
  );
};

export default LoadingSkeleton;
