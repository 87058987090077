import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, ScrollArea, createStyles } from "@mantine/core";

import DeviceFrame from "../../components/builder/components/DeviceFrame";
import PagePreview from "../../components/builder/sections/PagePreview";
import ConfigSidebar from "../../components/builder/sidebar/ConfigSidebar";

import { useBuilderQuery } from "../../hooks/custom/useBuilderQuery";
import { usePages } from "../../hooks/queries/usePages";
import { important } from "shared";

const useStyles = createStyles((theme) => ({
  scrollArea: {
    height: "calc(100vh - 75px)",
  },
  scrollViewport: {
    paddingTop: 30,
    paddingBottom: 70,

    ">div:first-of-type": {
      display: important("block"),
    },
  },
  scrollBar: {
    zIndex: 1000,
  },
  configSidebar: {
    height: "calc(100vh - 75px)",
    overflowY: "scroll",
    borderLeft: `1px solid ${theme.colors["low-gray"][0]}`,
    backgroundColor: "white",
  },
}));

const Sections = () => {
  const params = useParams();

  const { classes, cx } = useStyles();
  const { activePage, replaceActivePage, device } = useBuilderQuery();

  const themeId = Number(params.theme);
  const { data: pages } = usePages(themeId);

  useEffect(() => {
    if (!activePage && pages) {
      replaceActivePage(pages[0].id);
    }
  }, [pages]);

  return (
    <Grid m={0}>
      <Grid.Col p={0} span={8}>
        <DeviceFrame device={device}>
          <ScrollArea
            classNames={{ root: classes.scrollArea, scrollbar: classes.scrollBar, viewport: classes.scrollViewport }}
          >
            <PagePreview key={activePage} />
          </ScrollArea>
        </DeviceFrame>
      </Grid.Col>

      <Grid.Col p={0} span={4}>
        <Box className={cx(classes.configSidebar)}>
          <ConfigSidebar key={activePage} />
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default Sections;
