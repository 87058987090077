const FacebookIcon = ({ fill }: { fill: string }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.25 0.5C17.283 0.5 18.1662 0.866753 18.8997 1.60026C19.6332 2.33377 20 3.21701 20 4.25V16.75C20 17.783 19.6332 18.6662 18.8997 19.3997C18.1662 20.1332 17.283 20.5 16.25 20.5H13.8021V12.7526H16.3932L16.7839 9.73177H13.8021V7.80469C13.8021 7.31858 13.9041 6.95399 14.1081 6.71094C14.3121 6.46788 14.7092 6.34635 15.2995 6.34635L16.888 6.33333V3.63802C16.3411 3.5599 15.5686 3.52083 14.5703 3.52083C13.3898 3.52083 12.4457 3.86806 11.7383 4.5625C11.0308 5.25694 10.6771 6.23785 10.6771 7.50521V9.73177H8.07292V12.7526H10.6771V20.5H3.75C2.71701 20.5 1.83377 20.1332 1.10026 19.3997C0.366753 18.6662 0 17.783 0 16.75V4.25C0 3.21701 0.366753 2.33377 1.10026 1.60026C1.83377 0.866753 2.71701 0.5 3.75 0.5H16.25Z"
      fill={fill}
    />
  </svg>
);

export default FacebookIcon;
