import { useTranslation } from "react-i18next";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Layout from "./components/layout/Layout";
import BuilderLayout from "./components/builder/layout/BuilderLayout";

import Home from "./pages/Home";
import Sections from "./pages/builder/Sections";
import Settings from "./pages/builder/Settings";
import Pages from "./pages/builder/Pages";
import Page from "./components/builder/themePages/Page";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import NotFound from "./pages/404/NotFound";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5 * 60 * 1000 } },
});

function App() {
  const { i18n } = useTranslation();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/:locale?" element={<Layout />} handle={{ crumb: "home" }}>
        <Route index element={<Home />} />
        {/* <Route path="links" element={<CompanyLinks />} handle={{ crumb: "links" }} /> */}
        <Route path="builder/:theme" element={<BuilderLayout />}>
          <Route path="sections" element={<Sections />} handle={{ crumb: "sections" }} />
          <Route path="settings/:activeTab" element={<Settings />} handle={{ crumb: "settings" }} />
          <Route path="pages" element={<Pages />} handle={{ crumb: "pages" }}>
            <Route path=":activePage" element={<Page />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>,
    ),
  );

  return (
    <div dir={i18n.dir(i18n.language)}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
