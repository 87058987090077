import { Bounce, Fade, Flip, JackInTheBox, Roll, Rotate, Slide, Zoom } from 'react-awesome-reveal';

import { Direction, FlipDirection, IThemeComponentAnimation, RotateDirection } from '../typings';

type Props = {
  animation: IThemeComponentAnimation;
  children: React.ReactNode;
};

export const ZammitLayoutEffect = ({ animation, children }: Props) => {
  const { effect, direction, duration, delay, triggerOnce } = animation;

  switch (effect) {
    case 'Bounce':
      return (
        <Bounce duration={duration} delay={delay} triggerOnce={triggerOnce}>
          {children}
        </Bounce>
      );
    case 'Fade':
      return (
        <Fade
          direction={direction as Direction}
          duration={duration}
          delay={delay}
          triggerOnce={triggerOnce}
        >
          {children}
        </Fade>
      );
    case 'Flip':
      return (
        <Flip
          direction={direction as FlipDirection}
          duration={duration}
          delay={delay}
          triggerOnce={triggerOnce}
        >
          {children}
        </Flip>
      );
    case 'JackInTheBox':
      return (
        <JackInTheBox duration={duration} delay={delay} triggerOnce={triggerOnce}>
          {children}
        </JackInTheBox>
      );
    case 'Roll':
      return (
        <Roll duration={duration} delay={delay} triggerOnce={true}>
          {children}
        </Roll>
      );
    case 'Rotate':
      return (
        <Rotate
          direction={direction as RotateDirection}
          duration={duration}
          delay={delay}
          triggerOnce={triggerOnce}
        >
          {children}
        </Rotate>
      );
    case 'Slide':
      return (
        <Slide
          direction={direction as Direction}
          duration={duration}
          delay={delay}
          triggerOnce={triggerOnce}
        >
          {children}
        </Slide>
      );
    case 'Zoom':
      return (
        <Zoom
          direction={direction as Direction}
          duration={duration}
          delay={delay}
          triggerOnce={triggerOnce}
        >
          {children}
        </Zoom>
      );
    default:
      return <>{children}</>;
  }
};
