import { HeaderBasicProps } from '../../../typings';
import { hasEnabledCompanyLinks } from '../../../utils';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export interface HeaderProps extends HeaderBasicProps {
  isMobile?: boolean;
}

export const Header = ({ isMobile, showSocialMediaIcons, company, ...props }: HeaderProps) => {
  const showSocialMediaIconsFlag = showSocialMediaIcons && hasEnabledCompanyLinks(company);
  return isMobile ? (
    <MobileHeader showSocialMediaIcons={showSocialMediaIconsFlag} company={company} {...props} />
  ) : (
    <DesktopHeader showSocialMediaIcons={showSocialMediaIconsFlag} company={company} {...props} />
  );
};
