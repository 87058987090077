import { Badge, Box, Group, Text } from '@mantine/core';

import { usePrice } from '../../../hooks';
import { IPage, ITheme, ValidLocales } from '../../../typings';
import { getPriceRangeWithTemplate } from '../../../utils';

type Props = {
  theme: ITheme<IPage>;
  price:
    | {
        original: number;
        salePrice: number | null;
      }
    | {
        original: number;
        salePrice: {
          minPrice: number;
          maxPrice: number;
        };
      }
    | {
        original: {
          minPrice: number;
          maxPrice: number;
        };
        salePrice: number;
      }
    | {
        original: {
          minPrice: number;
          maxPrice: number;
        };
        salePrice: {
          minPrice: number;
          maxPrice: number;
        } | null;
      };
  locale?: ValidLocales;
  currency?: string;
  priceDecimalPoints?: number;
  priceTemplate?: string;
};

const ProductPrice = ({
  theme,
  price,
  currency,
  priceDecimalPoints,
  priceTemplate,
  locale,
}: Props) => {
  const { formatPrice } = usePrice({ locale, currency, priceDecimalPoints });
  const isOnSale = price ? !!price.salePrice : false;

  return (
    <Box>
      <Group my={10} position="apart" style={{ justifyContent: 'flex-start', gap: 10 }}>
        <Text size={16} weight={900} color={isOnSale ? theme.salePriceColor : theme.priceColor}>
          {isOnSale && price.salePrice
            ? typeof price.salePrice === 'object'
              ? getPriceRangeWithTemplate({
                  priceRange: price.salePrice,
                  priceTemplate,
                  formatPrice,
                })
              : formatPrice(price.salePrice)
            : typeof price.original === 'object'
            ? getPriceRangeWithTemplate({ priceRange: price.original, priceTemplate, formatPrice })
            : formatPrice(price.original)}{' '}
        </Text>
        {isOnSale && (
          <Text size={16} weight={500} color="med-gray" strikethrough>
            {typeof price.original === 'object'
              ? getPriceRangeWithTemplate({
                  priceRange: price.original,
                  priceTemplate,
                  formatPrice,
                })
              : formatPrice(price.original)}{' '}
          </Text>
        )}
      </Group>
      {isOnSale && (
        <Badge sx={{ backgroundColor: theme.salePriceColor }} radius="xs" variant="filled">
          {locale === 'ar' ? 'خصم' : 'Sale'}
        </Badge>
      )}
    </Box>
  );
};

export default ProductPrice;
