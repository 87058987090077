import { UnstyledButton, useMantineTheme } from "@mantine/core";

const Icon = () => {
  const theme = useMantineTheme();

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.14258 3.5715L9.99972 0.714355L12.8569 3.5715"
        stroke={theme.colors["secondary-magenta"][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.14258 16.4287L9.99972 19.2859L12.8569 16.4287"
        stroke={theme.colors["secondary-magenta"][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 0.714355V19.2858"
        stroke={theme.colors["secondary-magenta"][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4277 7.14307L19.2849 10.0002L16.4277 12.8574"
        stroke={theme.colors["secondary-magenta"][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.57101 7.14307L0.713867 10.0002L3.57101 12.8574"
        stroke={theme.colors["secondary-magenta"][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2853 10H0.713867"
        stroke={theme.colors["secondary-magenta"][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DragIcon = () => {
  const theme = useMantineTheme();
  return (
    <UnstyledButton
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 30,
        height: 30,
        borderRadius: 5,
        backgroundColor: theme.colors["light-gray"][0],
      }}
    >
      <Icon />
    </UnstyledButton>
  );
};

export default DragIcon;
