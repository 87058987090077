const TikTokIcon = ({ fill }: { fill: string }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2455_19494)">
      <path
        d="M15.3039 0.708252H4.69641C3.50679 0.710345 2.36648 1.18385 1.52529 2.02504C0.684094 2.86624 0.210589 4.00654 0.208496 5.19617L0.208496 15.8037C0.210589 16.9933 0.684094 18.1336 1.52529 18.9748C2.36648 19.816 3.50679 20.2895 4.69641 20.2916H15.3039C16.4935 20.2895 17.6338 19.816 18.475 18.9748C19.3162 18.1336 19.7897 16.9933 19.7918 15.8037V5.19617C19.7897 4.00654 19.3162 2.86624 18.475 2.02504C17.6338 1.18385 16.4935 0.710345 15.3039 0.708252ZM16.1197 9.072C15.3492 9.06797 14.588 8.90253 13.8853 8.58633C13.1825 8.27013 12.5539 7.81019 12.0397 7.23617V13.3562C12.0397 14.1631 11.8005 14.9519 11.3521 15.6229C10.9038 16.2938 10.2666 16.8168 9.5211 17.1256C8.77557 17.4344 7.95522 17.5152 7.16378 17.3578C6.37233 17.2003 5.64535 16.8118 5.07475 16.2412C4.50415 15.6706 4.11557 14.9436 3.95814 14.1521C3.80071 13.3607 3.88151 12.5403 4.19032 11.7948C4.49912 11.0493 5.02207 10.4121 5.69302 9.96377C6.36397 9.51546 7.1528 9.27617 7.95975 9.27617C8.09641 9.28169 8.23262 9.29532 8.36766 9.317V11.357C8.23324 11.3309 8.09668 11.3172 7.95975 11.3162C7.55627 11.3162 7.16186 11.4358 6.82638 11.66C6.49091 11.8841 6.22943 12.2027 6.07503 12.5755C5.92063 12.9483 5.88023 13.3584 5.95894 13.7542C6.03766 14.1499 6.23195 14.5134 6.51725 14.7987C6.80255 15.084 7.16604 15.2783 7.56176 15.357C7.95748 15.4357 8.36766 15.3953 8.74042 15.2409C9.11318 15.0865 9.43179 14.825 9.65594 14.4895C9.8801 14.1541 9.99975 13.7596 9.99975 13.3562V3.5645H12.4477L12.5702 4.05409C12.767 4.8465 13.2235 5.55019 13.8668 6.05298C14.5101 6.55577 15.3033 6.82869 16.1197 6.82825V9.072Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2455_19494">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default TikTokIcon;
