import { useEffect, useState } from 'react';

// we added this custom hook because the script component does not work on page changes when using Next Link component for navigation. It only works on initial load and reload
export const useExternalScript = (url: string, onLoadFunc?: () => void) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (onLoadFunc) onLoadFunc();
      setIsLoaded(true);
    };
    script.onerror = () => setError(true);

    return () => {
      document.head.removeChild(script);
    };
  }, [url, onLoadFunc]);

  return {
    isLoaded,
    error,
  };
};
