import { Anchor, Group, Menu, Text } from '@mantine/core';
import { IconSelect } from '@tabler/icons-react';
import Link from 'next/link';
import { useState } from 'react';
import { CircleFlag } from 'react-circle-flags';

import { Currency } from '../../../typings/Theme';

interface Props {
  color?: string;
  currency?: string;
  currencies: Currency[];
}

const CurrencyMenu = ({ color, currency = 'EGP', currencies, ...props }: Props) => {
  const delay = 300;

  const currentCurrencyObj = currencies.filter(
    (currencyObj) => currencyObj.currency === currency,
  )[0];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      transitionProps={{ transition: 'fade', duration: delay, timingFunction: 'ease-in-out' }}
      width={80}
      {...props}
    >
      <Menu.Target>
        <Group
          noWrap
          w={80}
          spacing={10}
          position={currentCurrencyObj ? 'apart' : 'right'}
          sx={{ cursor: 'pointer' }}
        >
          {currentCurrencyObj ? (
            <>
              <CircleFlag
                countryCode={currentCurrencyObj?.countryCode.toLowerCase()}
                height={15}
                width={15}
              />
              <Text size={12} weight={400} color={color}>
                {currentCurrencyObj?.currency}
              </Text>
            </>
          ) : null}
          <IconSelect
            size={20}
            color={color}
            style={{
              transition: `transform ${delay}ms ease-in-out`,
              transform: isOpen ? 'rotate(180deg)' : '',
            }}
          />
        </Group>
      </Menu.Target>

      <Menu.Dropdown p={0}>
        {currencies.map(({ countryCode, currency, link }) => (
          <Menu.Item
            key={countryCode}
            icon={<CircleFlag countryCode={countryCode.toLowerCase()} height={15} width={15} />}
          >
            <Link href={link} passHref>
              <Anchor component="a">
                <Text size={12} weight={400}>
                  {currency}
                </Text>
              </Anchor>
            </Link>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default CurrencyMenu;
