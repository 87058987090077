import React from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Group, Stack, Text, Loader, createStyles, Flex } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";

import ArrowButton from "../../common/ArrowButton";
import TreeView from "../treeView/TreeView";
import PropsConfig from "./PropsConfig";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useDeleteThemeComponent } from "../../../hooks/mutations/useDeleteThemeComponent";
import { ITheme, IThemePage } from "shared";

const useStyles = createStyles((theme) => ({
  topMenuIcons: {
    transition: "all 0.2s ease",
    stroke: theme.colors["secondary-magenta"][0],
    strokeWidth: 1,
    "&:hover": {
      strokeWidth: 1.5,
    },
  },
}));

const ConfigSidebar = () => {
  const { classes } = useStyles();
  const { i18n } = useTranslation();
  const { activePage, activeComponent, setActiveComponent } = useBuilderQuery();
  const { mutate: deleteThemeComponent, isLoading: isDeleteLoading } = useDeleteThemeComponent();

  const form = useFormContext<ITheme<IThemePage>>();
  const pageIndex = form.getValues("pages").findIndex((page) => page.id === activePage);
  const pageComponents = useFieldArray({
    name: `pages.${pageIndex}.pageComponents`,
    control: form.control,
    keyName: "formId",
  }).fields;

  const topComponents = useFieldArray({ name: "topComponents", control: form.control, keyName: "formId" }).fields;
  const bottomComponents = useFieldArray({ name: "bottomComponents", control: form.control, keyName: "formId" }).fields;
  const overlayComponents = useFieldArray({
    name: "overlayComponents",
    control: form.control,
    keyName: "formId",
  }).fields;

  if (!activeComponent) return <TreeView />;

  const { componentPrefix, componentIndex, component } = (() => {
    let component = null;
    let componentIndex = null;
    let componentPrefix:
      | "topComponents"
      | "bottomComponents"
      | "overlayComponents"
      | `pages.${number}.pageComponents` = `pages.${pageIndex}.pageComponents`;

    const pageComponentIndex = pageComponents.findIndex((component) => component.id === activeComponent);

    if (pageComponentIndex !== -1) {
      componentIndex = pageComponentIndex;
      component = pageComponents[componentIndex];
    } else {
      const topComponentIndex = topComponents.findIndex((component) => component.id === activeComponent);

      if (topComponentIndex !== -1) {
        componentIndex = topComponentIndex;
        component = topComponents[componentIndex];
        componentPrefix = "topComponents";
      } else {
        const bottomComponentIndex = bottomComponents.findIndex((component) => component.id === activeComponent);

        if (bottomComponentIndex !== -1) {
          componentIndex = bottomComponentIndex;
          component = bottomComponents[componentIndex];
          componentPrefix = "bottomComponents";
        } else {
          componentIndex = overlayComponents.findIndex((component) => component.id === activeComponent);
          component = overlayComponents[componentIndex];
          componentPrefix = "overlayComponents";
        }
      }
    }

    return { componentPrefix, componentIndex, component };
  })();

  if (!component) return <></>;

  return (
    <form>
      <Stack spacing={20} p={15}>
        <Group position="apart">
          <Flex gap={10}>
            <ArrowButton
              dir={i18n.language === "en" ? "left" : "right"}
              onClick={() => setActiveComponent(undefined)}
            />
            <Text fw={700}>{component.name}</Text>
          </Flex>
          {isDeleteLoading ? (
            <Loader color="secondary-magenta" variant="dots" size="sm" />
          ) : (
            <IconTrash
              className={classes.topMenuIcons}
              onClick={() => deleteThemeComponent({ id: component.id, pageIndex, display: component.display })}
            />
          )}
        </Group>
        <PropsConfig
          pageIndex={pageIndex}
          component={component}
          componentPrefix={componentPrefix}
          componentIndex={componentIndex}
        />
      </Stack>
    </form>
  );
};

export default React.memo(ConfigSidebar);
