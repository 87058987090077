import { useTranslation } from "react-i18next";
import { Group, Menu } from "@mantine/core";

import Button from "../../common/Button";
import LinkButton from "../../common/LinkButton";
import MenuButton from "./MenuButton";

import { useThemePublish } from "../../../hooks/mutations/useThemePublish";
import { ITheme, IThemePage } from "shared";
import { useDeleteTheme } from "../../../hooks/mutations/useDeleteTheme";

type Props = { isActiveTheme: boolean; theme: ITheme<IThemePage> };

const ActionsGroup = ({ isActiveTheme, theme }: Props) => {
  const { t } = useTranslation("home");
  const { mutate: publishTheme, isLoading: isPublishLoading } = useThemePublish();
  const { mutate: deleteTheme, isLoading: isDeleteLoading } = useDeleteTheme();

  return (
    <Group>
      {((isActiveTheme && theme.hasUnpublishedChanges) || !isActiveTheme) && (
        <Button
          mode="primary"
          h={45}
          onClick={() => publishTheme({ theme: { id: theme.id } })}
          loading={isPublishLoading}
        >
          {t("theme_library.actions.publish")}
        </Button>
      )}
      {isActiveTheme && (
        <LinkButton
          mode={theme.hasUnpublishedChanges ? "secondary" : "primary"}
          h={45}
          to={`/builder/${theme.id}/sections`}
        >
          {t("theme_library.actions.customize")}
        </LinkButton>
      )}
      {!isActiveTheme && (
        <LinkButton mode="secondary" h={45} to={`/builder/${theme.id}/sections`}>
          {t("theme_library.actions.edit")}
        </LinkButton>
      )}

      {!isActiveTheme && !isPublishLoading && (
        <Menu shadow="sm" width={150} position="bottom" withinPortal>
          <Menu.Target>
            <MenuButton bgColor={isActiveTheme ? "white" : "light-gray"} />
          </Menu.Target>
          <Menu.Dropdown>
            {/* <Menu.Item> {t("theme_library.actions.duplicate")}</Menu.Item> */}
            <Menu.Item onClick={() => deleteTheme({ id: theme.id })} disabled={isDeleteLoading}>
              {t("theme_library.actions.delete")}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
    </Group>
  );
};

export default ActionsGroup;
