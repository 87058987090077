import { Center, Loader } from "@mantine/core";
import Header from "../../builder/layout/Header";

const SectionsSkeleton = () => {
  return (
    <Center h="calc(100vh - 155px)">
      <Header />
      <Loader size="xl" variant="dots" />
    </Center>
  );
};

export default SectionsSkeleton;
