import Image, { ImageProps } from 'next/future/image';

import downArrow from '../../assets/icons/down_arrow.svg';

interface Props extends Omit<ImageProps, 'src' | 'alt'> {
  color?: string;
  className?: string;
  size: string | number;
  alt?: string;
}

const DownArrowIcon = ({ color, className, size, alt, ...props }: Props) => (
  <Image
    className={className}
    color={color}
    width={size}
    height={size}
    src={downArrow}
    alt={alt !== undefined ? alt : 'down arrow'}
    {...props}
  />
);

export default DownArrowIcon;
