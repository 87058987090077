const GreenPlusSquareIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="5" fill="#45B467" fillOpacity="0.1" />
      <g clipPath="url(#clip0_337_11190)">
        <path
          d="M24.2852 22.8051C24.2852 23.6228 23.6224 24.2858 22.8045 24.2858H10.9592C10.1414 24.2858 9.47852 23.6228 9.47852 22.8051V10.9597C9.47852 10.1419 10.1414 9.479 10.9592 9.479H22.8045C23.6224 9.479 24.2852 10.1419 24.2852 10.9597V22.8051Z"
          stroke="#45B467"
          strokeLinejoin="round"
        />
        <path
          d="M5.71387 19.0404V7.19503C5.71387 6.37728 6.37679 5.71436 7.19454 5.71436H19.0399"
          stroke="#45B467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8822 13.9209V16.8822M16.8822 16.8822V19.8436M16.8822 16.8822H19.8436M16.8822 16.8822H13.9209"
          stroke="#45B467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_337_11190">
          <rect width="20" height="20" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GreenPlusSquareIcon;
