import { Link } from "react-router-dom";
import { useMantineTheme } from "@mantine/core";

import SectionsIcon from "../../icons/SectionsIcon";
import ThemeSettingsIcon from "../../icons/ThemeSettingsIcon";
import PagesIcon from "../../icons/PagesIcon";

import { BuilderNavIcons } from "../../../typings";

const getIconComponent = ({ stroke, icon }: { stroke: "white" | "dark-gray"; icon: BuilderNavIcons }) => {
  switch (icon) {
    case "sections":
      return <SectionsIcon stroke={stroke} />;
    case "settings":
      return <ThemeSettingsIcon stroke={stroke} />;
    case "pages":
      return <PagesIcon stroke={stroke} />;
  }
};

const IconLink = ({ link, isActive, icon }: { link: string; isActive: boolean; icon: BuilderNavIcons }) => {
  const theme = useMantineTheme();
  return (
    <Link
      to={link}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 43,
        height: 43,
        borderRadius: 5,
        backgroundColor: isActive ? theme.colors["secondary-magenta"][0] : theme.colors["light-gray"][0],
      }}
    >
      {getIconComponent({ stroke: isActive ? "white" : "dark-gray", icon })}
    </Link>
  );
};

export default IconLink;
