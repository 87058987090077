import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { createStyles, Header as MantineHeader, Text, Group, Image, Divider, UnstyledButton } from "@mantine/core";

import ExitButton from "../builder/components/ExitButton";
import Account from "./components/Account";
import LanguageSwitcher from "./components/LanguageSwitcher";

import ZammitLogo from "../../assets/logo.svg";

const useStyles = createStyles((theme) => ({
  logoContainer: {
    borderRadius: 10,
    backgroundColor: theme.colors["primary-black"][0],
  },
}));

const Header = () => {
  const { t } = useTranslation("common");
  const { classes } = useStyles();

  return (
    <MantineHeader
      height={80}
      px={30}
      py={15}
      styles={(theme) => ({
        root: {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          borderBottomColor: theme.colors["low-gray"][0],
          zIndex: 999,
        },
      })}
    >
      <UnstyledButton sx={{ display: "flex", gap: 20 }} component={Link} to="/">
        <Group w={50} h={50} position="center" className={classes.logoContainer}>
          <Image width={26} height={32} src={ZammitLogo} alt="Zammit logo" />
        </Group>

        <Group position="apart" align="center" spacing={15}>
          <Text size={20} weight={900} color="primary-black">
            {t("zammit")}
          </Text>
          <Divider h={25} my={12} color="black" orientation="vertical" />
          <Text size={20} weight={500} color="dark-gray">
            {t("website_builder")}
          </Text>
        </Group>
      </UnstyledButton>

      <Group spacing={40}>
        <Account />
        <LanguageSwitcher />
        <ExitButton />
      </Group>
    </MantineHeader>
  );
};

export default Header;
