import { DividerStylesParams, MantineThemeOverride } from "@mantine/core";

const createThemeColor = (color: string) => {
  const colorValues = new Array<string>(10);
  return colorValues.fill(color) as keyof MantineThemeOverride["colors"];
};

const createBuilderTheme = (dir: "rtl" | "ltr"): MantineThemeOverride => ({
  dir,
  fontFamily: "Inter",
  colors: {
    white: createThemeColor("#ffffff"),
    black: createThemeColor("#000000"),
    "ghost-white": createThemeColor("#f8f9fa"),
    "primary-black": createThemeColor("#121118"),
    "low-gray": createThemeColor("#CDCCD7"),
    "high-gray": createThemeColor("#5D5D69"),
    "dark-gray": createThemeColor("#404C60"),
    "med-gray": createThemeColor("#A8A8A8"),
    "primary-green": createThemeColor("#45B467"),
    "light-gray": createThemeColor("#EDEFF3"),
    "light-red": createThemeColor("#EBEBEB"),
    "secondary-magenta": createThemeColor("#4F3FA8"),
    "light-magenta": createThemeColor("#dcd9ee"),
    "smoky-white": createThemeColor("#F2F4F5"),
    red: createThemeColor("#C13749"),
  },
  components: {
    Button: {
      defaultProps: {
        loaderPosition: "right",
      },
    },
    UnstyledButton: {
      styles: { root: { borderRadius: 5 } },
    },
    Divider: {
      styles: (_, params: DividerStylesParams) => ({
        root: {
          borderTopColor: params.color ? params.color[0] : `${["low-gray"][0]} !important`,
        },
      }),
    },
    Loader: {
      defaultProps: (theme) => ({
        color: theme.colors["secondary-magenta"][0],
      }),
    },
    TextInput: {
      styles: (theme) => ({ input: { "&:focus": { borderColor: theme.colors["secondary-magenta"][0] } } }),
    },
    Textarea: {
      styles: (theme) => ({ input: { "&:focus": { borderColor: theme.colors["secondary-magenta"][0] } } }),
    },
    NumberInput: {
      styles: (theme) => ({ input: { "&:focus": { borderColor: theme.colors["secondary-magenta"][0] } } }),
    },
    ColorInput: {
      styles: (theme) => ({ input: { "&:focus": { borderColor: theme.colors["secondary-magenta"][0] } } }),
    },
    Switch: {
      styles: { track: { cursor: "pointer" }, body: { justifyContent: "space-between" } },
    },
    Anchor: {
      defaultProps: { color: "secondary-magenta" },
    },
    Select: {
      styles: { rightSection: { pointerEvents: "none" } },
    },
    MultiSelect: {
      styles: { rightSection: { pointerEvents: "none" } },
    },
  },
});

export default createBuilderTheme;
