import { useQuery } from "@tanstack/react-query";
import { getSectionTemplates } from "../../api/SectionTemplates";

export const useSectionTemplates = (enabled: boolean) => {
  return useQuery({
    queryKey: ["sectionTemplates"],
    queryFn: getSectionTemplates,
    enabled,
    keepPreviousData: true,
  });
};
