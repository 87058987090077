import { TFunction } from 'i18next';

import { IProduct, IPurchaseProduct } from '../../typings';
import NumberInput from '../common/NumberInput';

type Props = {
  pricedBy: IProduct['pricedBy'];
  pricingUnit: string;
  pricingDetails: IPurchaseProduct['pricingDetails'];
  setPricingDetails: (value: IPurchaseProduct['pricingDetails']) => void;
  t: TFunction;
};

const DimensionsInputs = ({
  pricedBy,
  pricingUnit,
  pricingDetails,
  setPricingDetails,
  t,
}: Props) => {
  switch (pricedBy) {
    case 'area':
      return (
        <>
          <NumberInput
            label={t('width-label', { unit: pricingUnit.split('^')[0] })}
            value={pricingDetails?.width}
            onChange={(width) =>
              setPricingDetails({ ...pricingDetails, width: Number(width || 1) })
            }
          />
          <NumberInput
            label={t('length-label', { unit: pricingUnit.split('^')[0] })}
            value={pricingDetails?.length}
            onChange={(length) =>
              setPricingDetails({ ...pricingDetails, length: Number(length || 1) })
            }
          />
        </>
      );
    case 'weight':
      return (
        <NumberInput
          label={t('weight-label', { unit: pricingUnit })}
          value={pricingDetails?.weight || 0}
          onChange={(weight) =>
            setPricingDetails({ ...pricingDetails, weight: Number(weight || 1) })
          }
        />
      );
    default:
      return (
        <NumberInput
          label={t('length-label', { unit: pricingUnit })}
          value={pricingDetails?.length || 0}
          onChange={(length) =>
            setPricingDetails({ ...pricingDetails, length: Number(length || 1) })
          }
        />
      );
  }
};

export default DimensionsInputs;
