import { Group, Stack } from '@mantine/core';

import { INavigationItem } from '../../../typings/Navigation';
import NavItem from '../../common/NavItem';

const NavigationList = ({
  txtColor,
  navItems,
  isMobile,
}: {
  txtColor?: string;
  navItems: INavigationItem[];
  isMobile?: boolean;
}) => {
  return (
    <Group p={20} align="flex-start" position="center" spacing={isMobile ? 15 : 100}>
      {navItems.map((item) =>
        item.items.length ? (
          <Stack key={item.id}>
            <NavItem key={item.id} item={item} size={16} weight={500} color={txtColor} />
            {item.items.map((subItem) => (
              <NavItem key={subItem.id} item={subItem} size={14} weight={300} color={txtColor} />
            ))}
          </Stack>
        ) : (
          <NavItem key={item.id} item={item} size={16} weight={500} color={txtColor} />
        ),
      )}
    </Group>
  );
};

export default NavigationList;
