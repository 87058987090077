import { Control, FieldPath } from "react-hook-form";
import { NumberInput } from "react-hook-form-mantine";
import { Stack, Flex, Box, Text } from "@mantine/core";

import { ComponentPrefix } from "../../../../typings/Theme";
import { useTranslation } from "react-i18next";
import { ITheme, IThemePage } from "shared";

type Props = {
  name: FieldPath<ITheme<IThemePage>>;
  control: Control<ITheme<IThemePage>>;
};

const BoxModelEditor = ({ name, control }: Props) => {
  const { t } = useTranslation("sections");

  const namePrefix = name as `${ComponentPrefix}.${number}.propValues.${number}.value`;

  return (
    <Stack
      px={25}
      py={15}
      spacing={15}
      align="center"
      pos="relative"
      sx={{ border: "1px dashed #4F3FA8", borderRadius: 10, backgroundColor: "rgba(79, 63, 168, 0.05)" }}
    >
      <Text size={12} weight={400} color="med-gray" pos="absolute" top={10} left={25}>
        {t("config-sidebar.spacing.margin")}
      </Text>
      <NumberInput
        name={`${namePrefix}.margin.top`}
        control={control}
        w={35}
        variant="unstyled"
        size="xs"
        hideControls
        type="number"
        styles={{ input: { textAlign: "center", color: "#4F3FA8" } }}
      />
      <Flex w="100%" gap={15} align="center">
        <NumberInput
          name={`${namePrefix}.margin.left`}
          control={control}
          w={35}
          variant="unstyled"
          size="xs"
          hideControls
          type="number"
          styles={{ input: { textAlign: "center", color: "#4F3FA8" } }}
        />
        <Stack
          px={15}
          py={5}
          spacing={15}
          w="75%"
          align="center"
          pos="relative"
          sx={{ border: "1px solid #4F3FA8", borderRadius: 10, backgroundColor: "#ffffff" }}
        >
          <NumberInput
            name={`${namePrefix}.padding.top`}
            control={control}
            w={35}
            variant="unstyled"
            size="xs"
            hideControls
            type="number"
            styles={{ input: { textAlign: "center", color: "#4F3FA8" } }}
          />
          <Flex w="100%" gap={15}>
            <NumberInput
              name={`${namePrefix}.padding.left`}
              control={control}
              w={35}
              variant="unstyled"
              size="xs"
              hideControls
              type="number"
              styles={{ input: { textAlign: "center", color: "#4F3FA8" } }}
            />
            <Box sx={{ width: "60%", height: 30, borderRadius: 10, backgroundColor: "#EDEFF3" }} />
            <NumberInput
              name={`${namePrefix}.padding.right`}
              control={control}
              w={35}
              variant="unstyled"
              size="xs"
              hideControls
              type="number"
              styles={{ input: { textAlign: "center", color: "#4F3FA8" } }}
            />
          </Flex>
          <NumberInput
            name={`${namePrefix}.padding.bottom`}
            control={control}
            w={35}
            variant="unstyled"
            size="xs"
            hideControls
            type="number"
            styles={{ input: { textAlign: "center", color: "#4F3FA8" } }}
          />
          <Text size={12} weight={400} color="med-gray" pos="absolute" bottom={5} right={20}>
            {t("config-sidebar.spacing.padding")}
          </Text>
        </Stack>
        <NumberInput
          name={`${namePrefix}.margin.right`}
          control={control}
          w={35}
          variant="unstyled"
          size="xs"
          hideControls
          type="number"
          styles={{ input: { textAlign: "center", color: "#4F3FA8" } }}
        />
      </Flex>
      <NumberInput
        name={`${namePrefix}.margin.bottom`}
        control={control}
        w={35}
        variant="unstyled"
        size="xs"
        hideControls
        type="number"
        styles={{ input: { textAlign: "center", color: "#4F3FA8" } }}
      />
    </Stack>
  );
};

export default BoxModelEditor;
