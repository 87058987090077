import { forwardRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Group, Navbar as MantineNavbar, Tooltip } from "@mantine/core";

import IconLink from "../components/IconLink";
import SettingsNavbar from "../settings/SettingsNavbar";
import PagesNavbar from "../themePages/PagesNavbar";
import { BuilderNavIcons } from "../../../typings";

const navItems = ["sections", "settings", "pages"] as const;
const navItemsUrlMap = {
  sections: "sections",
  settings: "settings/colors",
  pages: "pages",
};

interface NavItemProps extends React.ComponentPropsWithoutRef<"a"> {
  link: string;
  isActive: boolean;
  icon: BuilderNavIcons;
}

const NavItem = forwardRef<HTMLDivElement, NavItemProps>((props: NavItemProps, ref) => (
  <Box ref={ref}>
    <IconLink {...props} />
  </Box>
));

NavItem.displayName = "NavItem";

const Navbar = ({ openPageModal }: { openPageModal: () => void }) => {
  const { t } = useTranslation("common");

  const location = useLocation();
  const routeItems = location.pathname.split("/");
  const showSettingsNavbar = location.pathname.includes("settings");
  const showPagesNavbar = location.pathname.includes("pages");

  const activeLink = useMemo(() => {
    if (routeItems.includes("sections")) {
      return "sections";
    } else if (routeItems.includes("settings")) {
      return "settings";
    } else {
      return "pages";
    }
  }, [routeItems]);

  return (
    <MantineNavbar
      px={20}
      py={30}
      width={{ sm: showSettingsNavbar || showPagesNavbar ? 401 : 83 }}
      styles={(theme) => ({
        root: {
          top: 75,
          display: "flex",
          flexDirection: "row",
          height: "initial",
          borderColor: theme.colors["low-gray"][0],
        },
      })}
    >
      <Group w={43} h="100%" sx={{ flexDirection: "column", flexWrap: "nowrap" }}>
        {navItems.map((item) => (
          <Tooltip
            key={item}
            label={t(`builder.navbar.${item}`)}
            sx={(theme) => ({
              color: theme.colors["secondary-magenta"][0],
              backgroundColor: theme.colors["white"][0],
              boxShadow: "0px 0px 20px 0px #0000001A",
            })}
            position="bottom-start"
            withArrow
          >
            <NavItem icon={item} isActive={activeLink === item} link={navItemsUrlMap[item]} />
          </Tooltip>
        ))}
      </Group>
      {showSettingsNavbar && <SettingsNavbar />}
      {showPagesNavbar && <PagesNavbar openPageModal={openPageModal} />}
    </MantineNavbar>
  );
};

export default Navbar;
