import { Divider, Group, Skeleton, Stack } from "@mantine/core";

import Section from "../../../common/Section";

const PageSkeleton = () => {
  return (
    <Stack spacing={20}>
      <Section>
        <Skeleton w="40%" h={25} mb={5} />
        <Skeleton w="80%" h={20} />
      </Section>
      <Section>
        <Skeleton w="30%" h={25} />
        <Divider my={20} color="light-gray" />
        <Skeleton w="100%" h={35} mb={20} />
        <Skeleton w="100%" h={35} mb={20} />
        <Skeleton w="100%" h={35} mb={20} />
        <Group position="apart">
          <Skeleton w="25%" h={30} />
          <Skeleton w="30%" h={30} />
        </Group>
      </Section>
      <Section>
        <Skeleton w="30%" h={25} mb={10} />
        <Skeleton w="100%" h={25} mb={20} />
        <Skeleton w="100%" h={80} />
        <Divider my={20} color="light-gray" />
        <Skeleton w="100%" h={35} mb={20} />
        <Skeleton w="100%" h={50} />
      </Section>
    </Stack>
  );
};

export default PageSkeleton;
