import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Chip, NumberInput, Select, Switch } from "react-hook-form-mantine";
import { Divider, Flex, Popover, Stack, Text } from "@mantine/core";
import { IconBolt, IconChevronLeft } from "@tabler/icons-react";
import { ITheme, IThemePage } from "shared";

import Button from "../../common/Button";
import { ComponentPrefix } from "../../../typings/Theme";

// reference to Direction types in ZammitLayoutEffect
const directionOptions = ["left", "right", "up", "down"];
const flipDirectionOptions = ["horizontal", "vertical"];
const rotateDirectionOptions = ["bottom-left", "bottom-right", "top-left", "top-right"];

type Props = {
  iconClassName?: string;
  componentPrefix:
    | `${ComponentPrefix}.${number}`
    | `${ComponentPrefix}.${number}.children.${number}`
    | `${ComponentPrefix}.${number}.children.${number}.children.${number}`;
};

const AnimationPopover = ({ iconClassName, componentPrefix }: Props) => {
  const { t } = useTranslation("sections");

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const form = useFormContext<ITheme<IThemePage>>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const effectValue = form.watch(`${componentPrefix}.animation.effect` as never) as unknown as string;

  const getDirectionOptions = () => {
    switch (effectValue) {
      case "Flip":
        return flipDirectionOptions;
      case "Rotate":
        return rotateDirectionOptions;
      default:
        return directionOptions;
    }
  };

  return (
    <Popover width={300} position="right-start" withinPortal withArrow shadow="md" zIndex={1000}>
      <Popover.Target>
        <IconBolt className={iconClassName} />
      </Popover.Target>
      <Popover.Dropdown>
        {isSettingsOpen ? (
          <Stack spacing={10}>
            <Flex gap={10} align="center">
              <IconChevronLeft cursor="pointer" onClick={() => setIsSettingsOpen(false)} />
              <Text size={14} weight={400} color="primary-black">
                {t("animation-popover.settings.title")}
              </Text>
            </Flex>

            <Divider my={0} />

            {!["Bounce", "JackInTheBox", "Roll"].includes(effectValue) && (
              <Stack spacing={5}>
                <Text size={12} weight={400} color="primary-black">
                  {t("animation-popover.settings.direction")}
                </Text>

                <Select
                  size="xs"
                  name={`${componentPrefix}.animation.direction` as never}
                  control={form.control}
                  data={getDirectionOptions()}
                />
              </Stack>
            )}

            <Stack spacing={5}>
              <Text size={12} weight={400} color="primary-black">
                {t("animation-popover.settings.duration")}
              </Text>
              <NumberInput
                size="xs"
                min={0}
                name={`${componentPrefix}.animation.duration` as never}
                control={form.control}
              />
            </Stack>

            <Stack spacing={5}>
              <Text size={12} weight={400} color="primary-black">
                {t("animation-popover.settings.delay")}
              </Text>
              <NumberInput
                size="xs"
                min={0}
                name={`${componentPrefix}.animation.delay` as never}
                control={form.control}
              />
            </Stack>

            {/* 
              Roll effect specific due to a bug 
              [https://github.com/morellodev/react-awesome-reveal/issues/68] 
            */}
            {effectValue === "Roll" && (
              <Flex justify="space-between">
                <Text size={12} weight={400} color="primary-black">
                  {t("animation-popover.settings.first-time")}
                </Text>
                <Switch
                  color="secondary-magenta"
                  name={`${componentPrefix}.animation.triggerOnce` as never}
                  control={form.control}
                />
              </Flex>
            )}
          </Stack>
        ) : (
          <Stack spacing={10}>
            <Text size={14} weight={400} color="primary-black">
              {t("animation-popover.title")}
            </Text>

            <Divider my={0} />

            <Flex wrap="wrap" gap={5} my={10}>
              <Chip.Group name={`${componentPrefix}.animation.effect` as never} control={form.control}>
                <Chip.Item color="secondary-magenta" value="Bounce">
                  Bounce
                </Chip.Item>
                <Chip.Item color="secondary-magenta" value="Fade">
                  Fade
                </Chip.Item>
                <Chip.Item color="secondary-magenta" value="Flip">
                  Flip
                </Chip.Item>
                <Chip.Item color="secondary-magenta" value="Roll">
                  Roll
                </Chip.Item>
                <Chip.Item color="secondary-magenta" value="Rotate">
                  Rotate
                </Chip.Item>
                <Chip.Item color="secondary-magenta" value="Slide">
                  Slide
                </Chip.Item>
                <Chip.Item color="secondary-magenta" value="Zoom">
                  Zoom
                </Chip.Item>
                <Chip.Item color="secondary-magenta" value="JackInTheBox">
                  JackInTheBox
                </Chip.Item>
                <Chip.Item color="secondary-magenta" value="None">
                  None
                </Chip.Item>
              </Chip.Group>
            </Flex>
            {effectValue !== "None" && (
              <Button mode="primary" h={35} onClick={() => setIsSettingsOpen(true)}>
                {t("animation-popover.customize")}
              </Button>
            )}
          </Stack>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

export default AnimationPopover;
