import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button as MantineButton, Box, Group, Modal, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

import Button from "../common/Button";
import LinkButton from "../common/LinkButton";
import NewBadge from "../animations/NewBadge.gif";

import { useCompanyUpdate } from "../../hooks/mutations/useCompanyUpdate";

const NewThemingCTA = () => {
  const { t, i18n } = useTranslation("home");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { mutate: updateCompany, isLoading } = useCompanyUpdate();

  return (
    <>
      <Group p={30} align="start" position="apart" bg="secondary-magenta">
        <Group maw="70%" align="start" spacing={20} noWrap>
          <img width={50} height={50} src={NewBadge} alt="new badge" />
          <Box>
            <Text size={20} weight={700} lh={1.2} color="white" mb={7}>
              {t("new_theming_cta.title")}
            </Text>
            <Text size={16} weight={300} lh={1.18} color="white">
              {t("new_theming_cta.description")}
            </Text>
          </Box>
        </Group>
        <Group spacing={15}>
          <LinkButton
            h={45}
            mode="primary"
            to={`https://api.zammit.shop/template?locale=${i18n.language as "en" | "ar"}`}
          >
            {t("new_theming_cta.edit_btn")}
          </LinkButton>
          <Button h={45} mode="secondary" loading={isLoading} onClick={() => setIsOpen(true)}>
            {t("new_theming_cta.migrate_btn")}
          </Button>
        </Group>
      </Group>
      <Modal
        size="lg"
        styles={{
          header: {
            backgroundColor: "rgba(255, 245, 245, 1)",
          },
          content: {
            backgroundColor: "rgba(255, 245, 245, 1)",
          },
          body: {
            paddingLeft: 55,
          },
        }}
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        title={
          <Group sx={{ color: "#fa5252" }}>
            <IconAlertCircle size={24} />
            <Text>{t("new_theming_cta.pop_up.title")}</Text>
          </Group>
        }
        centered
      >
        <Text>{t("new_theming_cta.pop_up.question")}</Text>
        <Text>{t("new_theming_cta.pop_up.desc")}</Text>
        <Text>{t("new_theming_cta.pop_up.note")}</Text>

        <Group pt={10} position="right">
          <MantineButton
            h={45}
            color="red"
            loading={isLoading}
            onClick={() => updateCompany({ company: { useNewTheming: true } })}
          >
            {t("new_theming_cta.migrate_btn")}
          </MantineButton>
        </Group>
      </Modal>
    </>
  );
};

export default NewThemingCTA;
