import { Group, GroupProps } from '@mantine/core';

import { ICompany } from '../../typings/Company';
import SocialMediaIcon from './SocialMediaIcon';

interface Props extends GroupProps {
  color?: string;
  company?: ICompany;
  isMobile?: boolean;
}

const SocialMediaIcons = ({ color, company, isMobile, ...props }: Props) => {
  return (
    <Group position="center" spacing={20} {...props}>
      {company?.companyLinks
        ?.filter((link) => link.isEnabled)
        .map(({ id, name, url }) => (
          <SocialMediaIcon key={id} platform={name} url={url} color={color} />
        ))}
    </Group>
  );
};

export default SocialMediaIcons;
