const WhatsAppIcon = ({ fill }: { fill: string }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99983 1.23999C15.0552 1.23583 19.2698 5.45874 19.2698 10.5042C19.2698 15.5537 15.0552 19.7642 9.99983 19.7617C8.67136 19.7623 7.35821 19.478 6.14899 18.9279L1.56108 19.7575C1.43929 19.7797 1.31378 19.7692 1.19736 19.7271C1.08094 19.685 0.977794 19.6127 0.89844 19.5177C0.819087 19.4227 0.766373 19.3083 0.745681 19.1862C0.724988 19.0641 0.737059 18.9388 0.78066 18.8229L2.09566 15.3287C1.20988 13.8748 0.740574 12.2054 0.738993 10.5029C0.739827 5.45708 4.95024 1.24249 9.99983 1.23999ZM14.6507 14.4392C13.6198 15.2729 12.0236 16.115 10.4977 15.1358C8.49733 13.8504 6.72649 12.1621 5.44233 10.1604C4.68858 8.98833 5.39774 7.11083 6.45774 5.96666C6.88024 5.50916 7.57691 5.58208 8.03691 6.00166L9.00483 6.88583C9.29858 7.15458 9.30983 7.62166 9.12649 7.97708C8.83691 8.53374 8.66649 9.28208 9.02441 9.84041C9.47483 10.54 10.0286 11.2296 11.3519 11.6308C11.7519 11.7537 12.2652 11.7008 12.7019 11.5246C13.0669 11.3762 13.5298 11.3779 13.7998 11.6658L14.7007 12.6242C15.1973 13.1529 15.214 13.9837 14.6502 14.44L14.6507 14.4392Z"
      fill={fill}
    />
  </svg>
);

export default WhatsAppIcon;
