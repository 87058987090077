import { Box, Select, Text } from '@mantine/core';
import { TFunction } from 'i18next';
import { useEffect } from 'react';

import { ISelectItem } from '../../typings';
import DownArrowIcon from './DownArrowIcon';
import ProductOptionItem from './ProductOptionItem';
import SizeBadges from './SizeBadges';

type Props = {
  isMobile: boolean;
  sizes: ISelectItem[];
  value?: string;
  setValue: (value: string) => void;
  t: TFunction;
};

const SizeOptions = ({ isMobile, sizes, value, setValue, t }: Props) => {
  // Select value if there is only one option
  useEffect(() => {
    const validOptions = sizes.filter((s) => !s.isDisabled);
    if (validOptions.length === 1 && !value) setValue(validOptions[0].value);
  }, [sizes, setValue, value]);

  return (
    <Box mb={20}>
      <Text mb={15} size={isMobile ? 14 : 16} weight={700} color="high-gray">
        {t('size-options')}
      </Text>
      {isMobile || sizes.length > 4 ? (
        <Select
          size="md"
          placeholder={t('select-size') as string}
          rightSection={<DownArrowIcon size="13" />}
          rightSectionWidth={53}
          dropdownPosition={isMobile ? 'top' : 'flip'}
          value={value}
          data={sizes.map((item) => ({ ...item, isMobile, t }))}
          onChange={setValue}
          itemComponent={ProductOptionItem}
        />
      ) : (
        <SizeBadges value={value} sizes={sizes} onSelect={setValue} />
      )}
    </Box>
  );
};

export default SizeOptions;
