import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateCompany } from "../../api/Company";
import { ICompany } from "shared";

export const useCompanyUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(({ company }: { company: Partial<ICompany> }) => updateCompany(company), {
    onSuccess(data) {
      queryClient.setQueryData(["company"], data);
    },
  });
};
