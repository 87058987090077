import { Carousel, CarouselProps } from '@mantine/carousel';
import { Box, Stack } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import { useRef } from 'react';

import { ICompany, IPage, ISpacing, ITheme, IThemeComponent, ValidLocales } from '../../../typings';
import { formatSpacing } from '../../../utils';
import { ZammitComponent } from '../../ZammitComponent';

export interface CardsCarouselProps extends CarouselProps {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  company: ICompany;
  locale?: ValidLocales;
  isMobile?: boolean;
  caption?: string;
  bgColor?: string;
  columns: number;
  mobileColumns?: number;
  withAutoRotating?: boolean;
  autoRotatingDelay?: number;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  enableImageHoverEffect?: boolean;
  componentChildren: IThemeComponent[];
}

export const CardsCarousel = ({
  withinBuilder,
  theme,
  company,
  locale,
  isMobile,
  caption,
  bgColor,
  columns,
  mobileColumns = 1,
  withControls = true,
  withAutoRotating = false,
  autoRotatingDelay = 2000,
  spacing,
  mobileSpacing,
  enableImageHoverEffect,
  componentChildren,
  ...props
}: CardsCarouselProps) => {
  const autoplay = useRef(Autoplay({ delay: autoRotatingDelay }));

  return (
    <Stack spacing={20} bg={bgColor} sx={{ ...formatSpacing(isMobile ? mobileSpacing : spacing) }}>
      {caption ? <Box w="100%" dangerouslySetInnerHTML={{ __html: caption }} /> : null}
      <Carousel
        maw="100%"
        bg={bgColor}
        slideSize={isMobile ? `${100 / mobileColumns}%` : `${100 / columns}%`}
        slideGap="lg"
        align="start"
        dragFree
        withControls={withControls}
        withKeyboardEvents
        plugins={withAutoRotating ? [autoplay.current] : undefined}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        {...props}
      >
        {componentChildren.map((child) => (
          <Carousel.Slide key={child.id}>
            <ZammitComponent
              withinBuilder={withinBuilder}
              theme={theme}
              locale={locale}
              component={child}
              company={company}
              isMobile={isMobile}
              extraProps={{ columns, mobileColumns, enableImageHoverEffect }}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Stack>
  );
};
