// Courtesy of https://ui.mantine.dev/category/error-pages

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { createStyles, Image, Container, Title, Text, Button, SimpleGrid, rem } from "@mantine/core";

import image from "./image.svg";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(80),
    paddingBottom: rem(80),
  },

  title: {
    fontWeight: 900,
    fontSize: rem(34),
    marginBottom: theme.spacing.md,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },

  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

export default function NotFound() {
  const { t } = useTranslation("notFound");
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}>
        <Image src={image} className={classes.mobileImage} />
        <div>
          <Title className={classes.title}>{t("title")}</Title>
          <Text color="dimmed" size="lg">
            {t("description")}
          </Text>

          <Button component={Link} to="/" size="md" mt="xl" className={classes.control} color="secondary-magenta">
            {t("home")}
          </Button>
        </div>
        <Image src={image} className={classes.desktopImage} />
      </SimpleGrid>
    </Container>
  );
}
