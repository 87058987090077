import API from "./API";
import { apiPrefix } from "./constants";

import { IStoreTemplate, ITemplateFilterCategory } from "../typings/StoreTemplate";
import { IMetadata } from "shared";

type StoreTemplatesData = {
  storeTemplates: IStoreTemplate[];
  metadata: IMetadata;
};

type StoreTemplatesResponse = {
  success: boolean;
  data: StoreTemplatesData;
};

type GetStoreTemplatesParams = {
  search: string | null;
  offers: string[];
  industry_tags: string[];
  catalog_size: string[];
  features_tags: string[];
  limit: number;
};

type TemplateFiltersData = {
  filterCategories: ITemplateFilterCategory[];
};

type TemplateFiltersResponse = {
  success: boolean;
  data: TemplateFiltersData;
};

export const getStoreTemplates = async (params?: GetStoreTemplatesParams): Promise<StoreTemplatesData> => {
  const response = await API.get<StoreTemplatesResponse>(`${apiPrefix}/store_templates`, {
    params,
  });
  return response.data.data;
};

export const getTemplateFilters = async (): Promise<TemplateFiltersData> => {
  const response = await API.get<TemplateFiltersResponse>(`${apiPrefix}/store_templates/filters`);
  return response.data.data;
};
