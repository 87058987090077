import { useMutation } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { ComponentDisplay } from "../../typings/Theme";
import { addThemeSection } from "../../api/Theme";
import { ITheme, IThemePage } from "shared";

type ThemeComponentsKey = "topComponents" | "bottomComponents" | "overlayComponents" | `pages.${number}.pageComponents`;

type Params = {
  themeId: number;
  page?: { id: number; index: number };
  sectionTemplateId: number;
  display: ComponentDisplay;
};

export const useAddThemeSection = ({ onSuccess }: { onSuccess: () => void }) => {
  const form = useFormContext<ITheme<IThemePage>>();
  return useMutation({
    mutationFn: ({ page, ...params }: Params) => addThemeSection({ pageId: page?.id, ...params }),
    onSuccess(newComponent, { page, display }) {
      const themeComponentsKey = (
        page ? `pages.${page.index}.pageComponents` : `${display}Components`
      ) as ThemeComponentsKey;
      const themeComponents = form.getValues(themeComponentsKey);

      themeComponents!.push(newComponent);
      form.setValue(themeComponentsKey, themeComponents);
      onSuccess();
    },
  });
};
