import { useMantineTheme } from "@mantine/core";

const ReplaceIcon = ({ stroke }: { stroke: "secondary-magenta" }) => {
  const theme = useMantineTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8574 15.4287L22.286 14.5715L23.1431 18.0001"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2863 14.5715C21.5249 16.7292 20.1418 18.6131 18.3114 19.9859C16.481 21.3587 14.2851 22.159 12.0006 22.2858C9.8888 22.2862 7.82806 21.6365 6.09829 20.4251C4.36852 19.2136 3.05356 17.4991 2.33203 15.5144"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.14314 8.57143L1.71456 9.42857L0.857422 6"
        stroke={theme.colors[stroke][0]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.71484 9.42864C3.15484 5.48578 7.5777 1.71436 12.0006 1.71436C14.1227 1.72032 16.191 2.38251 17.9219 3.61014C19.6529 4.83778 20.9618 6.57076 21.6691 8.5715"
        stroke="#4F3FA8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReplaceIcon;
