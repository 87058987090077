import { useTranslation } from "react-i18next";
import { Box, Divider, Group, Skeleton } from "@mantine/core";

import Tab from "../../../builder/settings/Tab";

const LogosTabSkeleton = () => {
  const { t } = useTranslation("settings");

  return (
    <Tab tab="logos" title={t("logos.title")} desc={t("logos.description")}>
      <Group spacing={20}>
        <Skeleton w={100} h={100} />
        <Box>
          <Skeleton w={100} h={17} mb={10} />
          <Skeleton w={200} h={15} />
        </Box>
      </Group>
      <Divider my={20} color="light-gray" />
      <Group spacing={20}>
        <Skeleton w={100} h={100} />
        <Box>
          <Skeleton w={100} h={17} mb={10} />
          <Skeleton w={200} h={15} />
        </Box>
      </Group>
    </Tab>
  );
};

export default LogosTabSkeleton;
