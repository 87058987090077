import { Group, Text } from "@mantine/core";

import InputLocaleSwitcher from "../components/InputLocaleSwitcher";

const InputLabel = ({ label, withLocale }: { label: string; withLocale?: boolean }) => {
  return (
    <Group position="apart" mb={10}>
      <Text size={14} weight={400} color="dark-gray">
        {label}
      </Text>
      {withLocale && <InputLocaleSwitcher />}
    </Group>
  );
};

export default InputLabel;
