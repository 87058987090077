import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { UnstyledButton } from "@mantine/core";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onChange = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    const pathname = location.pathname.replace(`/${i18n.language}`, `/${newLanguage}`);

    i18n.changeLanguage(newLanguage);
    navigate(pathname);
  };

  return (
    <UnstyledButton
      sx={(theme) => ({ fontSize: 16, fontWeight: 400, color: theme.colors["high-gray"][0] })}
      onClick={onChange}
    >
      {i18n.language === "en" ? "عربي" : "English"}
    </UnstyledButton>
  );
};

export default LanguageSwitcher;
