import { Flex } from '@mantine/core';

import { ISpacing } from '../../typings';
import { formatSpacing } from '../../utils';

export interface YoutubeProps {
  url: string;
  showControls: boolean;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  isMobile: boolean;
}

export function Youtube({ url, showControls, isMobile, mobileSpacing, spacing }: YoutubeProps) {
  const videoId = url?.split('?v=')?.pop();
  return (
    <Flex align="center" sx={{ ...formatSpacing(isMobile ? mobileSpacing : spacing) }}>
      <iframe
        // rel=0 limits related videos to the same channel
        src={`https://www.youtube.com/embed/${videoId}?;controls=${Number(showControls)}&rel=0`}
        width="100%"
        style={{ aspectRatio: '16/10', border: 'none' }}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Flex>
  );
}
