import { Box, BoxProps } from '@mantine/core';
import Marquee from 'react-fast-marquee';

import { ISpacing } from '../../typings';
import { formatSpacing } from '../../utils';
import { FreeText } from './FreeText';

export interface AnnouncementBarProps extends BoxProps {
  bgColor?: string;
  text: string;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  enableAnimation?: boolean;
  pauseOnHover?: boolean;
  autoFill?: boolean;
  speed?: number;
  isMobile?: boolean;
  withinBuilder?: boolean;
  componentChildren?: React.ReactNode;
}

export const AnnouncementBar = ({
  bgColor,
  text,
  spacing,
  mobileSpacing,
  enableAnimation = true,
  pauseOnHover,
  autoFill,
  speed = 50,
  isMobile,
  withinBuilder,
  componentChildren,
  ...props
}: AnnouncementBarProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: bgColor,
        ...formatSpacing(isMobile ? mobileSpacing : spacing),
      }}
      {...props}
    >
      {enableAnimation ? (
        <Marquee speed={speed} autoFill={autoFill} pauseOnHover={pauseOnHover}>
          <FreeText text={text} textAlign="center" />
        </Marquee>
      ) : (
        <FreeText text={text} textAlign="center" />
      )}
    </Box>
  );
};
