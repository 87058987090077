import { ContextStylesParams, MantineTheme, MantineThemeOverride } from '@mantine/core';

import { IPage, IShopPage, ITheme } from '../typings';
import { getDesignDirection } from '.';

const createThemeColor = (color: string) => {
  const colorValues = new Array<string>(10);
  return colorValues.fill(color) as keyof MantineThemeOverride['colors'];
};

// TODO: update text & buttons colors keys & values, plus other components after integrating all shops

export const createShopTheme = (
  shopTheme: ITheme<IPage | IShopPage>,
  locale?: string,
): MantineThemeOverride => {
  return {
    dir: getDesignDirection(locale),

    // dynamic theming
    colors: {
      black: createThemeColor('#121118'),
      'secondary-white': createThemeColor('#F4F4F4'),
      'light-gray': createThemeColor('#EDEFF3'),
      'dark-gray': createThemeColor('#404C60'),
      'high-gray': createThemeColor('#5D5D69'),
      'med-gray': createThemeColor('#A8A8A8'),
      'low-gray': createThemeColor('#CDCCD7'),
      'dark-red': createThemeColor('#C13749'),
      'primary-green': createThemeColor('#45B467'),
      'btn-primary': createThemeColor(shopTheme.primaryBtnBgColor),
      'btn-primary-label': createThemeColor(shopTheme.primaryBtnLabelColor),
      'btn-primary-border': createThemeColor(shopTheme.primaryBtnBorderColor),
      'btn-secondary': createThemeColor(shopTheme.secondaryBtnBgColor),
      'btn-secondary-label': createThemeColor(shopTheme.secondaryBtnLabelColor),
      'btn-secondary-border': createThemeColor(shopTheme.secondaryBtnBorderColor),
      'prd-title': createThemeColor(shopTheme.headingsAndLinksColor),
      'nav-bg': createThemeColor(shopTheme.primaryBtnBgColor),
      'nav-txt': createThemeColor(shopTheme.primaryBtnLabelColor),
      'footer-bg': createThemeColor(shopTheme.primaryBtnBgColor),
      'footer-txt': createThemeColor(shopTheme.primaryBtnLabelColor),
      'social-navbar': createThemeColor(shopTheme.primaryBtnLabelColor),
      'social-footer': createThemeColor(shopTheme.primaryBtnLabelColor),
      'borders-and-lines': createThemeColor(shopTheme.pageBordersAndLinesColor),
      'headings-and-links': createThemeColor(shopTheme.headingsAndLinksColor),
    },
    defaultRadius: shopTheme.radius,
    fontFamily: `"${
      (locale === 'ar' ? shopTheme.arabicFont : shopTheme.englishFont) || 'Inter'
    }" !important`,

    headings: {
      fontFamily: `"${
        (locale === 'ar' ? shopTheme.arabicFont : shopTheme.englishFont) || 'Inter'
      }" !important`,
      sizes: {
        h1: {
          fontSize: `${locale === 'ar' ? shopTheme.arabicSizes?.h1 : shopTheme.englishSizes?.h1}px`,
        },
        h2: {
          fontSize: `${locale === 'ar' ? shopTheme.arabicSizes?.h2 : shopTheme.englishSizes?.h2}px`,
        },
        h3: {
          fontSize: `${locale === 'ar' ? shopTheme.arabicSizes?.h3 : shopTheme.englishSizes?.h3}px`,
        },
        h4: {
          fontSize: `${locale === 'ar' ? shopTheme.arabicSizes?.h4 : shopTheme.englishSizes?.h4}px`,
        },
      },
    },

    // components theming
    components: {
      Skeleton: {
        styles: (theme: MantineTheme) => ({
          root: {
            backgroundColor: theme.colors['light-gray'][0],
          },
        }),
      },
      Button: {
        defaultProps: {
          loaderPosition: 'right',
        },
        styles: (theme: MantineTheme, _, context: ContextStylesParams) => ({
          root: {
            fontSize: locale === 'ar' ? shopTheme.arabicSizes?.btn : shopTheme.englishSizes?.btn,
            fontWeight: 400,
            textTransform: 'capitalize',

            color:
              context.variant === 'filled'
                ? shopTheme.primaryBtnLabelColor
                : shopTheme.secondaryBtnLabelColor,
            backgroundColor:
              context.variant === 'filled'
                ? shopTheme.primaryBtnBgColor
                : shopTheme.secondaryBtnBgColor,
            borderColor:
              context.variant === 'filled'
                ? shopTheme.primaryBtnBorderColor
                : shopTheme.secondaryBtnBorderColor,

            '&:hover': {
              textDecoration: 'none',
              '&[href]': {
                color:
                  context.variant === 'filled'
                    ? shopTheme.primaryBtnLabelColor
                    : shopTheme.secondaryBtnLabelColor,
              },
              backgroundColor:
                context.variant === 'filled'
                  ? shopTheme.primaryBtnBgColor
                  : shopTheme.secondaryBtnBgColor,
            },

            '&[data-disabled]': {
              color: theme.white,
              backgroundColor: theme.colors['low-gray'][0],
            },
          },
        }),
      },
      TextInput: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
          },
          input: {
            padding: '10px 20px',
            borderColor: theme.colors['med-gray'][0],

            '&:focus': {
              borderColor: theme.colors['med-gray'][0],
            },

            '&::placeholder': {
              fontSize: '16px',
              fontWeight: 400,
              color: theme.colors['med-gray'][0],
              opacity: 1,
            },
          },
        }),
      },
      PasswordInput: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
          },
          input: {
            borderColor: theme.colors['med-gray'][0],

            '&:focus': {
              borderColor: theme.colors['med-gray'][0],
            },
            '&:focus-within': {
              borderColor: theme.colors['med-gray'][0],
            },
          },
          innerInput: {
            padding: '10px 20px',
            '&::placeholder': {
              fontSize: '16px',
              fontWeight: 400,
              color: theme.colors['med-gray'][0],
              opacity: 1,
            },
          },
          rightSection: {
            paddingRight: '20px',
          },
        }),
      },
      Select: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
          },
          rightSection: { pointerEvents: 'none' },
          item: {
            '&[data-selected]': {
              color: theme.colors['btn-secondary-label'][0],
              backgroundColor: theme.colors['btn-secondary'][0],
            },
          },
          input: {
            padding: '10px 20px',
            borderColor: theme.colors['med-gray'][0],

            '&:focus': {
              borderColor: theme.colors['med-gray'][0],
            },

            '&::placeholder': {
              fontSize: '16px',
              fontWeight: 400,
              color: theme.colors.black[0],
              opacity: 1,
            },
          },
        }),
      },
      MultiSelect: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
          },
          rightSection: { pointerEvents: 'none' },
          input: {
            borderColor: `${theme.colors['med-gray'][0]} !important`,
          },
        }),
      },
      DatePicker: {
        styles: (theme: MantineTheme) => ({
          label: {
            width: '100%',
          },
          rightSection: { pointerEvents: 'none' },
          day: {
            '&[data-selected]': {
              color: theme.colors['btn-secondary'][0],
              backgroundColor: theme.colors['btn-primary'][0],
            },
          },
          input: {
            padding: '10px 20px',
            borderColor: theme.colors['med-gray'][0],

            '&:focus': {
              borderColor: theme.colors['med-gray'][0],
            },

            '&::placeholder': {
              fontSize: 16,
              fontWeight: 400,
              color: theme.colors.black[0],
              opacity: 1,
            },
          },
        }),
      },
      Divider: {
        styles: (theme: MantineTheme) => ({
          root: {
            borderTopColor: `${
              theme.colors['borders-and-lines'][0] || theme.colors['light-gray'][0]
            } !important`,
          },
        }),
      },
      Text: {
        styles: () => ({
          root: {
            textAlign: 'start',
            fontSize: locale === 'ar' ? shopTheme.arabicSizes?.body : shopTheme.englishSizes?.body,
          },
        }),
      },
      Checkbox: {
        defaultProps: {
          color: ['#fff', '#ffffff'].includes(shopTheme.primaryBtnBgColor?.toLocaleLowerCase())
            ? 'btn-secondary'
            : 'btn-primary',
        },
      },
      Card: {
        styles: () => ({
          root: {
            borderRadius: 0,
          },
        }),
      },
      Pagination: {
        styles: (theme) => ({
          control: {
            color: theme.colors['btn-primary-label'][0],
            backgroundColor: theme.colors['btn-primary'][0],

            '&:not([data-disabled]):hover': {
              color: theme.colors['btn-primary'][0],
              backgroundColor: theme.colors['btn-primary-label'][0],
            },

            '&[data-active]': {
              color: theme.colors['btn-primary'][0],
              backgroundColor: theme.colors['btn-primary-label'][0],
            },

            '&[data-active]:not([data-disabled]):hover': {
              color: theme.colors['btn-primary'][0],
              backgroundColor: theme.colors['btn-primary-label'][0],
            },
          },
        }),
      },
    },
  };
};
