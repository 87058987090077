import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createDefaultTheme } from "../../api/Theme";

export const useCreateDefaultTheme = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createDefaultTheme,
    onSuccess() {
      queryClient.invalidateQueries(["themes"]);
    },
  });
};
