import '../../../styles/CardsCollage.css';

import { MasonryGrid } from '@egjs/react-grid';
import { Box, Stack } from '@mantine/core';

import {
  ICompany,
  IPage,
  IPropValue,
  ISpacing,
  ITheme,
  IThemeComponent,
  ValidLocales,
} from '../../../typings';
import { formatSpacing } from '../../../utils';
import { ZammitComponent } from '../../ZammitComponent';

type Props = {
  theme: ITheme<IPage>;
  company: ICompany;
  locale?: ValidLocales;
  isMobile?: boolean;
  column?: number;
  mobileColumns?: number;
  gap?: number;
  spacing: ISpacing;
  mobileSpacing: ISpacing;
  direction?: 'start' | 'end';
  bgColor?: string;
  caption?: string;
  enableImageHoverEffect?: boolean;
  componentChildren: IThemeComponent[];
};

export const MasonryCollage = ({
  theme,
  company,
  locale,
  isMobile,
  gap = 1,
  column = 3,
  mobileColumns = 2,
  spacing,
  mobileSpacing,
  direction = 'end',
  bgColor = '#fff',
  caption,
  enableImageHoverEffect,
  componentChildren,
}: Props) => {
  const heights = componentChildren
    .map(
      (child) =>
        child.propValues.find((propValue: IPropValue) => propValue.key === 'height')?.value,
    )
    ?.toString();

  const width = `calc((100% - ${gap * (isMobile ? mobileColumns : column) - 1}px) / ${
    isMobile ? mobileColumns : column
  })`;

  const spacings = `${JSON.stringify(formatSpacing(spacing))} - ${JSON.stringify(
    formatSpacing(mobileSpacing),
  )}`;

  return (
    <Stack
      spacing={20}
      style={{ backgroundColor: bgColor, ...formatSpacing(isMobile ? mobileSpacing : spacing) }}
    >
      {caption ? <Box w="100%" dangerouslySetInnerHTML={{ __html: caption }} /> : null}

      <MasonryGrid
        key={`${isMobile}-${mobileColumns}-${column}-${spacings}-${heights}-${width}-${gap}-${direction}`}
        className="container"
        column={isMobile ? mobileColumns : column}
        gap={gap}
        defaultDirection={direction}
        align="justify"
        columnSize={0}
        columnSizeRatio={0}
      >
        {componentChildren.map((child) => (
          <Box key={`${child.id}-${width}-${gap}`} className="item" style={{ width }}>
            <ZammitComponent
              theme={theme}
              locale={locale}
              component={child}
              company={company}
              isMobile={isMobile}
              extraProps={{ enableImageHoverEffect }}
            />
          </Box>
        ))}
      </MasonryGrid>
    </Stack>
  );
};
