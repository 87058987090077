import { Accordion, AccordionControlProps, Group } from '@mantine/core';

import { INavigationItem } from '../../../typings';
import { hasSubNavigation } from '../../../utils';
import NavItem from '../../common/NavItem';

const AccordionControl = ({
  item,
  txtColor,
  onNavigate,
  ...props
}: AccordionControlProps & {
  item: INavigationItem;
  txtColor?: string;
  onNavigate: () => void;
}) => {
  return (
    <Group style={{ flexWrap: 'nowrap' }}>
      <NavItem
        px={20}
        py={16}
        size={15}
        weight={400}
        color={txtColor}
        item={item}
        onClick={onNavigate}
      />
      <Accordion.Control {...props} w="fit-content" />
    </Group>
  );
};

const AccordionItem = ({
  txtColor,
  navItem,
  onNavigate,
}: {
  txtColor?: string;
  navItem: INavigationItem;
  onNavigate: () => void;
}) => {
  return (
    <Accordion.Item value={`navigation-${navItem.id}`}>
      <AccordionControl item={navItem} txtColor={txtColor} onNavigate={onNavigate} />
      <Accordion.Panel>
        <MobileNavigationList
          txtColor={txtColor}
          navItems={navItem.items}
          onNavigate={onNavigate}
        />
      </Accordion.Panel>
    </Accordion.Item>
  );
};

const MobileNavigationList = ({
  txtColor,
  navItems,
  onNavigate,
}: {
  txtColor?: string;
  navItems: INavigationItem[];
  onNavigate: () => void;
}) => {
  return (
    <Accordion
      multiple
      styles={{
        item: {
          borderColor: txtColor,
        },
        control: {
          '&:active': {
            backgroundColor: 'transparent',
          },
        },
        chevron: {
          color: txtColor,
        },
      }}
    >
      {navItems.map((item) =>
        hasSubNavigation(item) ? (
          <AccordionItem key={item.id} txtColor={txtColor} navItem={item} onNavigate={onNavigate} />
        ) : (
          <NavItem
            key={item.id}
            px={20}
            py={16}
            size={15}
            weight={400}
            color={txtColor}
            item={item}
            onClick={onNavigate}
          />
        ),
      )}
    </Accordion>
  );
};

export default MobileNavigationList;
