import { Box, Grid, Group } from '@mantine/core';

import {
  ICompany,
  IPage,
  IProduct,
  ISpacing,
  ITheme,
  IThemeComponent,
  ValidLocales,
} from '../../../typings';
import { formatSpacing } from '../../../utils';
import ProductCard from '../FeaturedCollection/ProductCard';

export interface FeaturedProductsGridProps {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  caption?: string;
  columns: number;
  mobileColumns: number;
  bgColor?: string;
  txtColor?: string;
  imageScale: string;
  textAlign?: 'start' | 'center' | 'end';
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  isMobile?: boolean;
  children?: React.ReactNode;
  company: ICompany;
  data?: IProduct[];
  locale?: ValidLocales;
  order: number;
  enableImageHoverEffect?: boolean;
  showSecondaryImageOnHover?: boolean;
  componentChildren: IThemeComponent[];
}

export const FeaturedProductsGrid = ({
  withinBuilder,
  theme,
  caption,
  data,
  company,
  locale,
  isMobile,
  bgColor,
  imageScale,
  txtColor,
  columns,
  mobileColumns,
  spacing,
  mobileSpacing,
  children,
  textAlign,
  order,
  enableImageHoverEffect,
  showSecondaryImageOnHover,
  componentChildren,
}: FeaturedProductsGridProps) => {
  return (
    <Group
      spacing={20}
      sx={{
        flexDirection: 'column',
        backgroundColor: bgColor,
        ...formatSpacing(isMobile ? mobileSpacing : spacing),
      }}
    >
      {caption ? <Box w="100%" dangerouslySetInnerHTML={{ __html: caption }} /> : null}
      <Grid gutter="sm" columns={isMobile ? mobileColumns : columns}>
        {data?.map((product) => (
          <Grid.Col key={product.id} span={1}>
            <ProductCard
              withinBuilder={withinBuilder}
              theme={theme}
              product={product}
              imageScale={imageScale}
              isMobile={isMobile}
              currency={company?.paymentCurrency}
              locale={locale}
              txtColor={txtColor}
              textAlign={textAlign}
              priority={order < 4}
              enableImageHoverEffect={enableImageHoverEffect}
              showSecondaryImageOnHover={showSecondaryImageOnHover}
            />
          </Grid.Col>
        ))}
      </Grid>
      {children}
    </Group>
  );
};
