/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from "react-i18next";
import { CircleFlag } from "react-circle-flags";
import { Control, FieldPath, UseFormSetValue, useWatch } from "react-hook-form";
import { TextInput } from "react-hook-form-mantine";
import { MultiSelect, Stack } from "@mantine/core";

import ArrowButton from "../../../common/ArrowButton";

import Currencies from "../../../../constants/Currencies.json";
import { ITheme, IThemePage, Currency } from "shared";

const getCountryCode = (currency: string) => {
  return Currencies.find((currencyObj) => currencyObj.currency === currency)?.iso || "EG";
};

type Props = {
  name: FieldPath<ITheme<IThemePage>>;
  label: React.ReactNode;
  control: Control<ITheme<IThemePage>>;
  setValue: UseFormSetValue<ITheme<IThemePage>>;
};

const CurrencyEditor = ({ name, label, control, setValue }: Props) => {
  const { t } = useTranslation("sections");
  const values = useWatch({ control, name }) as Currency[];

  const onSelect = (selectedCurrencies: string[] | undefined) => {
    const currentCurrencies = values ? values.map((value) => value.currency) : [];

    const currenciesList = selectedCurrencies?.map((currency) => {
      if (currentCurrencies.includes(currency)) {
        return values.find((value) => value.currency === currency)!;
      } else {
        return { countryCode: getCountryCode(currency), currency, link: "" };
      }
    });

    setValue(name, currenciesList);
  };

  return (
    <Stack>
      <MultiSelect
        searchable
        aria-label="Currencies select"
        label={label}
        rightSection={<ArrowButton dir="down" />}
        data={Currencies.map((currencyObj) => ({ label: currencyObj.currency, value: currencyObj.currency }))}
        value={values?.map((value) => value.currency)}
        onChange={onSelect}
      />

      {values?.map(({ countryCode, currency }, index) => (
        <TextInput
          key={countryCode}
          aria-label={`${currency} link`}
          placeholder={t("config-sidebar.currency-input-placeholder", { currency })}
          icon={<CircleFlag countryCode={countryCode.toLowerCase()} height={20} width={20} />}
          name={`${name as `topComponents.${number}.propValues.${number}.value`}.${index}.link`}
          control={control}
        />
      ))}
    </Stack>
  );
};

export default CurrencyEditor;
