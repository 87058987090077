import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, Text } from "@mantine/core";

import SearchBar from "./SearchBar";
import ThemeFilter from "./ThemeFilter";
import StoreTemplateCardCollection from "./StoreTemplateCardCollection";
import ThemeStoreSkeleton from "../../loadingSkeleton/home/ThemesStoreSkeleton";

import { useTemplateFilters } from "../../../hooks/queries/useTemplateFilters";
import { useStoreTemplates } from "../../../hooks/queries/useStoreTemplates";

const ThemesStore = () => {
  const { t } = useTranslation("home");
  const [filters, setFilters] = useSearchParams("");

  const payload = {
    search: filters.get("search"),
    offers: filters.getAll("offers"),
    industry_tags: filters.getAll("industry"),
    catalog_size: filters.getAll("catalog_size"),
    features_tags: filters.getAll("features"),
    limit: 12,
    page: filters.get("page") || "1",
  };

  const { data, isLoading } = useStoreTemplates(payload);
  const { data: filterCategories } = useTemplateFilters();

  return (
    <Box p={30}>
      <Grid sx={{ display: "flex", justifyContent: "space-between", gap: 10, paddingBottom: 40 }}>
        <Grid.Col span="auto">
          <Box>
            <Text size={30} weight={400} lh="36px" color="primary-black" pb={5}>
              {t("themes_store.title")}
            </Text>
            <Text size={16} weight={300} lh="19px" color="primary-black">
              {t("themes_store.description")}
            </Text>
          </Box>
        </Grid.Col>
        <Grid.Col span="auto">
          <SearchBar filters={filters} setFilters={setFilters} />
        </Grid.Col>
      </Grid>
      <Box sx={{ display: "flex", gap: 30 }}>
        <ThemeFilter filterCategories={filterCategories?.filterCategories} filters={filters} setFilters={setFilters} />
        {isLoading ? (
          <ThemeStoreSkeleton />
        ) : (
          data && (
            <StoreTemplateCardCollection
              storeTemplates={data.storeTemplates}
              metadata={data.metadata}
              filters={filters}
              setFilters={setFilters}
            />
          )
        )}
      </Box>
    </Box>
  );
};

export default ThemesStore;
