// To remove RTL Mark (char code = 8207) from the arabic price to avoid hydration errors

// https://www.codetable.net/decimal/8207
const stripRightToLeftMark = (str: string) => {
  return str.replace(/[\u200E\u200F\u202A-\u202E]/g, '');
};

// convert arabic numbers to english numbers
const convertArabicNumbers = (str: string) => {
  return str.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, (c) => String(c.charCodeAt(0) & 0xf));
};

const numberFormatter = (str: string) => {
  return convertArabicNumbers(stripRightToLeftMark(str));
};

export const usePrice = ({
  locale = 'en',
  currency = 'EGP',
  priceDecimalPoints = 0,
}: {
  locale?: string;
  currency?: string;
  priceDecimalPoints?: number;
}) => {
  // To remove subtle differences between browsers and node
  // disable price locale for meta
  // const priceLocale = locale === 'ar' ? 'ar-EG' : 'en-US';
  const priceLocale = 'en-US';

  // A function to format a price with the correct currency
  const formatPrice = (value: number) => {
    const price = Intl.NumberFormat(priceLocale, {
      style: 'currency',
      currency,
      minimumFractionDigits: priceDecimalPoints,
      maximumFractionDigits: priceDecimalPoints,
    }).format(value);

    return numberFormatter(price);
  };

  const formatPriceWithoutCurrency = (value: number) => {
    const price = Intl.NumberFormat(priceLocale).format(value);

    return numberFormatter(price);
  };
  // Returns the currency symbol for the current locale
  const currencySymbol = numberFormatter(
    (0).toLocaleString(priceLocale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  )
    .replace(/\d/g, '') // English "zero"
    .trim();

  return {
    formatPrice,
    formatPriceWithoutCurrency,
    currencySymbol,
  };
};
