import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { MantineProvider, AppShell } from "@mantine/core";

import { ltrCache, rtlCache } from "../../emotion-cache";
import createBuilderTheme from "../../utils/mantineTheme";

const Layout = () => {
  const { i18n } = useTranslation();

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={createBuilderTheme(i18n.dir(i18n.language))}
      emotionCache={i18n.dir(i18n.language) === "rtl" ? rtlCache : ltrCache}
    >
      <AppShell
        sx={{ backgroundColor: "#f2f4f5" }}
        styles={{
          main: {
            paddingTop: 75,
            paddingBottom: 0,
          },
        }}
        padding={0}
      >
        <Outlet />
      </AppShell>
    </MantineProvider>
  );
};

export default Layout;
