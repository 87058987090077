import { useTranslation } from "react-i18next";
import { NavigateOptions, URLSearchParamsInit } from "react-router-dom";
import { Box, Grid, Text, Flex } from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";

import StoreTemplateCard from "./StoreTemplateCard";
import Paginator from "./Paginator";

import { IMetadata } from "shared";
import { IStoreTemplate } from "../../../typings/StoreTemplate";

type Props = {
  storeTemplates: IStoreTemplate[];
  metadata: IMetadata;
  filters: URLSearchParams;
  setFilters: (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit) | undefined,
    navigateOpts?: NavigateOptions | undefined,
  ) => void;
};

const StoreTemplateCardCollection = ({ storeTemplates, metadata, filters, setFilters }: Props) => {
  const { t } = useTranslation("home");
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 200,
  });

  return (
    <Box w="100%" ref={targetRef}>
      <Grid gutter={30} justify="flex-start">
        {storeTemplates.map((template, index) => {
          return (
            <Grid.Col span="content" key={index}>
              <StoreTemplateCard storeTemplate={template} />
            </Grid.Col>
          );
        })}
      </Grid>
      <Flex justify="space-between">
        <Text color="med-gray" style={{ alignSelf: "center" }}>
          {t("themes_store.results_count", { count: storeTemplates.length, totalCount: metadata.totalCount })}
        </Text>
        <Paginator
          totalPages={metadata?.totalPages || 0}
          activePage={parseInt(filters.get("page") || "1")}
          setActivePage={(page) => {
            filters.set("page", page.toString());
            setFilters(filters);
            scrollIntoView();
          }}
        />
      </Flex>
    </Box>
  );
};

export default StoreTemplateCardCollection;
