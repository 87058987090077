import {
  ICompany,
  IPage,
  ITheme,
  IThemeComponent,
  ValidLocales,
  ZammitOverlayKey,
} from '../typings';
import { constructProps } from '../utils';
import { ConfirmationPopup } from './custom/overlays/ConfirmationPopup';
import { DismissiblePopup } from './custom/overlays/DismissiblePopup';
import { ImageWithOverlayTextPopup } from './custom/overlays/ImageWithOverlayTextPopup';

export type ZammitOverlayProps = {
  opened: boolean;
  theme: ITheme<IPage>;
  company?: ICompany;
  component: IThemeComponent;
  locale?: ValidLocales;
  isMobile?: boolean;
  withinBuilder?: boolean;
  extraProps?: Record<string, any>;
  onClose: () => void;
};

/* eslint-disable camelcase */
const ComponentMap: Record<ZammitOverlayKey, React.FC<any>> = {
  confirmation_popup: ConfirmationPopup,
  dismissible_popup: DismissiblePopup,
  image_with_overlay_text_popup: ImageWithOverlayTextPopup,
} as const;

export const ZammitOverlay = ({
  opened,
  theme,
  company,
  component,
  locale,
  isMobile,
  withinBuilder,
  extraProps,
  onClose,
}: ZammitOverlayProps) => {
  const Component = ComponentMap[component.key as ZammitOverlayKey];
  const componentProps = constructProps(component);

  return (
    <Component
      opened={opened}
      theme={theme}
      company={company}
      locale={locale}
      isMobile={isMobile}
      withinBuilder={withinBuilder}
      onClose={onClose}
      {...componentProps}
      {...extraProps}
    />
  );
};
