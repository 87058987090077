import {
  ICompany,
  IPage,
  ITheme,
  IThemeComponent,
  ValidLocales,
  ZammitComponentKey,
} from '../typings';
import { constructProps } from '../utils';
import { Button } from './common/Button';
import { AnnouncementBar } from './custom/AnnouncementBar';
import { CollageCard } from './custom/CardsCollage/CollageCard';
import { FrameCollage } from './custom/CardsCollage/FrameCollage';
import { MasonryCollage } from './custom/CardsCollage/MasonryCollage';
import { Card } from './custom/CardsList/Card';
import { CardsCarousel } from './custom/CardsList/CardsCarousel';
import { CardsGrid } from './custom/CardsList/CardsGrid';
import { FeaturedCollection } from './custom/FeaturedCollection';
import { FeaturedProduct } from './custom/FeaturedProduct';
import { FeaturedProductsCarousel } from './custom/FeaturedProductsCarousel';
import { FeaturedProductsGrid } from './custom/FeaturedProductsGrid';
import { Footer } from './custom/Footer';
import { FreeText } from './custom/FreeText';
import { Header } from './custom/Header';
import { DividedBanner } from './custom/HeroBanner/DividedBanner';
import { HeroBanner } from './custom/HeroBanner/HeroBanner';
import { HeroBannerCarousel } from './custom/HeroBanner/HeroBannerCarousel';
import { Youtube } from './custom/Youtube';
import { ZammitLayoutEffect } from './ZammitLayoutEffect';

export type ZammitComponentProps = {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  company: ICompany;
  component: IThemeComponent;
  locale?: ValidLocales;
  isMobile?: boolean;
  children?: React.ReactNode;
  extraProps?: Record<string, any>;
};

/* eslint-disable camelcase */
const ComponentMap: Record<ZammitComponentKey, React.FC<any>> = {
  announcement_bar: AnnouncementBar,
  header: Header,
  hero_banner: HeroBanner,
  hero_banner_carousel: HeroBannerCarousel,
  divided_banner: DividedBanner,
  free_text: FreeText,
  cards_grid: CardsGrid,
  cards_carousel: CardsCarousel,
  featured_collection: FeaturedCollection,
  featured_product: FeaturedProduct,
  featured_products_grid: FeaturedProductsGrid,
  featured_products_carousel: FeaturedProductsCarousel,
  footer: Footer,
  card: Card,
  button: Button,
  youtube: Youtube,
  masonry_collage: MasonryCollage,
  frame_collage: FrameCollage,
  collage_card: CollageCard,
} as const;

export const ZammitComponent = ({
  withinBuilder,
  theme,
  company,
  component,
  locale,
  isMobile,
  children,
  extraProps,
}: ZammitComponentProps) => {
  const Component = ComponentMap[component.key as ZammitComponentKey];
  const componentProps = constructProps(component);

  return component.animation ? (
    <ZammitLayoutEffect animation={component.animation}>
      <Component
        withinBuilder={withinBuilder}
        theme={theme}
        company={company}
        data={component.data}
        locale={locale}
        isMobile={isMobile}
        order={component.order}
        componentChildren={component.children}
        {...componentProps}
        {...extraProps}
      >
        {children}
      </Component>
    </ZammitLayoutEffect>
  ) : (
    <Component
      withinBuilder={withinBuilder}
      theme={theme}
      company={company}
      data={component.data}
      locale={locale}
      isMobile={isMobile}
      order={component.order}
      componentChildren={component.children}
      {...componentProps}
      {...extraProps}
    >
      {children}
    </Component>
  );
};
