import { ImageMetadata } from "../typings";
import API from "./API";
import { apiPrefix } from "./constants";

type UploadImageResponse = {
  success: boolean;
  data: { id: string; url: string; metadata: ImageMetadata };
};

export const uploadImage = async (file: File): Promise<UploadImageResponse> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await API.post<UploadImageResponse>(`${apiPrefix}/uploads`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
