import { ColorInputProps, ColorInput as MantineColorInput, Group, Box, Text, useMantineTheme } from "@mantine/core";
import { Control, Controller, FieldPath } from "react-hook-form";
import { useState } from "react";
import { useClickOutside } from "@mantine/hooks";
import { getThemeColors } from "../../../utils/settings";
import ColorPickerIcon from "../../icons/ColorPickerIcon";
import { ITheme, IThemePage } from "shared";

// Our own wrapper around Mantine's ColorInput component, instead of the one from react-hook-form-mantine
// It's purpose is just to avoid passing null/undefined to ColorInput, as it breaks the component
// TODO: add a different UI for null values -> allow "unsetting" the color.

type Props = ColorInputProps & {
  name: FieldPath<ITheme<IThemePage>>;
  control: Control<ITheme<IThemePage>>;
  label: string | React.ReactNode;
  showColorPreview?: boolean;
  showColorWheel?: boolean;
};

function ColorInput({ name, control, label, showColorWheel, showColorPreview, ...props }: Props) {
  const [showColorInput, setShowColorInput] = useState(false);
  const ref = useClickOutside(() => setShowColorInput(false));
  const toggleShowColorInput = () => setShowColorInput(!showColorInput);

  const theme = useMantineTheme();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, formState }) => {
        const value = (field.value || theme.colors["light-gray"][0]) as string;

        return (
          <Group position="apart" sx={{ width: "100%", position: "relative" }} noWrap ref={ref}>
            {showColorPreview && (
              <Box
                w={40}
                h={40}
                bg={value}
                onClick={toggleShowColorInput}
                sx={{ borderRadius: 3, cursor: "pointer", border: `solid 1px ${theme.colors["light-gray"][0]}` }}
              />
            )}

            {showColorInput && (
              <MantineColorInput
                {...field}
                {...props}
                sx={showColorPreview ? { left: 50 } : showColorWheel ? { right: 40 } : {}}
                w={225}
                format="hexa"
                pos="absolute"
                autoFocus
                withinPortal={false}
                swatches={getThemeColors(formState.defaultValues as ITheme<IThemePage>)}
                value={value}
              />
            )}

            <div style={{ flexGrow: 1 }}>
              {label}
              <Text size={14} weight={300} color="primary-black">
                {value.toUpperCase()}
              </Text>
            </div>

            {showColorWheel && (
              <div style={{ cursor: "pointer" }} onClick={toggleShowColorInput}>
                <ColorPickerIcon />
              </div>
            )}
          </Group>
        );
      }}
    />
  );
}

export default ColorInput;
