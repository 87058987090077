import API from "./API";
import { apiPrefix } from "./constants";
import { IMetadata, ICollection } from "shared";

type GetCollectionsResponse = {
  success: boolean;
  data: ICollection[];
  metadata: IMetadata;
};

export const getCollections = async (locale: "en" | "ar") => {
  const response = await API.get<GetCollectionsResponse>(`${apiPrefix}/collections`, {
    headers: { locale },
    params: { page: 1, limit: 1000 },
  });
  return response.data.data;
};
