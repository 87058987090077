import { Box } from "@mantine/core";

import Header from "../../../builder/layout/Header";
import PageSkeleton from "./PageSkeleton";

const PagesSkeleton = () => {
  return (
    <Box m={30} w="auto">
      <Header />
      <PageSkeleton />
    </Box>
  );
};

export default PagesSkeleton;
