import { BackgroundImage, Box, Modal, Overlay } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import Link from 'next/link';

import { Image as ImageType, ISpacing } from '../../../typings';
import { formatSpacing } from '../../../utils';

type ImageWithOverlayTextProps = {
  url?: string;
  bgImage: ImageType;
  overlay?: boolean;
  overlayOpacity?: number;
  overlayColor?: string;
  overlayText?: string;
};

type Props = {
  opened: boolean;
  size: 'sm' | 'md' | 'lg' | 'xl' | 'auto';
  radius?: number;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  height: number;
  withTransition: boolean;
  transition:
    | 'fade'
    | 'skew-up'
    | 'skew-down'
    | 'rotate-right'
    | 'rotate-left'
    | 'slide-down'
    | 'slide-up'
    | 'slide-right'
    | 'slide-left'
    | 'scale-y'
    | 'scale-x'
    | 'scale'
    | 'pop';
  delay?: number;
  withCloseButton: boolean;
  closeOnClickOutside: boolean;
  withinBuilder?: boolean;
  isMobile?: boolean;
  onClose: () => void;
} & ImageWithOverlayTextProps;

export const ImageWithOverlayTextPopup = ({
  opened,
  size,
  radius,
  spacing,
  mobileSpacing,
  overlayText,
  height = 300,
  url,
  bgImage,
  overlay,
  overlayOpacity,
  overlayColor,
  withTransition,
  transition,
  withCloseButton,
  closeOnClickOutside,
  withinBuilder,
  isMobile,
  onClose,
}: Props) => {
  return (
    <Modal
      centered
      padding={0}
      size={size}
      opened={opened}
      styles={{
        inner: { position: withinBuilder ? 'absolute' : 'fixed' },
        content: {
          borderRadius: radius,
          ...formatSpacing(isMobile ? mobileSpacing : spacing),
        },
        body: {
          height,
          position: 'relative',
        },
      }}
      withCloseButton={false}
      closeOnClickOutside={closeOnClickOutside}
      overlayProps={{ fixed: !withinBuilder }}
      lockScroll={!withinBuilder}
      onClose={onClose}
      {...(withTransition && {
        transitionProps: { transition, duration: 600, timingFunction: 'linear' },
      })}
      {...(withinBuilder && { target: '#modal-portal' })}
    >
      {withCloseButton && (
        <IconX
          style={{ position: 'absolute', top: 5, right: 5, zIndex: 202, cursor: 'pointer' }}
          size={20}
          onClick={onClose}
        />
      )}
      {url ? (
        <Link href={url} passHref>
          <BackgroundImage
            component="a"
            src={bgImage?.value[bgImage.value.length - 1]}
            style={{ width: '100%', height: '100%' }}
            onClick={onClose}
          >
            {overlayText ? (
              <Box h="100%" style={{ position: 'relative', zIndex: 201 }}>
                <Box px={20} dangerouslySetInnerHTML={{ __html: overlayText }} />
              </Box>
            ) : null}
            {overlay && (
              <Overlay style={{ opacity: overlayOpacity, backgroundColor: overlayColor }} />
            )}
          </BackgroundImage>
        </Link>
      ) : (
        <BackgroundImage src={bgImage?.value[0]} style={{ width: '100%', height: '100%' }}>
          {overlayText ? (
            <Box h="100%" style={{ position: 'relative', zIndex: 201 }}>
              <Box px={20} dangerouslySetInnerHTML={{ __html: overlayText }} />
            </Box>
          ) : null}
          {overlay && (
            <Overlay style={{ opacity: overlayOpacity, backgroundColor: overlayColor }} />
          )}
        </BackgroundImage>
      )}
    </Modal>
  );
};
