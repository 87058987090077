const ColorPickerIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.665 19.4252L27.9921 22.5009C29.2688 20.2946 29.9997 17.733 29.9997 15.0005H23.8493C23.8493 16.6124 23.4181 18.1236 22.665 19.4252Z"
        fill="#A027A7"
      />
      <path
        d="M22.5004 2.00759C20.2941 0.730945 17.7325 0 15 0V6.15037C16.6119 6.15037 18.1231 6.58166 19.425 7.33468L22.5007 2.00759H22.5004Z"
        fill="#FF9532"
      />
      <path
        d="M29.9997 14.9999C29.9997 12.2674 29.2688 9.70582 27.9921 7.49951L22.665 10.5753C23.4181 11.8769 23.8493 13.3881 23.8493 14.9999H29.9997Z"
        fill="#F83F3C"
      />
      <path
        d="M7.49931 27.9921C9.70562 29.2688 12.2672 29.9997 14.9997 29.9997V23.8493C13.3879 23.8493 11.8767 23.4181 10.5748 22.665L7.49902 27.9921H7.49931Z"
        fill="#4051AD"
      />
      <path
        d="M15.0003 30.0002C17.7328 30.0002 20.2944 29.2693 22.5007 27.9926L19.425 22.6655C18.1234 23.4186 16.6122 23.8498 15 23.8498V30.0002H15.0003Z"
        fill="#6B3CAA"
      />
      <path
        d="M14.9995 0C12.2669 0 9.70534 0.730945 7.49902 2.00759L10.5748 7.33468C11.8764 6.58166 13.3876 6.15037 14.9997 6.15037V0H14.9995Z"
        fill="#F3DE5A"
      />
      <path
        d="M19.4258 22.6648L22.5015 27.9919C24.7775 26.6748 26.6753 24.777 27.9924 22.5011L22.6653 19.4253C21.8885 20.7679 20.7687 21.8877 19.4261 22.6645L19.4258 22.6648Z"
        fill="#8328A5"
      />
      <path
        d="M0 15.0005C0 17.733 0.730945 20.2946 2.00759 22.5009L7.33468 19.4252C6.58166 18.1236 6.15037 16.6124 6.15037 15.0005H0Z"
        fill="#00BCD1"
      />
      <path
        d="M10.5744 7.33491L7.49865 2.00781C5.22265 3.32489 3.32489 5.22265 2.00781 7.49865L7.33491 10.5744C8.11173 9.23181 9.23152 8.11202 10.5741 7.33519L10.5744 7.33491Z"
        fill="#CADA5A"
      />
      <path
        d="M22.6643 10.5744L27.9914 7.49865C26.6743 5.22265 24.7766 3.32489 22.5006 2.00781L19.4248 7.33491C20.7674 8.11173 21.8872 9.23152 22.664 10.5741L22.6643 10.5744Z"
        fill="#FF522E"
      />
      <path
        d="M2.00759 7.49951C0.730945 9.70582 0 12.2674 0 14.9999H6.15037C6.15037 13.3881 6.58166 11.8769 7.33468 10.5753L2.00759 7.49951Z"
        fill="#85C25B"
      />
      <path
        d="M7.33491 19.4253L2.00781 22.5011C3.32489 24.777 5.22265 26.6748 7.49865 27.9919L10.5744 22.6648C9.23181 21.888 8.11202 20.7682 7.33519 19.4256L7.33491 19.4253Z"
        fill="#1196EB"
      />
    </svg>
  );
};

export default ColorPickerIcon;
