import { Box, Grid, Group, Skeleton } from '@mantine/core';

import { IProduct, ISpacing } from '../../typings';
import { formatSpacing } from '../../utils';

export interface FeaturedProductProps {
  data?: IProduct;
  bgColor?: string;
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  isMobile: boolean;
  children?: React.ReactNode;
}

const EmptyFeaturedProduct = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <Grid gutter={isMobile ? 0 : 70} mr={isMobile ? 0 : 20}>
      <Grid.Col span={isMobile ? 12 : 7} mb={isMobile ? 20 : 0}>
        <Skeleton height={400} mb={20} />
      </Grid.Col>
      <Grid.Col span={isMobile ? 12 : 5}>
        <Skeleton width="50%" height={25} mb={20} />
        <Skeleton width="70%" height={15} mb={20} />
        <Skeleton width="70%" height={15} mb={20} />
        <Skeleton width="50%" height={25} mb={20} />
        <Skeleton width="70%" height={15} mb={20} />
        {Array.from({ length: 2 }, (_, index) => (
          <Skeleton key={index} width="50%" height={25} mb={20} />
        ))}
        <Group position="apart">
          {Array.from({ length: 2 }, (_, index) => (
            <Skeleton key={index} width="45%" height={55} />
          ))}
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export const FeaturedProduct = ({
  data,
  bgColor,
  spacing,
  mobileSpacing,
  isMobile,
  children,
}: FeaturedProductProps) => {
  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        ...formatSpacing(isMobile ? mobileSpacing : spacing),
      }}
    >
      {data ? children : <EmptyFeaturedProduct isMobile={isMobile} />}
    </Box>
  );
};
