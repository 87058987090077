import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mantine/core";

import { usePages } from "../../hooks/queries/usePages";

const Pages = () => {
  const params = useParams();
  const navigate = useNavigate();

  const themeId = Number(params.theme);
  const { data: pages } = usePages(themeId);

  useEffect(() => {
    if (!params.activePage && pages) {
      navigate(`${pages[0].id}`);
    }
  }, [pages]);

  return (
    <Box m={30} w="auto">
      <Outlet />
    </Box>
  );
};

export default Pages;
