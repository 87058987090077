import { Box, Grid, Group } from '@mantine/core';

import {
  ICompany,
  IPage,
  IProduct,
  ISpacing,
  ITheme,
  IThemeComponent,
  ValidLocales,
} from '../../../typings';
import { formatSpacing } from '../../../utils';
import { ZammitComponent } from '../../ZammitComponent';
import ProductCard from './ProductCard';

export interface FeaturedCollectionProps {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  caption?: string;
  numberOfProducts: number;
  columns: number;
  mobileColumns: number;
  bgColor?: string;
  txtColor?: string;
  imageScale: string;
  textAlign?: 'start' | 'center' | 'end';
  spacing?: ISpacing;
  mobileSpacing?: ISpacing;
  isMobile?: boolean;
  company: ICompany;
  data?: IProduct[];
  locale?: ValidLocales;
  enableImageHoverEffect?: boolean;
  order: number;
  showSecondaryImageOnHover?: boolean;
  componentChildren: IThemeComponent[];
}

export const FeaturedCollection = ({
  withinBuilder,
  theme,
  caption,
  numberOfProducts,
  columns,
  mobileColumns,
  bgColor,
  txtColor,
  imageScale,
  spacing,
  mobileSpacing,
  isMobile,
  data,
  company,
  locale,
  textAlign,
  enableImageHoverEffect,
  order,
  showSecondaryImageOnHover,
  componentChildren,
}: FeaturedCollectionProps) => {
  return (
    <Group
      spacing={20}
      sx={{
        flexDirection: 'column',
        backgroundColor: bgColor,
        ...formatSpacing(isMobile ? mobileSpacing : spacing),
      }}
    >
      {caption ? <Box w="100%" dangerouslySetInnerHTML={{ __html: caption }} /> : null}
      <Grid w="100%" gutter="sm" columns={isMobile ? mobileColumns : columns}>
        {data?.slice(0, numberOfProducts).map((product) => (
          <Grid.Col key={product.id} span={1}>
            <ProductCard
              withinBuilder={withinBuilder}
              theme={theme}
              product={product}
              imageScale={imageScale}
              isMobile={isMobile}
              currency={company?.paymentCurrency}
              priceDecimalPoints={company?.priceDecimalPoints}
              priceTemplate={company?.priceTemplate}
              locale={locale}
              txtColor={txtColor}
              textAlign={textAlign}
              priority={order < 4}
              enableImageHoverEffect={enableImageHoverEffect}
              showSecondaryImageOnHover={showSecondaryImageOnHover}
            />
          </Grid.Col>
        ))}
      </Grid>
      {componentChildren.map((child) => (
        <ZammitComponent
          key={child.id}
          theme={theme}
          locale={locale}
          component={child}
          company={company}
          isMobile={isMobile}
        />
      ))}
    </Group>
  );
};
